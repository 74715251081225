import { combineReducers } from "redux";

import * as actionTypes from "../actionTypes/modal.actionTypes";

const isOpenSearchRoomModal = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.OPEN_SEARCH_MODAL:
      return true;
    case actionTypes.CLOSE_SEARCH_MODAL:
      return false;
    default:
      return state;
  }
};

const isOpenViewRoomTypeModal = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.OPEN_VIEW_ROOM_TYPE_MODAL:
      return true;
    case actionTypes.CLOSE_VIEW_ROOM_TYPE_MODAL:
      return false;
    default:
      return state;
  }
};

const isOpenSearchedRoomModal = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.OPEN_SEARCHED_MODAL:
      return true;
    case actionTypes.CLOSE_SEARCHED_MODAL:
      return false;
    default:
      return state;
  }
};

const isOpenLoginModal = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.OPEN_LOGIN_MODAL:
      return true;
    case actionTypes.CLOSE_LOGIN_MODAL:
      return false;
    default:
      return state;
  }
};

const isOpenConfirmSearchModal = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.OPEN_CONFIRM_SEARCH_MODAL:
      return true;
    case actionTypes.CLOSE_CONFIRM_SEARCH_MODAL:
      return false;
    default:
      return state;
  }
};

const isOpenBookingRequestModal = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.OPEN_BOOKING_REQUEST_MODAL:
      return true;
    case actionTypes.CLOSE_BOOKING_REQUEST_MODAL:
      return false;
    default:
      return state;
  }
};

const modalReducer = combineReducers({
  isOpenSearchRoomModal,
  isOpenViewRoomTypeModal,
  isOpenSearchedRoomModal,
  isOpenLoginModal,
  isOpenConfirmSearchModal,
  isOpenBookingRequestModal,
});

export default modalReducer;
