import React from "react";
import Fab from "@material-ui/core/Fab";
import EmailIcon from "@material-ui/icons/Email";
import { withTranslation } from "react-i18next";

const ContactFloatButton = ({ t, onClick }) => (
  <Fab
    color="primary"
    variant="extended"
    onClick={onClick}
    style={{ position: "fixed", bottom: "16px", right: "16px" }}>
    <EmailIcon /> {t("Contact Us")}
  </Fab>
);

export default withTranslation("common")(ContactFloatButton);
