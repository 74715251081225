import { Container } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import Header from "../Header";

const EnContent = () => (
  <div>
    <h2>Privacy Policy</h2>
    <p>
      Personal data (usually referred to just as "data" below) will only be processed by us to the
      extent necessary and for the purpose of providing a functional and user-friendly website,
      including its contents, and the services offered there.
    </p>
    <p>
      Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation
      (hereinafter referred to as the "GDPR"), "processing" refers to any operation or set of
      operations such as collection, recording, organization, structuring, storage, adaptation,
      alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or
      otherwise making available, alignment, or combination, restriction, erasure, or destruction
      performed on personal data, whether by automated means or not.
    </p>
    <p>
      The following privacy policy is intended to inform you in particular about the type, scope,
      purpose, duration, and legal basis for the processing of such data either under our own
      control or in conjunction with others. We also inform you below about the third-party
      components we use to optimize our website and improve the user experience which may result in
      said third parties also processing data they collect and control.
    </p>
    <p>Our privacy policy is structured as follows:</p>
    <p>
      I. Information about us as controllers of your data
      <br />
      II. The rights of users and data subjects
      <br />
      III. Information about the data processing
    </p>
    <h3>I. Information about us as controllers of your data</h3>
    <p>
      The party responsible for this website (the "controller") for purposes of data protection law
      is:
    </p>
    <p>
      <span style={{ color: "#ff0000" }}>
        ePlaza office center
        <br />
        Büro+BusinessCenter - Martina Scheel
      </span>
      <br />
      <span style={{ color: "#ff0000" }}>
        Stockholmer Platz 1<br />
        D-70173 Stuttgart
        <br />
        Germany
      </span>
    </p>
    <p>
      <span style={{ color: "#ff0000" }}>
        Telefon: +49 711 7811500
        <br />
        Telefax: +49 711 7811501
        <br />
        E-Mail: info@eplaza-office.de
      </span>
    </p>
    <p>The controller's data protection officer is:</p>
    <p>
      <span style={{ color: "#ff0000" }}>Martina Scheel&nbsp;</span>
    </p>

    <h3>II. The rights of users and data subjects</h3>
    <p>
      With regard to the data processing to be described in more detail below, users and data
      subjects have the right
    </p>
    <ul type="disc">
      <li>
        to confirmation of whether data concerning them is being processed, information about the
        data being processed, further information about the nature of the data processing, and
        copies of the data (cf. also Art. 15 GDPR);
      </li>
      <li>to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);</li>
      <li>
        to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or,
        alternatively, if further processing is necessary as stipulated in Art. 17 Para. 3 GDPR, to
        restrict said processing per Art. 18 GDPR;
      </li>
      <li>
        to receive copies of the data concerning them and/or provided by them and to have the same
        transmitted to other providers/controllers (cf. also Art. 20 GDPR);
      </li>
      <li>
        to file complaints with the supervisory authority if they believe that data concerning them
        is being processed by the controller in breach of data protection provisions (see also Art.
        77 GDPR).
      </li>
    </ul>
    <p>
      In addition, the controller is obliged to inform all recipients to whom it discloses data of
      any such corrections, deletions, or restrictions placed on processing the same per Art. 16, 17
      Para. 1, 18 GDPR. However, this obligation does not apply if such notification is impossible
      or involves a disproportionate effort. Nevertheless, users have a right to information about
      these recipients.
    </p>
    <p>
      <strong>
        Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the
        controller's future processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In
        particular, an objection to data processing for the purpose of direct advertising is
        permissible.
      </strong>
    </p>
    <h3>III. Information about the data processing</h3>
    <p>
      Your data processed when using our website will be deleted or blocked as soon as the purpose
      for its storage ceases to apply, provided the deletion of the same is not in breach of any
      statutory storage obligations or unless otherwise stipulated below.
    </p>

    <h4>Server data</h4>
    <p>
      For technical reasons, the following data sent by your internet browser to us or to our server
      provider will be collected, especially to ensure a secure and stable website: These server log
      files record the type and version of your browser, operating system, the website from which
      you came (referrer URL), the webpages on our site visited, the date and time of your visit, as
      well as the IP address from which you visited our site.
    </p>
    <p>
      The data thus collected will be temporarily stored, but not in association with any other of
      your data.
    </p>
    <p>
      The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the
      improvement, stability, functionality, and security of our website.
    </p>
    <p>
      The data will be deleted within no more than seven days, unless continued storage is required
      for evidentiary purposes. In which case, all or part of the data will be excluded from
      deletion until the investigation of the relevant incident is finally resolved.
    </p>

    <h4>Cookies</h4>
    <h5>a) Session cookies</h5>
    <p>
      We use cookies on our website. Cookies are small text files or other storage technologies
      stored on your computer by your browser. These cookies process certain specific information
      about you, such as your browser, location data, or IP address. &nbsp;
    </p>
    <p>
      This processing makes our website more user-friendly, efficient, and secure, allowing us, for
      example, to display our website in different languages or to offer a shopping cart function.
    </p>
    <p>
      The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR, insofar as these cookies
      are used to collect data to initiate or process contractual relationships.
    </p>
    <p>
      If the processing does not serve to initiate or process a contract, our legitimate interest
      lies in improving the functionality of our website. The legal basis is then Art. 6 Para. 1
      lit. f) GDPR.
    </p>
    <p>When you close your browser, these session cookies are deleted.</p>
    <h5>b) Third-party cookies</h5>
    <p>
      If necessary, our website may also use cookies from companies with whom we cooperate for the
      purpose of advertising, analyzing, or improving the features of our website.
    </p>
    <p>
      Please refer to the following information for details, in particular for the legal basis and
      purpose of such third-party collection and processing of data collected through cookies.
    </p>
    <h5>c) Disabling cookies</h5>
    <p>
      You can refuse the use of cookies by changing the settings on your browser. Likewise, you can
      use the browser to delete cookies that have already been stored. However, the steps and
      measures required vary, depending on the browser you use. If you have any questions, please
      use the help function or consult the documentation for your browser or contact its maker for
      support. Browser settings cannot prevent so-called flash cookies from being set. Instead, you
      will need to change the setting of your Flash player. The steps and measures required for this
      also depend on the Flash player you are using. If you have any questions, please use the help
      function or consult the documentation for your Flash player or contact its maker for support.
    </p>
    <p>
      If you prevent or restrict the installation of cookies, not all of the functions on our site
      may be fully usable.
    </p>

    <h4>Order processing</h4>
    <p>
      The data you submit when ordering goods and/or services from us will have to be processed in
      order to fulfill your order. Please note that orders cannot be processed without providing
      this data.
    </p>
    <p>The legal basis for this processing is Art. 6 Para. 1 lit. b) GDPR.</p>
    <p>
      After your order has been completed, your personal data will be deleted, but only after the
      retention periods required by tax and commercial law.
    </p>
    <p>
      In order to process your order, we will share your data with the shipping company responsible
      for delivery to the extent required to deliver your order and/or with the payment service
      provider to the extent required to process your payment.
    </p>
    <h5>The legal basis for the transfer of this data is Art. 6 Para. 1 lit. b) GDPR. </h5>

    <h4>Customer account/registration</h4>
    <p>
      If you create a customer account with us via our website, we will use the data you entered
      during registration (e.g. your name, your address, or your email address) exclusively for
      services leading up to your potential placement of an order or entering some other contractual
      relationship with us, to fulfill such orders or contracts, and to provide customer care (e.g.
      to provide you with an overview of your previous orders or to be able to offer you a wishlist
      function). We also store your IP address and the date and time of your registration. This data
      will not be transferred to third parties.
    </p>
    <p>
      During the registration process, your consent will be obtained for this processing of your
      data, with reference made to this privacy policy. The data collected by us will be used
      exclusively to provide your customer account.&nbsp;
    </p>
    <p>
      If you give your consent to this processing, Art. 6 Para. 1 lit. a) GDPR is the legal basis
      for this processing.
    </p>
    <p>
      If the opening of the customer account is also intended to lead to the initiation of a
      contractual relationship with us or to fulfill an existing contract with us, the legal basis
      for this processing is also Art. 6 Para. 1 lit. b) GDPR.
    </p>
    <p>
      You may revoke your prior consent to the processing of your personal data at any time under
      Art. 7 Para. 3 GDPR with future effect. All you have to do is inform us that you are revoking
      your consent.
    </p>
    <p>
      The data previously collected will then be deleted as soon as processing is no longer
      necessary. However, we must observe any retention periods required under tax and commercial
      law.
    </p>

    <h4>Newsletter</h4>
    <p>
      If you register for our free newsletter, the data requested from you for this purpose, i.e.
      your email address and, optionally, your name and address, will be sent to us. We also store
      the IP address of your computer and the date and time of your registration. During the
      registration process, we will obtain your consent to receive this newsletter and the type of
      content it will offer, with reference made to this privacy policy. The data collected will be
      used exclusively to send the newsletter and will not be passed on to third parties.
    </p>
    <p>The legal basis for this is Art. 6 Para. 1 lit. a) GDPR.</p>
    <p>
      You may revoke your prior consent to receive this newsletter under Art. 7 Para. 3 GDPR with
      future effect. All you have to do is inform us that you are revoking your consent or click on
      the unsubscribe link contained in each newsletter.
    </p>

    <h4>Contact</h4>
    <p>
      If you contact us via email or the contact form, the data you provide will be used for the
      purpose of processing your request. We must have this data in order to process and answer your
      inquiry; otherwise we will not be able to answer it in full or at all.
    </p>
    <p>The legal basis for this data processing is Art. 6 Para. 1 lit. b) GDPR.</p>
    <p>
      Your data will be deleted once we have fully answered your inquiry and there is no further
      legal obligation to store your data, such as if an order or contract resulted therefrom.
    </p>

    <h4>Google Analytics</h4>
    <p>
      We use Google Analytics on our website. This is a web analytics service provided by Google
      Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (hereinafter: Google).
    </p>
    <p>Through certification according to the EU-US Privacy Shield</p>
    <p>
      <a
        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
        target="_blank"
        rel="noopener">
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
      </a>
    </p>
    <p>
      Google guarantees that it will follow the EU's data protection regulations when processing
      data in the United States.
    </p>
    <p>
      The Google Analytics service is used to analyze how our website is used. The legal basis is
      Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the analysis, optimization, and
      economic operation of our site.
    </p>
    <p>
      Usage and user-related information, such as IP address, place, time, or frequency of your
      visits to our website will be transmitted to a Google server in the United States and stored
      there. However, we use Google Analytics with the so-called anonymization function, whereby
      Google truncates the IP address within the EU or the EEA before it is transmitted to the US.
    </p>
    <p>
      The data collected in this way is in turn used by Google to provide us with an evaluation of
      visits to our website and what visitors do once there. This data can also be used to provide
      other services related to the use of our website and of the internet in general.
    </p>
    <p>
      Google states that it will not connect your IP address to other data. In addition, Google
      provides further information with regard to its data protection practices at
    </p>
    <p>
      <a
        href="https://www.google.com/intl/de/policies/privacy/partners"
        target="_blank"
        rel="noopener">
        https://www.google.com/intl/de/policies/privacy/partners,
      </a>
    </p>
    <p>including options you can exercise to prevent such use of your data.</p>
    <p>In addition, Google offers an opt-out add-on at</p>
    <p>
      <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener">
        https://tools.google.com/dlpage/gaoptout?hl=en
      </a>
    </p>
    <p>
      in addition with further information. This add-on can be installed on the most popular
      browsers and offers you further control over the data that Google collects when you visit our
      website. The add-on informs Google Analytics' JavaScript (ga.js) that no information about the
      website visit should be transmitted to Google Analytics. However, this does not prevent
      information from being transmitted to us or to other web analytics services we may use as
      detailed herein.
    </p>

    <h4>Google-Maps</h4>
    <p>
      Our website uses Google Maps to display our location and to provide directions. This is a
      service provided by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
      (hereinafter: Google).
    </p>
    <p>Through certification according to the EU-US Privacy Shield</p>
    <p>
      <a
        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
        target="_blank"
        rel="noopener">
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
      </a>
    </p>
    <p>
      Google guarantees that it will follow the EU's data protection regulations when processing
      data in the United States.
    </p>
    <p>
      To enable the display of certain fonts on our website, a connection to the Google server in
      the USA is established whenever our website is accessed.
    </p>
    <p>
      If you access the Google Maps components integrated into our website, Google will store a
      cookie on your device via your browser. Your user settings and data are processed to display
      our location and create a route description. We cannot prevent Google from using servers in
      the USA.
    </p>
    <p>
      The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in optimizing the
      functionality of our website.
    </p>
    <p>
      By connecting to Google in this way, Google can determine from which website your request has
      been sent and to which IP address the directions are transmitted.
    </p>
    <p>
      If you do not agree to this processing, you have the option of preventing the installation of
      cookies by making the appropriate settings in your browser. Further details can be found in
      the section about cookies above.
    </p>
    <p>
      In addition, the use of Google Maps and the information obtained via Google Maps is governed
      by the{" "}
      <a href="http://www.google.de/accounts/TOS" target="_blank" rel="noopener">
        Google Terms of Use
      </a>{" "}
      <a href="https://policies.google.com/terms?gl=DE&amp;hl=de" target="_blank" rel="noopener">
        https://policies.google.com/terms?gl=DE&amp;hl=en
      </a>{" "}
      and the{" "}
      <a
        href="https://www.google.com/intl/de_de/help/terms_maps.html"
        target="_blank"
        rel="noopener">
        Terms and Conditions for Google Maps
      </a>{" "}
      https://www.google.com/intl/de_de/help/terms_maps.html.
    </p>
    <p>Google also offers further information at</p>
    <p>
      <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener">
        https://adssettings.google.com/authenticated
      </a>
    </p>
    <p>
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">
        https://policies.google.com/privacy
      </a>
    </p>

    <p>
      <a
        href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
        target="_blank"
        rel="noopener">
        Model Data Protection Statement
      </a>{" "}
      for{" "}
      <a href="https://www.ratgeberrecht.eu/" target="_blank">
        Anwaltskanzlei Weiß &amp; Partner
      </a>
    </p>
  </div>
);

const DeContent = (props) => (
  <div>
    <h2>Datenschutzerklärung</h2>
    <p>
      Wir freuen uns über Ihren Besuch auf unserer Internetseite  <a
        target="_blank"
        rel="noopener"
        href="www.eplaza-office.de">
        www.eplaza-office.de
      </a> und Ihr
      Interesse an unserem Unternehmen.
    </p>
    <p>
      Der Schutz Ihrer personenbezogenen Daten, wie z.B. Geburtsdatum, Name, Telefonnummer,
      Anschrift etc., ist uns ein wichtiges Anliegen.
    </p>
    <p>
      Der Zweck dieser Datenschutzerklärung besteht darin, Sie über die Verarbeitung Ihrer
      personenbezogenen Daten zu informieren, die wir bei einem Seitenbesuch von Ihnen sammeln.
      Unsere Datenschutzpraxis steht im Einklang mit den gesetzlichen Regelungen der
      Datenschutzgrundverordnung der EU (DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Die
      nachfolgende Datenschutzerklärung dient der Erfüllung der sich aus der DSGVO ergebenden
      Informationspflichten. Diese finden sich z.B. in Art. 13 und Art. 14 ff. DSGVO.
    </p>
    <p>
      Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen
      der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und
      nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
      Leistungen, verarbeitet.
    </p>
    <p>
      Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung
      (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
      automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang
      mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die
      Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
      Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den
      Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
    </p>
    <p>
      Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang,
      Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder
      allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden.
      Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur
      Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten
      in wiederum eigener Verantwortung verarbeiten.
    </p>
    <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
    <p>
      I. Informationen über uns als Verantwortliche
      <br />
      II. Rechte der Nutzer und Betroffenen
      <br />
      III. Informationen zur Datenverarbeitung
    </p>
    <h3>I. Informationen über uns als Verantwortliche</h3>
    <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
    <p>
      <span style={{ color: "#ff0000" }}>
        ePLAZA office center
        <br />
        Martina Scheel
        <br />
        Stockholmer Platz 1<br />
        70173 Stuttgart
        <br />
        Deutschland
      </span>
    </p>
    <p>
      <span style={{ color: "#ff0000" }}>
        E-Mail: info@eplaza-office.de
        <br />
        Tel.: +49 711 7811500
        <br />
        Fax: +49 711 7811501
      </span>
    </p>
    <p>Datenschutzbeauftragte/r beim Anbieter ist:</p>
    <p>
      <span style={{ color: "#ff0000" }}>Martina Scheel&nbsp;</span>
    </p>

    <h3>II. Rechte der Nutzer und Betroffenen</h3>
    <p>
      Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und
      Betroffenen das Recht
    </p>
    <ul>
      <li>
        auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die
        verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien
        der Daten (vgl. auch Art. 15 DSGVO);
      </li>
      <li>
        auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch
        Art. 16 DSGVO);
      </li>
      <li>
        auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder,
        alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist,
        auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;
      </li>
      <li>
        auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung
        dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
      </li>
      <li>
        auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie
        betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen
        verarbeitet werden (vgl. auch Art. 77 DSGVO).
      </li>
    </ul>
    <p>
      Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch
      den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder
      die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt,
      zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich
      oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer
      ein Recht auf Auskunft über diese Empfänger.
    </p>
    <p>
      <strong>
        Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch
        gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den
        Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist
        ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.
      </strong>
    </p>
    <h3>III. Informationen zur Datenverarbeitung</h3>
    <p>
      Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt,
      sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen
      Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu
      einzelnen Verarbeitungsverfahren gemacht werden.
    </p>

    <h4>Serverdaten</h4>
    <p>
      Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen
      Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren
      Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version
      Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren
      Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts,
      die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des
      Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.
    </p>
    <p>
      Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit
      anderen Daten von Ihnen.
    </p>
    <p>
      Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser
      berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit
      unseres Internetauftritts.
    </p>
    <p>
      Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere
      Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen
      Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.
    </p>

    <h4>Cookies</h4>
    <h5>a) Sitzungs-Cookies/Session-Cookies</h5>
    <p>
      Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder
      andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem
      Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang
      bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder
      Ihre IP-Adresse, verarbeitet. &nbsp;
    </p>
    <p>
      Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und
      sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in
      unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.
    </p>
    <p>
      Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies
      Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.
    </p>
    <p>
      Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser
      berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts.
      Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
    </p>
    <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
    <h5>b) Drittanbieter-Cookies</h5>
    <p>
      Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit
      denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres
      Internetauftritts zusammenarbeiten, verwendet.
    </p>
    <p>
      Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung
      solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.
    </p>
    <h5>c) Beseitigungsmöglichkeit</h5>
    <p>
      Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers
      verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit
      löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret
      genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder
      Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei
      sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers
      unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players
      ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret
      genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder
      Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.
    </p>
    <p>
      Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings
      dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar
      sind.{" "}
    </p>

    <h4>Vertragsabwicklung</h4>
    <p>
      Die von Ihnen zur Inanspruchnahme unseres Waren- und/oder Dienstleistungsangebots
      übermittelten Daten werden von uns zum Zwecke der Vertragsabwicklung verarbeitet und sind
      insoweit erforderlich. Vertragsschluss und Vertragsabwicklung sind ohne Bereitstellung Ihrer
      Daten nicht möglich.
    </p>
    <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
    <p>
      Wir löschen die Daten mit vollständiger Vertragsabwicklung, müssen dabei aber die steuer- und
      handelsrechtlichen Aufbewahrungsfristen beachten.
    </p>
    <p>
      Im Rahmen der Vertragsabwicklung geben wir Ihre Daten an das mit der Warenlieferung
      beauftragte Transportunternehmen oder an den Finanzdienstleister weiter, soweit die Weitergabe
      zur Warenauslieferung oder zu Bezahlzwecken erforderlich ist.
    </p>
    <p>Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6 Abs. 1 lit. b) DSGVO.</p>

    <h4>Kundenkonto / Registrierungsfunktion</h4>
    <p>
      Falls Sie über unseren Internetauftritt ein Kundenkonto bei uns anlegen, werden wir die von
      Ihnen bei der Registrierung eingegebenen Daten (also bspw. Ihren Namen, Ihre Anschrift oder
      Ihre E-Mail-Adresse) ausschließlich für vorvertragliche Leistungen, für die Vertragserfüllung
      oder zum Zwecke der Kundenpflege (bspw. um Ihnen eine Übersicht über Ihre bisherigen
      Bestellungen bei uns zur Verfügung zu stellen oder um Ihnen die sog. Merkzettelfunktion
      anbieten zu können) erheben und speichern. Gleichzeitig speichern wir dann die IP-Adresse und
      das Datum Ihrer Registrierung nebst Uhrzeit. Eine Weitergabe dieser Daten an Dritte erfolgt
      natürlich nicht.
    </p>
    <p>
      Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in diese Verarbeitung eingeholt
      und auf diese Datenschutzerklärung verwiesen. Die dabei von uns erhobenen Daten werden
      ausschließlich für die Zurverfügungstellung des Kundenkontos verwendet.&nbsp;
    </p>
    <p>
      Soweit Sie in diese Verarbeitung einwilligen, ist Art. 6 Abs. 1 lit. a) DSGVO Rechtsgrundlage
      für die Verarbeitung.
    </p>
    <p>
      Sofern die Eröffnung des Kundenkontos zusätzlich auch vorvertraglichen Maßnahmen oder der
      Vertragserfüllung dient, so ist Rechtsgrundlage für diese Verarbeitung auch noch Art. 6 Abs. 1
      lit. b) DSGVO.
    </p>
    <p>
      Die uns erteilte Einwilligung in die Eröffnung und den Unterhalt des Kundenkontos können Sie
      gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie
      uns lediglich über Ihren Widerruf in Kenntnis setzen.
    </p>
    <p>
      Die insoweit erhobenen Daten werden gelöscht, sobald die Verarbeitung nicht mehr erforderlich
      ist. Hierbei müssen wir aber steuer- und handelsrechtliche Aufbewahrungsfristen beachten.
    </p>

    <h4>Newsletter</h4>
    <p>
      Falls Sie sich für unseren kostenlosen Newsletter anmelden, werden die von Ihnen hierzu
      abgefragten Daten, also Ihre E-Mail-Adresse sowie - optional - Ihr Name und Ihre Anschrift, an
      uns übermittelt. Gleichzeitig speichern wir die IP-Adresse des Internetanschlusses von dem aus
      Sie auf unseren Internetauftritt zugreifen sowie Datum und Uhrzeit Ihrer Anmeldung. Im Rahmen
      des weiteren Anmeldevorgangs werden wir Ihre Einwilligung in die Übersendung des Newsletters
      einholen, den Inhalt konkret beschreiben und auf diese Datenschutzerklärung verwiesen. Die
      dabei erhobenen Daten verwenden wir ausschließlich für den Newsletter-Versand – sie werden
      deshalb insbesondere auch nicht an Dritte weitergegeben.
    </p>
    <p>Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO.</p>
    <p>
      Die Einwilligung in den Newsletter-Versand können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit
      Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in
      Kenntnis setzen oder den in jedem Newsletter enthaltenen Abmeldelink betätigen.
    </p>

    <h4>Kontaktanfragen / Kontaktmöglichkeit</h4>
    <p>
      Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von
      Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur
      Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung können wir
      Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.
    </p>
    <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
    <p>
      Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der
      Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich
      etwaig anschließenden Vertragsabwicklung.
    </p>

    <h4>Google Analytics</h4>
    <p>
      In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um einen
      Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
      nachfolgend nur „Google“ genannt.
    </p>
    <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
    <p>
      <a
        target="_blank"
        rel="noopener"
        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
      </a>
    </p>
    <p>
      garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in
      den USA eingehalten werden.
    </p>
    <p>
      Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens unseres
      Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes
      Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres
      Internetauftritts.
    </p>
    <p>
      Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder Häufigkeit
      des Besuchs unseres Internetauftritts, werden dabei an einen Server von Google in den USA
      übertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog.
      Anonymisierungsfunktion. Durch diese Funktion kürzt Google die IP-Adresse schon innerhalb der
      EU bzw. des EWR.
    </p>
    <p>
      Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine Auswertung über den
      Besuch unseres Internetauftritts sowie über die dortigen Nutzungsaktivitäten zur Verfügung zu
      stellen. Auch können diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen, die
      mit der Nutzung unseres Internetauftritts und der Nutzung des Internets zusammenhängen.
    </p>
    <p>
      Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem hält Google unter
    </p>
    <p>
      <a
        target="_blank"
        rel="noopener"
        href="https://www.google.com/intl/de/policies/privacy/partners">
        https://www.google.com/intl/de/policies/privacy/partners
      </a>
    </p>
    <p>
      weitere datenschutzrechtliche Informationen für Sie bereit, so bspw. auch zu den
      Möglichkeiten, die Datennutzung zu unterbinden.
    </p>
    <p>Zudem bietet Google unter</p>
    <p>
      <a target="_blank" rel="noopener" href="https://tools.google.com/dlpage/gaoptout?hl=de">
        https://tools.google.com/dlpage/gaoptout?hl=de
      </a>
    </p>
    <p>
      ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on lässt
      sich mit den gängigen Internet-Browsern installieren und bietet Ihnen weitergehende
      Kontrollmöglichkeit über die Daten, die Google bei Aufruf unseres Internetauftritts erfasst.
      Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics mit, dass Informationen zum
      Besuch unseres Internetauftritts nicht an Google Analytics übermittelt werden sollen. Dies
      verhindert aber nicht, dass Informationen an uns oder an andere Webanalysedienste übermittelt
      werden. Ob und welche weiteren Webanalysedienste von uns eingesetzt werden, erfahren Sie
      natürlich ebenfalls in dieser Datenschutzerklärung.
    </p>

    <h4>Google-Maps</h4>
    <p>
      In unserem Internetauftritt setzen wir Google Maps zur Darstellung unseres Standorts sowie zur
      Erstellung einer Anfahrtsbeschreibung ein. Es handelt sich hierbei um einen Dienst der Google
      Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“
      genannt.
    </p>
    <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
    <p>
      <a
        target="_blank"
        rel="noopener"
        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
      </a>
    </p>
    <p>
      garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in
      den USA eingehalten werden.
    </p>
    <p>
      Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei
      Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.
    </p>
    <p>
      Sofern Sie die in unseren Internetauftritt eingebundene Komponente Google Maps aufrufen,
      speichert Google über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät. Um unseren
      Standort anzuzeigen und eine Anfahrtsbeschreibung zu erstellen, werden Ihre
      Nutzereinstellungen und -daten verarbeitet. Hierbei können wir nicht ausschließen, dass Google
      Server in den USA einsetzt.
    </p>
    <p>
      Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
      Optimierung der Funktionalität unseres Internetauftritts.
    </p>
    <p>
      Durch die so hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre
      Anfrage gesendet worden ist und an welche IP-Adresse die Anfahrtsbeschreibung zu übermitteln
      ist.
    </p>
    <p>
      Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die
      Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu
      verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.
    </p>
    <p>
      Zudem erfolgt die Nutzung von Google Maps sowie der über Google Maps erlangten Informationen
      nach den{" "}
      <a target="_blank" rel="noopener" href="http://www.google.de/accounts/TOS">
        Google-Nutzungsbedingungen
      </a>
      &nbsp;
      <a target="_blank" rel="noopener" href="https://policies.google.com/terms?gl=DE&amp;hl=de">
        https://policies.google.com/terms?gl=DE&amp;hl=de
      </a>{" "}
      und den{" "}
      <a
        target="_blank"
        rel="noopener"
        href="https://www.google.com/intl/de_de/help/terms_maps.html">
        Geschäftsbedingungen für Google Maps
      </a>{" "}
      https://www.google.com/intl/de_de/help/terms_maps.html.
    </p>
    <p>Überdies bietet Google unter</p>
    <p>
      <a target="_blank" rel="noopener" href="https://adssettings.google.com/authenticated">
        https://adssettings.google.com/authenticated
      </a>
    </p>
    <p>
      <a target="_blank" rel="noopener" href="https://policies.google.com/privacy">
        https://policies.google.com/privacy
      </a>
    </p>
    <p>weitergehende Informationen an.</p>

    <p>
      <a
        target="_blank"
        href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html">
        Muster-Datenschutzerklärung
      </a>{" "}
      der{" "}
      <a
        target="_blank"
        href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html">
        Anwaltskanzlei Weiß &amp; Partner
      </a>
    </p>
  </div>
);

const renderView = (lang) => {
  switch (lang) {
    case "de":
      return <DeContent />;
    default:
      return <EnContent />;
  }
};

const Imprint = ({ t, i18n }) => (
  <div>
    <Header size="small" />

    <Container style={{ marginTop: 64, marginBottom: 249 }}>{renderView(i18n.language)}</Container>
  </div>
);

export default withTranslation("policies")(Imprint);
