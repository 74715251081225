const C = "BOOKING_SESSION";
export const CONTINUE_BOOKING_PROCESS = `${C}.CONTINUE_BOOKING_PROCESS`;
export const CONTINUE_BOOKING_PROCESS_COMPLETED = `${C}.CONTINUE_BOOKING_PROCESS_COMPLETED`;
export const CONTINUE_BOOKING_PROCESS_FAILED = `${C}.CONTINUE_BOOKING_PROCESS_FAILED`;
export const SHOW_CONTACT_FORM = `${C}.SHOW_CONTACT_FORM`;
export const HIDE_CONTACT_FORM = `${C}.HIDE_CONTACT_FORM`;

/**
 * Initial Stage
 * Pick booking date, pariticipants
 */
const STAGE_0 = `${C}.STAGE_0`;
export const STAGE_0_SHOW_HOLIDAY_WARNING_POPUP = `${STAGE_0}.SHOW_HOLIDAY_WARNING_POPUP`;
export const STAGE_0_HIDE_HOLIDAY_WARNING_POPUP = `${STAGE_0}.HIDE_HOLIDAY_WARNING_POPUP`;
export const STAGE_0_SHOW_PICK_DATE_POPUP = `${STAGE_0}.SHOW_PICK_DATE_POPUP`;
export const STAGE_0_HIDE_PICK_DATE_POPUP = `${STAGE_0}.HIDE_PICK_DATE_POPUP`;
export const STAGE_0_START_BOOK_FOR_ROOMTYPE = `${STAGE_0}.START_BOOK_FOR_ROOMTYPE`;
export const STAGE_0_CHANGE_BOOKING_TYPE = `${STAGE_0}.CHANGE_BOOKING_TYPE`;
export const STAGE_0_CHANGE_PARTICIPANT_NUMBER = `${STAGE_0}.CHANGE_PARTICIPANT_NUMBER`;
export const STAGE_0_CHANGE_BOOKING_START_DATE = `${STAGE_0}.CHANGE_BOOKING_START_DATE`;
export const STAGE_0_CHANGE_BOOKING_START_TIME = `${STAGE_0}.CHANGE_BOOKING_START_TIME`;
export const STAGE_0_CHANGE_BOOKING_END_DATE = `${STAGE_0}.CHANGE_BOOKING_END_DATE`;
export const STAGE_0_CHANGE_BOOKING_END_TIME = `${STAGE_0}.CHANGE_BOOKING_END_TIME`;
export const STAGE_0_TOGGLE_PRESENTATION_DEVICE = `${STAGE_0}.TOGGLE_PRESENTATION_DEVICE`;
export const STAGE_0_CLICK_SEARCH_ROOM = `${STAGE_0}.CLICK_SEARCH_ROOM`;
export const STAGE_0_SEARCHING_ROOM = `${STAGE_0}.SEARCHING_ROOM`;
export const STAGE_0_SEARCHING_ROOM_SUCCESS = `${STAGE_0}.SEARCHING_ROOM_SUCCESS`;
export const STAGE_0_SEARCHING_ROOM_FAIL = `${STAGE_0}.SEARCHING_ROOM_FAIL`;
export const STAGE_0_CLICK_CANCEL = `${STAGE_0}.CLICK_CANCEL`;
export const STAGE_0_RETRIEVE_LEAD_TIME = `${STAGE_0}.RETRIEVE_LEAD_TIME`;
export const STAGE_0_RETRIEVE_LEAD_TIME_COMPLETED = `${STAGE_0}.RETRIEVE_LEAD_TIME_COMPLETED`;
export const STAGE_0_RETRIEVE_LEAD_TIME_FAILED = `${STAGE_0}.RETRIEVE_LEAD_TIME_FAILED`;

/**
 * Stage 1
 * Booking Configuration
 */
const STAGE_1 = `${C}.STAGE_1`;
export const STAGE_1_FETCH_EX_FACILITY_CATEGORIES = `${STAGE_1}.FETCH_EX_FACILITY_CATEGORIES`;
export const STAGE_1_FETCH_EX_FACILITY_CATEGORIES_COMPLETED = `${STAGE_1}.FETCH_EX_FACILITY_CATEGORIES_COMPLETED`;
export const STAGE_1_FETCH_EX_FACILITY_CATEGORIES_FAILED = `${STAGE_1}.FETCH_EX_FACILITY_CATEGORIES_FAILED`;
export const STAGE_1_FETCH_CATERING_SERVICES = `${STAGE_1}.FETCH_CATERING_SERVICES`;
export const STAGE_1_FETCH_CATERING_SERVICES_COMPLETED = `${STAGE_1}.FETCH_CATERING_SERVICES_COMPLETED`;
export const STAGE_1_FETCH_CATERING_SERVICES_FAILED = `${STAGE_1}.FETCH_CATERING_SERVICES_FAILED`;
export const STAGE_1_CHANGE_EX_FACILITY = `${STAGE_1}.CHANGE_EX_FACILITY`;
export const STAGE_1_FETCH_EX_FACILITY_AVAILABILITY = `${STAGE_1}.FETCH_EX_FACILITY_AVAILABILITY`;
export const STAGE_1_FETCH_EX_FACILITY_AVAILABILITY_COMPLETED = `${STAGE_1}.FETCH_EX_FACILITY_AVAILABILITY_COMPLETED`;
export const STAGE_1_FETCH_EX_FACILITY_AVAILABILITY_FAILED = `${STAGE_1}.FETCH_EX_FACILITY_AVAILABILITY_FAILED`;
export const STAGE_1_CHANGE_EX_FACILITY_QUANTITY = `${STAGE_1}.CHANGE_EX_FACILITY_QUANTITY`;
export const STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS = `${STAGE_1}.FETCH_EX_FACILITY_ITEM_PRICINGS`;
export const STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS_COMPLETED = `${STAGE_1}.FETCH_EX_FACILITY_ITEM_PRICINGS_COMPLETED`;
export const STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS_FAILED = `${STAGE_1}.FETCH_EX_FACILITY_ITEM_PRICINGS_FAILED`;
export const STAGE_1_ADD_EX_FACILITY_ITEM = `${STAGE_1}.ADD_EX_FACILITY_ITEM`;
export const STAGE_1_ADD_EX_FACILITY_ITEM_COMPLETE = `${STAGE_1}.ADD_EX_FACILITY_ITEM_COMPLETE`;
export const STAGE_1_ADD_EX_FACILITY_ITEM_FAILED = `${STAGE_1}.ADD_EX_FACILITY_ITEM_FAILED`;
export const STAGE_1_REMOVE_EX_FACILITY_ITEM = `${STAGE_1}.REMOVE_EX_FACILITY_ITEM`;
export const STAGE_1_REMOVE_EX_FACILITY_ITEM_COMPLETE = `${STAGE_1}.REMOVE_EX_FACILITY_ITEM_COMPLETE`;
export const STAGE_1_REMOVE_EX_FACILITY_ITEM_FAILED = `${STAGE_1}.REMOVE_EX_FACILITY_ITEM_FAILED`;
export const STAGE_1_FETCH_CATERING_SERVICE_AVAILABILITY = `${STAGE_1}.FETCH_CATERING_SERVICE_AVAILABILITY`;
export const STAGE_1_FETCH_CATERING_SERVICE_AVAILABILITY_COMPLETE = `${STAGE_1}.FETCH_CATERING_SERVICE_AVAILABILITY_COMPLETE`;
export const STAGE_1_FETCH_CATERING_SERVICE_AVAILABILITY_FAILED = `${STAGE_1}.FETCH_CATERING_SERVICE_AVAILABILITY_FAILED`;
export const STAGE_1_CHANGE_CATERING_SERVICE = `${STAGE_1}.CHANGE_CATERING_SERVICE`;
export const STAGE_1_CHANGE_CATERING_SERVICE_QUANTITY = `${STAGE_1}.CHANGE_CATERING_SERVICE_QUANTITY`;
export const STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS = `${STAGE_1}.FETCH_CATERING_SERVICE_ITEM_PRICINGS`;
export const STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS_COMPLETED = `${STAGE_1}.FETCH_CATERING_SERVICE_ITEM_PRICINGS_COMPLETED`;
export const STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS_FAILED = `${STAGE_1}.FETCH_CATERING_SERVICE_ITEM_PRICINGS_FAILED`;
export const STAGE_1_ADD_CATERING_SERVICE_ITEM = `${STAGE_1}.ADD_CATERING_SERVICE_ITEM`;
export const STAGE_1_ADD_CATERING_SERVICE_ITEM_COMPLETED = `${STAGE_1}.ADD_CATERING_SERVICE_ITEM_COMPLETED`;
export const STAGE_1_ADD_CATERING_SERVICE_ITEM_FAILED = `${STAGE_1}.ADD_CATERING_SERVICE_ITEM_FAILED`;
export const STAGE_1_REMOVE_CATERING_SERVICE_ITEM = `${STAGE_1}.REMOVE_CATERING_SERVICE_ITEM`;
export const STAGE_1_REMOVE_CATERING_SERVICE_ITEM_COMPLETED = `${STAGE_1}.REMOVE_CATERING_SERVICE_ITEM_COMPLETED`;
export const STAGE_1_REMOVE_CATERING_SERVICE_ITEM_FAILED = `${STAGE_1}.REMOVE_CATERING_SERVICE_ITEM_FAILED`;
export const STAGE_1_CLICK_NEXT = `${STAGE_1}.CLICK_NEXT`;
export const STAGE_1_CLICK_NEXT_COMPLETED = `${STAGE_1}.CLICK_NEXT_COMPLETED`;
export const STAGE_1_CLICK_NEXT_FAILED = `${STAGE_1}.CLICK_NEXT_FAILED`;
export const STAGE_1_CLICK_CANCEL = `${STAGE_1}.CLICK_CANCEL`;
export const STAGE_1_CLICK_CANCEL_COMPLETED = `${STAGE_1}.CLICK_CANCEL_COMPLETED`;
export const STAGE_1_CLICK_CANCEL_FAILED = `${STAGE_1}.CLICK_CANCEL_FAILED`;
export const STAGE_1_CHANGE_ADDITIONAL_REQUIREMENTS = `${STAGE_1}.CHANGE_ADDITIONAL_REQUIREMENTS`;

/**
 * Stage 2
 * Booking Summary
 */
const STAGE_2 = `${C}.STAGE_2`;
export const STAGE_2_CLICK_NEXT = `${STAGE_2}.CLICK_NEXT`;
export const STAGE_2_CLICK_NEXT_COMPLETED = `${STAGE_2}.CLICK_NEXT_COMPLETED`;
export const STAGE_2_CLICK_NEXT_FAILED = `${STAGE_2}.CLICK_NEXT_FAILED`;
export const STAGE_2_CLICK_CANCEL = `${STAGE_2}.CLICK_CANCEL`;
export const STAGE_2_CLICK_CANCEL_COMPLETED = `${STAGE_2}.CLICK_CANCEL_COMPLETED`;
export const STAGE_2_CLICK_CANCEL_FAILED = `${STAGE_2}.CLICK_CANCEL_FAILED`;
export const STAGE_2_CLICK_BACK = `${STAGE_2}.CLICK_BACK`;
export const STAGE_2_CLICK_BACK_COMPLETED = `${STAGE_2}.CLICK_BACK_COMPLETED`;
export const STAGE_2_CLICK_BACK_FAILED = `${STAGE_2}.CLICK_BACK_FAILED`;

/**
 * Stage 3
 * Payment
 */
const STAGE_3 = `${C}.STAGE_3`;
export const STAGE_3_CLICK_NEXT = `${STAGE_3}.CLICK_NEXT`;
export const STAGE_3_CLICK_NEXT_COMPLETED = `${STAGE_3}.CLICK_NEXT_COMPLETED`;
export const STAGE_3_CLICK_NEXT_FAILED = `${STAGE_3}.CLICK_NEXT_FAILED`;
export const STAGE_3_CLICK_CANCEL = `${STAGE_3}.CLICK_CANCEL`;
export const STAGE_3_CLICK_CANCEL_COMPLETED = `${STAGE_3}.CLICK_CANCEL_COMPLETED`;
export const STAGE_3_CLICK_CANCEL_FAILED = `${STAGE_3}.CLICK_CANCEL_FAILED`;
export const STAGE_3_CLICK_BACK = `${STAGE_3}.CLICK_BACK`;
export const STAGE_3_CLICK_BACK_COMPLETED = `${STAGE_3}.CLICK_BACK_COMPLETED`;
export const STAGE_3_CLICK_BACK_FAILED = `${STAGE_3}.CLICK_BACK_FAILED`;

/**
 * Stage 4
 * Booking confirmation
 */
const STAGE_4 = `${C}.STAGE_4`;
export const STAGE_4_CLICK_NEXT = `${STAGE_4}.CLICK_NEXT`;
export const STAGE_4_CLICK_NEXT_COMPLETED = `${STAGE_4}.CLICK_NEXT_COMPLETED`;
export const STAGE_4_CLICK_NEXT_FAILED = `${STAGE_4}.CLICK_NEXT_FAILED`;
export const STAGE_4_CLICK_CANCEL = `${STAGE_4}.CLICK_CANCEL`;
export const STAGE_4_CLICK_CANCEL_COMPLETED = `${STAGE_4}.CLICK_CANCEL_COMPLETED`;
export const STAGE_4_CLICK_CANCEL_FAILED = `${STAGE_4}.CLICK_CANCEL_FAILED`;
export const STAGE_4_CLICK_BACK = `${STAGE_4}.CLICK_BACK`;
export const STAGE_4_CLICK_BACK_COMPLETED = `${STAGE_4}.CLICK_BACK_COMPLETED`;
export const STAGE_4_CLICK_BACK_FAILED = `${STAGE_4}.CLICK_BACK_FAILED`;

/**
 * Stage 5
 * Finished
 */
const STAGE_5 = `${C}.STAGE_5`;
export const STAGE_5_LOAD_INVOICE_HTML = `${STAGE_5}.LOAD_INVOICE_HTML`;
export const STAGE_5_LOAD_INVOICE_HTML_COMPLETED = `${STAGE_5}.LOAD_INVOICE_HTML_COMPLETED`;
export const STAGE_5_LOAD_INVOICE_HTML_FAILED = `${STAGE_5}.LOAD_INVOICE_HTML_FAILED`;

export const STAGE_5_CLICK_GO_HOME = `${STAGE_5}.CLICK_GO_HOME`;
export const STAGE_5_CLICK_EXPORT_PDF = `${STAGE_5}.CLICK_EXPORT_PDF`;
export const STAGE_5_CLICK_EXPORT_PDF_COMPLETED = `${STAGE_5}.CLICK_EXPORT_PDF_COMPLETED`;
export const STAGE_5_CLICK_EXPORT_PDF_FAILED = `${STAGE_5}.CLICK_EXPORT_PDF_FAILED`;
