import { Button, Container, Grid, Link, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { Popconfirm } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link as ReactLink } from "react-router-dom";
import * as PATH from "../../constants/paths.constants";
import { downloadInvoice } from "../../helpers/helpers";

const MyBooking = ({
  t,
  confirmationNo,
  bookingStatus,
  paymentStatus,
  paymentMethod,

  isCancelling,
  canCancelBooking,

  isFetching,
  isLoadingInvoiceHtml,
  invoiceHtml,
  invoiceUrl,

  onCancelBooking,
}) => (
  <Container>
    <Paper style={{ padding: 48, marginTop: 36 }}>
      <Grid container spacing={6} justify="space-between">
        <Grid item container xs={8} direction="column">
          <Grid item>
            <Typography variant="h6" color="primary">
              {t("BOOKING DETAIL")}
            </Typography>
          </Grid>

          <Grid item container style={{ marginTop: 32 }} spacing={2}>
            <Grid item xs={3}>
              <Typography variant="subtitle1">
                <b>{t("Confirmation No")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {isFetching ? t("Loading...") : confirmationNo}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="subtitle1">
                <b>{t("Booking Status")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {isFetching ? t("Loading...") : t(bookingStatus)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="subtitle1">
                <b>{t("Payment Method")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {isFetching ? t("Loading...") : t(paymentMethod)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="subtitle1">
                <b>{t("Payment Status")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {isFetching ? t("Loading...") : t(paymentStatus)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={4} spacing={16} direction="column" alignItems="flex-end">
          <Grid item>
            <ReactLink underline="none" to={PATH.MyBookingPage}>
              <Button style={{ minWidth: 172 }} variant="contained" color="primary">
                {t("MY BOOKING")}
              </Button>
            </ReactLink>
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            <Link
              disabled={isFetching || isLoadingInvoiceHtml}
              onClick={() => downloadInvoice(invoiceUrl)}>
              <Button
                disabled={isFetching || isLoadingInvoiceHtml}
                style={{ minWidth: 172 }}
                variant="contained"
                color="default">
                {t("EXPORT PDF")}
              </Button>
            </Link>
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            <Popconfirm
              placement="leftBottom"
              title={t("Are you sure?")}
              onConfirm={onCancelBooking}
              okText={t("Yes")}
              cancelText={t("No")}
              disabled={isFetching || !canCancelBooking || isCancelling}>
              <Button
                style={{ minWidth: 172 }}
                variant="contained"
                disabled={isFetching || !canCancelBooking || isCancelling}>
                {isCancelling && <CircularProgress size={16} />}
                {t("CANCEL BOOKING")}
              </Button>
            </Popconfirm>
          </Grid>
        </Grid>
      </Grid>

      {isLoadingInvoiceHtml && <CircularProgress size={32} />}

      {!isLoadingInvoiceHtml && !isFetching && invoiceHtml && (
        <iframe
          srcDoc={invoiceHtml}
          frameborder="0"
          allowfullscreen
          style={{ width: "100%", height: "968px" }}></iframe>
      )}
    </Paper>
  </Container>
);

export default withTranslation("booking")(MyBooking);
