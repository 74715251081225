import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { Modal } from "antd";
import { withTranslation } from "react-i18next";

import LoginComponent from "../Auth/Login/Login";

function LoginModal(props) {
  const { isOpen, closeModal } = props;

  return (
    <Modal
      // title="Basic Modal"
      visible={isOpen}
      // onOk={this.handleOk}
      onCancel={closeModal}
      footer={false}
      // width={1000}
    >
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <LoginComponent />
        </Grid>
      </Grid>
    </Modal>
  );
}

LoginModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  login: PropTypes.func,
  isLogining: PropTypes.bool,
  form: PropTypes.object,
};

export default withTranslation("common")(LoginModal);
