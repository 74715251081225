import * as _ from "lodash";
import moment from "moment";
import { combineReducers } from "redux";

import * as C from "../actionTypes/bookingSession";

import { BOOK_FOR } from "../constants/bookingSession";

/**
 * STAGE 0 Initial Stage
 */
const isShowing = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_0_SHOW_PICK_DATE_POPUP:
      return true;
    case C.STAGE_0_CLICK_CANCEL:
    case C.STAGE_0_HIDE_PICK_DATE_POPUP:
    case C.STAGE_0_SEARCHING_ROOM_SUCCESS:
    case C.SHOW_CONTACT_FORM:
      return false;
    default:
      return state;
  }
};

const roomType = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_0_START_BOOK_FOR_ROOMTYPE:
      return payload;
    case C.STAGE_0_CLICK_CANCEL:
    case C.STAGE_0_HIDE_PICK_DATE_POPUP:
    case C.STAGE_0_SEARCHING_ROOM_SUCCESS:
      return null;
    default:
      return state;
  }
};

const participantNumber = (state = 2, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_0_CHANGE_PARTICIPANT_NUMBER:
      if (payload < 0) return 0;
      return payload;
    case C.STAGE_0_HIDE_PICK_DATE_POPUP:
      return 2;
    default:
      return state;
  }
};

const bookingType = (state = BOOK_FOR.SINGLE_DAY, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_0_CHANGE_BOOKING_TYPE:
      return payload;
    case C.STAGE_0_HIDE_PICK_DATE_POPUP:
      return BOOK_FOR.SINGLE_DAY;
    default:
      return state;
  }
};

const initialDate = () => moment(moment().format("YYYY-MM-DD") + "T" + "09:00");

const startDate = (state = initialDate(), action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_0_CHANGE_BOOKING_START_DATE:
      return moment(payload.format("YYYY-MM-DD") + "T" + state.format("HH:mm:ss"));
    case C.STAGE_0_CHANGE_BOOKING_START_TIME:
      return moment(state.format("YYYY-MM-DD") + "T" + `${_.padStart(payload, 2, "0")}:00`);
    default:
      return state;
  }
};

const endDate = (state = initialDate().add(1, "days").add(8, "hours"), action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_0_CHANGE_BOOKING_START_DATE:
      return moment(
        payload.clone().add(1, "days").format("YYYY-MM-DD") + "T" + state.format("HH:mm:ss"),
      );
    case C.STAGE_0_CHANGE_BOOKING_END_DATE:
      return moment(payload.format("YYYY-MM-DD") + "T" + state.format("HH:mm:ss"));
    case C.STAGE_0_CHANGE_BOOKING_START_TIME:
      let endTime = state.hour();
      endTime = endTime > payload ? endTime : payload + 1;
      return moment(state.format("YYYY-MM-DD") + "T" + `${_.padStart(endTime, 2, "0")}:00`);
    case C.STAGE_0_CHANGE_BOOKING_END_TIME:
      return moment(state.format("YYYY-MM-DD") + "T" + `${_.padStart(payload, 2, "0")}:00`);
    default:
      return state;
  }
};

const hasPresentationDevice = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_0_TOGGLE_PRESENTATION_DEVICE:
      return !state;
    case C.STAGE_0_SHOW_PICK_DATE_POPUP:
      return false;
    default:
      return state;
  }
};

const isSearching = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_0_SEARCHING_ROOM:
      return true;
    case C.STAGE_0_SEARCHING_ROOM_SUCCESS:
    case C.STAGE_0_SEARCHING_ROOM_FAIL:
      return false;
    default:
      return state;
  }
};

const leadTimeList = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_0_RETRIEVE_LEAD_TIME_COMPLETED:
      return payload;
    default:
      return state;
  }
};

const holidayWarning = combineReducers({
  isShowing: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_0_SHOW_HOLIDAY_WARNING_POPUP:
        return true;
      case C.STAGE_0_HIDE_HOLIDAY_WARNING_POPUP:
      case C.SHOW_CONTACT_FORM:
        return false;
      default:
        return state;
    }
  },
});

const stage0Reducer = combineReducers({
  isShowing,
  roomType,
  participantNumber,
  bookingType,
  startDate,
  endDate,
  hasPresentationDevice,
  isSearching,
  leadTimeList,
  holidayWarning,
});

const stage0 = (state, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
      state = undefined;
  }

  return stage0Reducer(state, action);
};

/**
 * STAGE 1 Booking Configuration
 */
const isFetchingSelectableExFacilityCategories = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_FETCH_EX_FACILITY_CATEGORIES:
      return true;
    case C.STAGE_1_FETCH_EX_FACILITY_CATEGORIES_COMPLETED:
    case C.STAGE_1_FETCH_EX_FACILITY_CATEGORIES_FAILED:
      return false;
    default:
      return state;
  }
};

const selectableExFacilityCategories = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_FETCH_EX_FACILITY_CATEGORIES_COMPLETED:
      return payload;
    default:
      return state;
  }
};

const selectedExFacilityCategory = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_CHANGE_EX_FACILITY:
      return payload;
    case C.STAGE_1_ADD_EX_FACILITY_ITEM_COMPLETE:
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
      return null;
    default:
      return state;
  }
};

const isFetchingSelectableExFacilityAvailabilities = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_FETCH_EX_FACILITY_AVAILABILITY:
      return true;
    case C.STAGE_1_FETCH_EX_FACILITY_AVAILABILITY_COMPLETED:
    case C.STAGE_1_FETCH_EX_FACILITY_AVAILABILITY_FAILED:
      return false;
    default:
      return state;
  }
};

const bookableExFacilityAvailabilities = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_FETCH_EX_FACILITY_AVAILABILITY_COMPLETED:
      return payload;
    default:
      return state;
  }
};

const selectedExFacilityCategoryQuantity = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_CHANGE_EX_FACILITY_QUANTITY:
      return payload;
    case C.STAGE_1_CHANGE_EX_FACILITY:
    case C.STAGE_1_ADD_EX_FACILITY_ITEM_COMPLETE:
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
      return null;
    default:
      return state;
  }
};

const isFetchingExFacilityItemPricings = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS:
      return true;
    case C.STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS_COMPLETED:
    case C.STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS_FAILED:
      return false;
    default:
      return state;
  }
};

const exFacilityItemPricings = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS_COMPLETED:
      return payload;
    case C.STAGE_1_CHANGE_EX_FACILITY:
    case C.STAGE_1_ADD_EX_FACILITY_ITEM_COMPLETE:
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
      return [];
    default:
      return state;
  }
};

const isAddingExFacilityCategoryItems = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_ADD_EX_FACILITY_ITEM:
      return true;
    case C.STAGE_1_ADD_EX_FACILITY_ITEM_COMPLETE:
    case C.STAGE_1_ADD_EX_FACILITY_ITEM_FAILED:
      return false;
    default:
      return state;
  }
};

const removingExFacilityCategoryItems = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_REMOVE_EX_FACILITY_ITEM:
      return payload;
    case C.STAGE_1_REMOVE_EX_FACILITY_ITEM_COMPLETE:
    case C.STAGE_1_REMOVE_EX_FACILITY_ITEM_FAILED:
      return null;
    default:
      return state;
  }
};

const isFetchingSelectableCateringServices = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_FETCH_CATERING_SERVICES:
      return true;
    case C.STAGE_1_FETCH_CATERING_SERVICES_COMPLETED:
    case C.STAGE_1_FETCH_CATERING_SERVICES_FAILED:
      return false;
    default:
      return state;
  }
};

const selectableCateringServices = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_FETCH_CATERING_SERVICES_COMPLETED:
      return payload;
    default:
      return state;
  }
};

const selectedCateringService = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_CHANGE_CATERING_SERVICE:
      return payload;
    case C.STAGE_1_ADD_CATERING_SERVICE_ITEM_COMPLETED:
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
      return null;
    default:
      return state;
  }
};

const cateringServiceQuantity = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_CHANGE_CATERING_SERVICE_QUANTITY:
      return payload;
    case C.STAGE_1_ADD_CATERING_SERVICE_ITEM_COMPLETED:
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
      return null;
    default:
      return state;
  }
};

const isFetchingCateringServicePricings = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS:
      return true;
    case C.STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS_COMPLETED:
    case C.STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS_FAILED:
      return false;
    default:
      return state;
  }
};

const cateringServiceItemPricings = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS_COMPLETED:
      return payload;
    case C.STAGE_1_ADD_CATERING_SERVICE_ITEM_COMPLETED:
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
      return [];
    default:
      return state;
  }
};

const isAddingCateringServiceItem = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_ADD_CATERING_SERVICE_ITEM:
      return true;
    case C.STAGE_1_ADD_CATERING_SERVICE_ITEM_COMPLETED:
    case C.STAGE_1_ADD_CATERING_SERVICE_ITEM_FAILED:
      return false;
    default:
      return state;
  }
};

const removingCateringServiceItem = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_REMOVE_CATERING_SERVICE_ITEM:
      return payload;
    case C.STAGE_1_REMOVE_CATERING_SERVICE_ITEM_COMPLETED:
    case C.STAGE_1_REMOVE_CATERING_SERVICE_ITEM_FAILED:
      return null;
    default:
      return state;
  }
};

const isNextSubmitting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_CLICK_NEXT:
      return true;
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_1_CLICK_NEXT_FAILED:
      return false;
    default:
      return state;
  }
};

const isCancelSubmitting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.STAGE_1_CLICK_CANCEL:
      return true;
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
    case C.STAGE_1_CLICK_CANCEL_FAILED:
      return false;
    default:
      return state;
  }
};

const additionalRequirements = (state = "", action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_1_CHANGE_ADDITIONAL_REQUIREMENTS:
      return payload;
    case C.STAGE_0_SEARCHING_ROOM_SUCCESS:
    case C.CONTINUE_BOOKING_PROCESS_COMPLETED:
    case C.STAGE_2_CLICK_BACK_COMPLETED:
      return _.get(payload, "additional_requirement", "");
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
    case C.STAGE_2_CLICK_CANCEL_COMPLETED:
    case C.STAGE_3_CLICK_CANCEL_COMPLETED:
    case C.STAGE_4_CLICK_CANCEL_COMPLETED:
      return "";
    default:
      return state;
  }
};

const stage1 = combineReducers({
  isFetchingSelectableExFacilityCategories,
  selectableExFacilityCategories,
  selectedExFacilityCategory,

  isFetchingSelectableCateringServices,
  selectableCateringServices,
  selectedCateringService,
  cateringServiceQuantity,
  isFetchingCateringServicePricings,
  cateringServiceItemPricings,
  isAddingCateringServiceItem,
  removingCateringServiceItem,

  isFetchingSelectableExFacilityAvailabilities,
  bookableExFacilityAvailabilities,
  selectedExFacilityCategoryQuantity,

  isFetchingExFacilityItemPricings,
  exFacilityItemPricings,

  additionalRequirements,

  isAddingExFacilityCategoryItems,
  removingExFacilityCategoryItems,

  isNextSubmitting,
  isCancelSubmitting,
});

/** STAGE 2 - Booking Summary */
const stage2 = combineReducers({
  isNextSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_2_CLICK_NEXT:
        return true;
      case C.STAGE_2_CLICK_NEXT_COMPLETED:
      case C.STAGE_2_CLICK_NEXT_FAILED:
        return false;
      default:
        return state;
    }
  },

  isBackSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_2_CLICK_BACK:
        return true;
      case C.STAGE_2_CLICK_BACK_COMPLETED:
      case C.STAGE_2_CLICK_BACK_FAILED:
        return false;
      default:
        return state;
    }
  },

  isCancelSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_2_CLICK_CANCEL:
        return true;
      case C.STAGE_2_CLICK_CANCEL_COMPLETED:
      case C.STAGE_2_CLICK_CANCEL_FAILED:
        return false;
      default:
        return state;
    }
  },
});

/** STAGE 3 - Booking Summary */
const stage3 = combineReducers({
  isNextSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_3_CLICK_NEXT:
        return true;
      case C.STAGE_3_CLICK_NEXT_COMPLETED:
      case C.STAGE_3_CLICK_NEXT_FAILED:
        return false;
      default:
        return state;
    }
  },

  isBackSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_3_CLICK_BACK:
        return true;
      case C.STAGE_3_CLICK_BACK_COMPLETED:
      case C.STAGE_3_CLICK_BACK_FAILED:
        return false;
      default:
        return state;
    }
  },

  isCancelSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_3_CLICK_CANCEL:
        return true;
      case C.STAGE_3_CLICK_CANCEL_COMPLETED:
      case C.STAGE_3_CLICK_CANCEL_FAILED:
        return false;
      default:
        return state;
    }
  },
});

/** STAGE 4 - Booking Confirmation */
const stage4 = combineReducers({
  isNextSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_4_CLICK_NEXT:
        return true;
      case C.STAGE_4_CLICK_NEXT_COMPLETED:
      case C.STAGE_4_CLICK_NEXT_FAILED:
        return false;
      default:
        return state;
    }
  },

  isBackSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_4_CLICK_BACK:
        return true;
      case C.STAGE_4_CLICK_BACK_COMPLETED:
      case C.STAGE_4_CLICK_BACK_FAILED:
        return false;
      default:
        return state;
    }
  },

  isCancelSubmitting: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_4_CLICK_CANCEL:
        return true;
      case C.STAGE_4_CLICK_CANCEL_COMPLETED:
      case C.STAGE_4_CLICK_CANCEL_FAILED:
        return false;
      default:
        return state;
    }
  },
});

/** stage 5 - Booking Complete */
const stage5 = combineReducers({
  isLoadingInvoiceHtml: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.STAGE_5_LOAD_INVOICE_HTML:
        return true;
      case C.STAGE_5_LOAD_INVOICE_HTML_COMPLETED:
      case C.STAGE_5_LOAD_INVOICE_HTML_FAILED:
        return false;
      default:
        return state;
    }
  },
  invoiceHtml: (state = "", action) => {
    const { type, payload } = action;
    switch (type) {
      case C.STAGE_5_LOAD_INVOICE_HTML_COMPLETED:
        return payload;
      case C.STAGE_5_LOAD_INVOICE_HTML_FAILED:
        return "";
      default:
        return state;
    }
  },
});

/**
 * Global booking session object
 */
const sessionData = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case C.STAGE_0_SEARCHING_ROOM_SUCCESS:
    case C.CONTINUE_BOOKING_PROCESS_COMPLETED:
    case C.STAGE_1_ADD_EX_FACILITY_ITEM_COMPLETE:
    case C.STAGE_1_ADD_CATERING_SERVICE_ITEM_COMPLETED:
    case C.STAGE_1_REMOVE_CATERING_SERVICE_ITEM_COMPLETED:
    case C.STAGE_1_REMOVE_EX_FACILITY_ITEM_COMPLETE:
    case C.STAGE_1_CLICK_NEXT_COMPLETED:
    case C.STAGE_2_CLICK_NEXT_COMPLETED:
    case C.STAGE_2_CLICK_BACK_COMPLETED:
    case C.STAGE_3_CLICK_NEXT_COMPLETED:
    case C.STAGE_3_CLICK_BACK_COMPLETED:
    case C.STAGE_4_CLICK_BACK_COMPLETED:
      return payload;
    case C.STAGE_4_CLICK_NEXT_COMPLETED:
      const cloneState = _.cloneDeep(state);
      cloneState.stage = 5;
      return cloneState;
    case C.STAGE_1_CLICK_CANCEL_COMPLETED:
    case C.STAGE_2_CLICK_CANCEL_COMPLETED:
    case C.STAGE_3_CLICK_CANCEL_COMPLETED:
    case C.STAGE_4_CLICK_CANCEL_COMPLETED:
      return {};
    default:
      return state;
  }
};

const isBookingProcessContinuing = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case C.CONTINUE_BOOKING_PROCESS:
      return true;
    case C.CONTINUE_BOOKING_PROCESS_COMPLETED:
    case C.CONTINUE_BOOKING_PROCESS_FAILED:
      return false;
    default:
      return state;
  }
};

const contact = combineReducers({
  isShowing: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.SHOW_CONTACT_FORM:
        return true;
      case C.HIDE_CONTACT_FORM:
        return false;
      default:
        return state;
    }
  },
});

const bookingSession = combineReducers({
  stage0,
  stage1,
  stage2,
  stage3,
  stage4,
  stage5,
  sessionData,
  contact,
  isBookingProcessContinuing,
});

export default bookingSession;
