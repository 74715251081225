import * as _ from "lodash";
import { createSelector } from "reselect";

const getReducer = ({ bookingSession }) => bookingSession.stage5;

export const isLoadingInvoiceHtml = createSelector(
  [getReducer],
  ({ isLoadingInvoiceHtml }) => isLoadingInvoiceHtml,
);

export const getInvoiceHtml = createSelector([getReducer], ({ invoiceHtml }) =>
  _.get(invoiceHtml, "html", ""),
);

export const getInvoicePdfUrl = createSelector(
  [getReducer],
  ({ invoiceHtml: { pdfUrl } }) => pdfUrl,
);
