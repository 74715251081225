import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import * as _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { getImage } from "../../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
  roomImage: {
    width: "100px",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f7f7f7",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    borderBottom: "1px solid #ddd",
    minHeight: "160px",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
}));

const RoomComponent = (props) => {
  const { roomInfo, openViewRoomModal, t } = props;

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={_.get(roomInfo, "images[0].url", getImage(roomInfo.id))}
        title="Image title"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h6" component="h2" align="left">
          {roomInfo.room_type}
        </Typography>
        <Typography variant="subtitle1" align="left">
          {parse((_.split(roomInfo.description || "", /\n/) || [""])[0].replace(/\n/gi, "<br />"))}
        </Typography>
      </CardContent>
      <CardActions className={classes.justifyContentCenter}>
        <Button size="small" color="primary" onClick={() => openViewRoomModal(roomInfo)}>
          {t("16")}
        </Button>
      </CardActions>
    </Card>
  );
};

RoomComponent.propTypes = {
  roomInfo: PropTypes.object,
  openViewRoomModal: PropTypes.func,
};

export default withTranslation("common")(RoomComponent);
