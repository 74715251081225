export const MY_BOOKING_UPCOMING_TAB = 0;
export const MY_BOOKING_HISTORY_TAB = 1;

export const BOOKING_STATUS = {
  SCHEDULED: 1,
  CANCELLED: 2,
};

export const PAYMENT_STATUS = {
  OUTSTANDING: 1,
  DONE: 2,
  FAILED: 3,
  CANCELLED: 4,
  PARTIAL_REFUND: 5,
  FULL_REFUND: 6,
};
