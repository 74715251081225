import LOGO from "../assets/images/ecos_Logo_Stuttgart_CMYK.png";
import HEADER_SLIDE_01_IMG from "../assets/images/header_slide_01.jpg";
import HEADER_SLIDE_02_IMG from "../assets/images/header_slide_02.jpg";
import HEADER_SLIDE_03_IMG from "../assets/images/header_slide_03.jpg";
import HEADER_SLIDE_04_IMG from "../assets/images/header_slide_04.jpg";
import HEADER_SLIDE_05_IMG from "../assets/images/header_slide_05.jpg";
import HEADER_SLIDE_06_IMG from "../assets/images/header_slide_06.jpg";
import HEADER_SLIDE_07_IMG from "../assets/images/header_slide_07.jpg";
import HEADER_SLIDE_08_IMG from "../assets/images/header_slide_08.jpg";
import HEADER_SLIDE_09_IMG from "../assets/images/header_slide_09.jpg";
import HEADER_SLIDE_10_IMG from "../assets/images/header_slide_10.jpg";
import FOOTER_IMG from "../assets/images/footer.png";
import AGB_PDF from "../assets/agbs.pdf";

export default {
  LOGO,
  HEADER_SLIDE_01_IMG,
  HEADER_SLIDE_02_IMG,
  HEADER_SLIDE_03_IMG,
  HEADER_SLIDE_04_IMG,
  HEADER_SLIDE_05_IMG,
  HEADER_SLIDE_06_IMG,
  HEADER_SLIDE_07_IMG,
  HEADER_SLIDE_08_IMG,
  HEADER_SLIDE_09_IMG,
  HEADER_SLIDE_10_IMG,
  FOOTER_IMG,
  AGB_PDF,
};
