import { AppBar, Container, Grid, Tab, Tabs, Toolbar, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination, Tag } from "antd";
import _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  toolbarSecondary: {
    justifyContent: "space-between",
    backgroundColor: "#840810",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  formContent: {
    justifyContent: "center",
  },
  formRoot: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
      "& .MuiInputBase-input": {
        padding: "10.5px 14px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ddd !important",
        color: "#ddd !important",
      },
    },
  },
  formField: {
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      borderRadius: "15px",
    },
    "& .MuiInputLabel-formControl": {
      top: "-5px",
    },
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  pageTitle: {
    color: "white",
    padding: "15px 0",
  },
  searchTitle: {
    margin: "auto 0",
  },
  searchContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchField: {},
  searchButton: {
    margin: "8px",
  },
  tabsContent: {
    margin: "20px 0",
    boxShadow: "unset",
    "& .MuiTabs-flexContainer": {
      backgroundColor: "white",
      borderBottom: "3px solid #f44336",
    },
    "& .MuiButtonBase-root": {
      // backgroundColor: theme.colorAccent,
      color: theme.colorAccent,
    },
    "& .Mui-selected": {
      backgroundColor: theme.colorAccent,
      color: "white",
    },
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyBooking = ({
  t,
  activatedView,
  pagination,
  isLoading,
  items = [],

  onChangeActivatedView,
  onChangePage,
}) => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <div>
      <Toolbar component="nav" variant="dense" className={classes.facilitiesContent}>
        <Grid container className={classes.justifyContentCenter}>
          <Container>
            <Grid item xs={12}>
              <AppBar position="" className={classes.tabsContent}>
                <Tabs value={activatedView} onChange={(e, v) => onChangeActivatedView(v)}>
                  <Tab label={t("68")} {...a11yProps(0)} />
                  <Tab label={t("69")} {...a11yProps(1)} />
                </Tabs>
              </AppBar>
            </Grid>

            <Paper style={{ padding: 36 }}>
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={1}>
                  <Typography variant="subtitle2">
                    <b>{t("Confirmation No")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">
                    <b>{t("Room")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">
                    <b>{t("Price")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">
                    <b>{t("Participants")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">
                    <b>{t("Booking Date")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">
                    <b>{t("Payment Status")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">
                    <b>{t("Booking Status")}</b>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              {_.map(items, (it) => [
                <Grid
                  container
                  spacing={2}
                  justify="space-between"
                  style={{ marginTop: 4, marginBottom: 4 }}
                  key={`grid-${it.id}`}>
                  <Grid item xs={1}>
                    <Link to={`${match.path}${it.id}`}>
                      <Typography variant="subtitle2" color="primary">
                        {it.confirmNo}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">{it.roomType}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle2">{it.price}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle2">{it.participants}</Typography>
                  </Grid>
                  <Grid item xs={2} container direction="column">
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Typography variant="subtitle2">{t("From")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">{it.startAt}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Typography variant="subtitle2">{t("To")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">{it.endAt}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Tag color={_.get(it, "paymentStatus.color")}>
                      {t(_.get(it, "paymentStatus.name"))}
                    </Tag>
                  </Grid>
                  <Grid item xs={2}>
                    <Tag color={_.get(it, "bookingStatus.color")}>
                      {t(_.get(it, "bookingStatus.name"))}
                    </Tag>
                  </Grid>
                </Grid>,
                <Divider key={`divd-${it.id}`} />,
              ])}
            </Paper>

            {isLoading && <LinearProgress color="primary" />}
            <Grid container justify="flex-end" style={{ marginTop: 32, marginBottom: 64 }}>
              <Grid item>
                <Pagination
                  hideOnSinglePage={true}
                  current={_.get(pagination, "currentPage")}
                  total={_.get(pagination, "total")}
                  onChange={(page, pageSize) => onChangePage(page)}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Toolbar>
    </div>
  );
};

export default withTranslation("common")(MyBooking);
