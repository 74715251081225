import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import * as authActions from "../../actions/auth";
import * as modalActions from "../../actions/modal";

import HeaderComponent from "../../components/Header";
import LoginModal from "../../components/Modal/LoginModal";

import * as authSelectors from "../../selectors/auth";

const Header = ({ isLogined, size, isOpenLoginModal, closeLoginModal }) => {
  return (
    <div>
      <HeaderComponent {...{ isLogined, size }} />
      <LoginModal isOpen={isOpenLoginModal && !isLogined} closeModal={closeLoginModal} />
    </div>
  );
};

const mapState = (state) => ({
  isLogined: authSelectors.getIsLogined(state),
  isOpenLoginModal: state.modalReducer.isOpenLoginModal,
});

const mapDispatch = (dispatch) => ({
  login() {
    dispatch(authActions.loginNotRedirect());
  },
  openLoginModal() {
    dispatch(modalActions.openLoginModal());
  },
  closeLoginModal() {
    dispatch(modalActions.closeLoginModal());
  },
});

Header.propTypes = {
  isLogined: PropTypes.bool,
  size: PropTypes.bool,
};

export default connect(mapState, mapDispatch)(Header);
