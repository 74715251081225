import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ToastContainer } from "react-toastify";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { I18nextProvider } from "react-i18next";
import { ParallaxProvider } from "react-scroll-parallax";

import configStore, { history } from "./config/store";
import App from "./containers/App";

import themeDefault from "./theme";

import "./config/i18next";

import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.less";
import "./assets/styles/app.scss";
import "./App.css";

const store = configStore();

const theme = createMuiTheme(themeDefault);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
          <ToastContainer position="top-center" autoClose={3000} style={{ zIndex: 19999 }} />
        </ThemeProvider>
      </ConnectedRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
