import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { Modal, Button, Collapse, Checkbox } from "antd";

const { Panel } = Collapse;

const CookieConsent = ({
  t,
  visible,
  statistics,
  comfort,

  onSelectAll = (f) => f,
  onConfirm = (f) => f,
  onStatisticsChange = (f) => f,
  onComfortChange = (f) => f,
}) => (
  <Modal
    closable={false}
    visible={visible}
    centered
    width={window.innerWidth * 0.7}
    footer={
      <Grid container justify="flex-end" width="100%" spacing={2}>
        <Grid item>
          <Button onClick={onConfirm}>{t("Confirm Selection")}</Button>
        </Grid>
        <Grid item>
          <Button type="primary" onClick={onSelectAll}>
            {t("Select All")}
          </Button>
        </Grid>
      </Grid>
    }>
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">
          <b>{t("Cookie Preferences")}</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">{t("Cookie Content")}</Typography>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item>
          <Checkbox checked disabled>
            {t("Required")}
          </Checkbox>
        </Grid>
        <Grid item>
          <Checkbox checked={statistics} onChange={(e) => onStatisticsChange(e.target.checked)}>
            {t("Statistics")}
          </Checkbox>
        </Grid>
        <Grid item>
          <Checkbox checked={comfort} onChange={(e) => onComfortChange(e.target.checked)}>
            {t("Comfort")}
          </Checkbox>
        </Grid>
      </Grid>
      <Grid item>
        <Collapse bordered={false} style={{ backgroundColor: "white" }}>
          <Panel
            header={
              <Typography color="primary" variant="subtitle1">
                {t("View Details")}
              </Typography>
            }
            key="1"
            style={{ borderBottom: "0px solid" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="subtitle1">
                    <b>{t("Required")}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">{t("Required Content")}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="subtitle1">
                    <b>{t("Statistics")}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">{t("Statistics Content")}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="subtitle1">
                    <b>{t("Comfort")}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">{t("Comfort Content")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Panel>
        </Collapse>
      </Grid>
    </Grid>
  </Modal>
);

export default withTranslation("common")(CookieConsent);
