import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import authReducer from "./auth";
import booking from "./booking";
import bookingSession from "./bookingSession";
import modalReducer from "./modal";
import publicReducer from "./public";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    publicReducer,
    modalReducer,
    bookingSession,
    booking,
  });
