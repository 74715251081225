import React, { Component } from "react";

import VerifyComponent from "../../../components/Auth/Verify";
class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerified: false,
    };
  }

  verifyAccount = () => {
    this.setState({ isVerified: true });
  };

  render() {
    const { isVerified } = this.state;

    return (
      <div>
        <VerifyComponent verifyAccount={() => this.verifyAccount()} {...{ isVerified }} />
      </div>
    );
  }
}

export default Verify;
