import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { withTranslation } from "react-i18next";
import * as PATH from "../../constants/paths.constants";
import { downloadInvoice } from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 48,
    margin: "auto",
    marginBottom: 84,
  },
  image: {
    width: 240,
    height: 240,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  },
}));

const Stage5 = ({ t, isLoadingInvoiceHtml, invoiceHtml, invoiceUrl }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={6} justify="space-between">
        <Grid item container xs={8} direction="column">
          <Grid item>
            <Typography variant="h6" color="primary">
              {t("BOOKING SUCCESSFULLY CREATED!")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">--</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("Please check your email for the booking details")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("Any other requirements or changes, please contact our staffs via")}
            </Typography>
          </Grid>

          <Grid item style={{ marginTop: 32 }}>
            <Typography variant="subtitle1">{t("Thank you!")}</Typography>
          </Grid>
        </Grid>

        <Grid item container xs={4} spacing={16} direction="column" alignItems="flex-end">
          <Grid item>
            <Link underline="none" href={PATH.MyBookingPage} style={{ textDecoration: "none" }}>
              <Button style={{ minWidth: 160 }} variant="contained" color="primary">
                {t("MY BOOKING")}
              </Button>
            </Link>
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            <span disabled={isLoadingInvoiceHtml} onClick={() => downloadInvoice(invoiceUrl)}>
              <Button style={{ minWidth: 160 }} variant="contained" color="default">
                {t("EXPORT PDF")}
              </Button>
            </span>
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            <Link underline="none" href={PATH.HomePage} style={{ textDecoration: "none" }}>
              <Button style={{ minWidth: 160 }} variant="contained" color="default">
                {t("New Booking")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      {isLoadingInvoiceHtml && <CircularProgress size={32} />}

      {invoiceHtml && (
        <iframe
          title=""
          srcDoc={invoiceHtml}
          frameborder="0"
          allowfullscreen
          style={{ width: "100%", height: "968px" }}></iframe>
      )}
    </Paper>
  );
};

export default withTranslation("booking")(Stage5);
