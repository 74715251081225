import { Container, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import Header from "../Header";

const EnContent = () => (
  <Grid container spacing={4} direction="column">
    <Grid item>
      <Typography variant="h4">Imprint</Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">ePLAZA office center</Typography>
      <Typography variant="subtitle1">Martina Scheel</Typography>
      <Typography variant="subtitle1">Stockholmer Platz 1</Typography>
      <Typography variant="subtitle1">70173 Stuttgart</Typography>
      <Typography variant="subtitle1">Tel: +49 711 7811 – 500</Typography>
      <Typography variant="subtitle1">Fax: +49 711 7811 – 501</Typography>
      <Typography variant="subtitle1">
        e-mail: <Link href="mailto:info@eplaza-office.de">info@eplaza-office.de</Link>
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">
        <b>Authorised representative</b>: Martina Scheel, Diplom-Betriebswirtin
      </Typography>
      <Typography variant="subtitle1">
        <b>USt-IdNr</b>: DE171390615
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">
        <b>Kammer</b>: IHK Stuttgart
      </Typography>
      <Typography variant="subtitle1">
        <b>Gerichtsstand</b>: Stuttgart
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">
        <b>Disclaimer</b>: By judgement dated May 12 1998, the Hamburg District Court ruled that
        homepage operators may be held responsible for the contents of Internet pages linked to
        their own homepage. According to the District Court, this can only be prevented by an
        explicit dissociation from such contents on the part of the homepage operator. On this
        website, we offer links to other homepages.
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">
        The following applies to any and all such links: ePLAZA has no influence whatsoever on the
        design and contents of any linked websites. Therefore ecos explicitly dissociates itself
        from the contents of any and all linked websites and refuses to endorse any of their
        contents. This declaration applies to all links offered on this website.
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">
        <b>Disclaimer google analytics</b>: This website uses Google Analytics, a web analytics
        service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text
        files placed on your computer to help the website analyse how visitors use the site. The
        information generated by the cookie about your use of the website (including your IP
        address) will be transmitted to and stored by Google on servers in the United States. Google
        will use this information for the purpose of evaluating your use of the website.
      </Typography>
    </Grid>
  </Grid>
);

const DeContent = (props) => (
  <Grid container spacing={4} direction="column">
    <Grid item>
      <Typography variant="h4">Impressum</Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1">ePLAZA office center</Typography>
      <Typography variant="subtitle1">Martina Scheel</Typography>
      <Typography variant="subtitle1">Stockholmer Platz 1</Typography>
      <Typography variant="subtitle1">70173 Stuttgart</Typography>
      <Typography variant="subtitle1">Tel: +49 711 7811 – 500</Typography>
      <Typography variant="subtitle1">Fax: +49 711 7811 – 501</Typography>
      <Typography variant="subtitle1">
        e-mail: <Link href="mailto:info@eplaza-office.de">info@eplaza-office.de</Link>
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">
        <b>Inhaberin</b>: Martina Scheel, Diplom-Betriebswirtin
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">
        <b>Kammer</b>: IHK Stuttgart
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1">
        <b>Gerichtsstand</b>: Stuttgart
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="h5">
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="subtitle1">DE171390615</Typography>
    </Grid>

    <Grid item>
      <Typography variant="h5">Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV</Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1 ">Martina Scheel (Anschrift wie oben)</Typography>
    </Grid>
    <Grid item>
      <Typography variant="h5">Informationen zur Online-Streitbeilegung</Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1 ">
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit.
        Diese Plattform finden Sie unter folgendem Link:{" "}
        <Link href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</Link>.
        Verbraucher können diese Plattform nutzen, um ihre Streitigkeiten aus Online-Verträgen
        beizulegen.
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h5"> Hinweis gemäß § 36 VSBG </Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1">
        Wir werden nicht an alternativen Streitschlichtungsverfahren im Sinne des § 36 VSBG
        teilnehmen. Die Nutzung einer alternativen Schlichtungsstelle stellt keine zwingende
        Voraussetzung für das Anrufen zuständiger ordentlicher Gerichte dar.
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h5">Urheberrecht und Bildnachweise</Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1">
        Die Inhalte von <Link href="www.tagungstuttgart.de"> www.tagungstuttgart.de</Link> sind –
        soweit nicht abweichend angegeben – urheberrechtlich geschützt. Verwendete Fotografien sind
        ggf. mit Bildnachweisen gekennzeichnet oder unten aufgeführt, soweit sie nicht selbst
        angefertigt wurden. Die Verwendung von Fotografien auf Drittseiten ist nur im Rahmen der
        jeweiligen Lizenz der Urheber möglich.
      </Typography>
    </Grid>
  </Grid>
);

const renderView = (lang) => {
  switch (lang) {
    case "de":
      return <DeContent />;
    default:
      return <EnContent />;
  }
};

const Imprint = ({ t, i18n }) => (
  <div>
    <Header size="small" />

    <Container style={{ marginTop: 64, marginBottom: 249 }}>{renderView(i18n.language)}</Container>
  </div>
);

export default withTranslation("policies")(Imprint);
