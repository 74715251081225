import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import Stage4 from "../../components/Booking/Stage4";

import * as sessionSelector from "../../selectors/bookingSession";
import * as selector from "../../selectors/bookingSession/stage4";

const Stage4Container = (props) => {
  return <Stage4 {...props} />;
};

const mapState = (state) => ({
  bookingDate: sessionSelector.getBookingDate(state),
  participants: sessionSelector.getBookingParticipants(state),
  customer: sessionSelector.getCustomer(state),
  freeCancelDate: sessionSelector.getFreeCancelDate(state),
  items: selector.getItemsBeautify(state),
  subTotal: selector.getTotalBeautify(state),
  tax: selector.getTaxBeautify(state),
  taxRate: selector.getTaxRateBeautify(state),
  grandTotal: selector.getGrandTotalBeautify(state),
  paymentMethod: selector.getPaymentMethod(state),
  additionalRequirement: selector.getAdditionalRequirement(state),

  isNextSubmitting: selector.isNextSubmitting(state),
  isCancelSubmitting: selector.isCancelSubmitting(state),
  isBackSubmitting: selector.isBackSubmitting(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      onBackClick: actions.stage4BackClick,
      onNextClick: actions.stage4NextClick,
      onCancelClick: actions.stage4CancelClick,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(Stage4Container);
