export const CHECK_EMAIL_EXIST = "CHECK_EMAIL_EXIST";
export const CHECK_EMAIL_EXIST_SUCCESS = "CHECK_EMAIL_EXIST_SUCCESS";
export const CHECK_EMAIL_EXIST_FAIL = "CHECK_EMAIL_EXIST_FAIL";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
export const REQUEST_RESET_PASSWORD_SUCCESS = "REQUEST_RESET_PASSWORD_SUCCESS";
export const REQUEST_RESET_PASSWORD_FAIL = "REQUEST_RESET_PASSWORD_FAIL";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const LOGIN_NOT_REDIRECT = "LOGIN_NOT_REDIRECT";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";

export const RESEND_ACTIVATION_CODE = "RESEND_ACTIVATION_CODE";
export const RESEND_ACTIVATION_CODE_SUCCESS = "RESEND_ACTIVATION_CODE_SUCCESS";
export const RESEND_ACTIVATION_CODE_FAIL = "RESEND_ACTIVATION_CODE_FAIL";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const LOGOUT = "LOGOUT";

export const CHECK_TOKEN_EXPIRATION = "CHECK_TOKEN_EXPIRATION";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const RESET_CHECKING_EMAIL_RESULT = "RESET_CHECKING_EMAIL_RESULT";
