import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { withTranslation } from "react-i18next";

import { PAYMENT_METHOD } from "../../constants/bookingSession";

const { REACT_APP_STRIPE_TOKEN } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_TOKEN);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 48,
    margin: "auto",
  },
  image: {
    width: 240,
    height: 240,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  },
}));

const StripePaymentContent = ({ t, classes }) => (
  <Grid container spacing={16}>
    <Grid item xs={4}></Grid>
    <Grid item xs={4} container direction="column">
      <Grid item>
        <Typography variant="h6">
          <b>{t("Add credit card details")}</b>
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: 42 }}>
        <form className={classes.formRoot} noValidate autoComplete="off">
          <CardElement />
        </form>
      </Grid>
      <Grid item style={{ marginTop: 36 }}>
        <Typography variant="subtitle2">
          {t("You will be charged at the first day of your event")}
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={4}></Grid>
  </Grid>
);

const InvoicePaymentContent = ({ t, classes }) => (
  <Grid container spacing={16}>
    <Grid item xs={4}></Grid>
    <Grid item xs={5} container direction="column">
      <Grid item>
        <Typography variant="h6">
          <b>{t("You'll be charged later")}</b>
        </Typography>
      </Grid>
      <Grid item container style={{ marginTop: 36 }} spacing={2}>
        <Grid item>
          <Typography variant="subtitle1">{t("Payment Method")}:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <b>{t("INVOICE")}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 28 }}>
        <Typography variant="subtitle2">
          {t("You will receive INVOICE for your payment after the booking end")}
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={3}></Grid>
  </Grid>
);

const PaymentMainView = ({
  t,
  paymentMethod,
  isBackSubmitting = false,
  isCancelSubmitting = false,
  isNextSubmitting = false,

  onBackClick = (f) => f,
  onCancelClick = (f) => f,
  onNextClick = (f) => f,
}) => {
  const classes = useStyles();

  const stripe = useStripe();
  const elements = useElements();

  return (
    <Paper className={classes.paper}>
      {paymentMethod === PAYMENT_METHOD.STRIPE ? (
        <StripePaymentContent {...{ classes, t }} />
      ) : (
        <InvoicePaymentContent {...{ classes, t }} />
      )}

      <Grid container style={{ marginTop: 56 }} justify="space-between">
        <Grid item>
          <Button
            variant="contained"
            color="default"
            onClick={onCancelClick}
            style={{ marginRight: 16 }}
            disabled={isNextSubmitting || isBackSubmitting || isCancelSubmitting}>
            {isCancelSubmitting && <CircularProgress size={16} />}&nbsp;
            {t("Abort")}
          </Button>

          <Button
            variant="contained"
            color="default"
            onClick={onBackClick}
            disabled={isNextSubmitting || isBackSubmitting || isCancelSubmitting}>
            {isBackSubmitting && <CircularProgress size={16} />}&nbsp;
            {t("Back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const card = elements.getElement(CardElement);
              onNextClick({ stripe, card });
            }}
            disabled={isNextSubmitting || isBackSubmitting || isCancelSubmitting}>
            {isNextSubmitting && <CircularProgress size={16} />}&nbsp;
            {t("Next")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Stage3 = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMainView {...props} />
    </Elements>
  );
};

export default withTranslation("booking")(Stage3);
