import { createSelector } from "reselect";
import * as _ from "lodash";

const getReducer = ({ publicReducer }) => publicReducer;

export const getLocale = ({ publicReducer: { locale } }) => _.get(locale, "lang", "en");

export const getListRoomTypes = createSelector([getReducer], ({ listRoomTypes }) => listRoomTypes);

export const getListHoliday = createSelector([getReducer], ({ listHoliday }) => listHoliday);
