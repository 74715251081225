import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Icon, Input } from "antd";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  checkEmailExist,
  login,
  resetCheckingEmailResult,
} from "../../../actions/auth";

import { history } from "../../../config/store";
import { LogoImage as Logo } from "../../../constants/componentHelpers";
import * as paths from "../../../constants/paths.constants";

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      marginTop: "50px",
      marginBottom: "30px",
      color: theme.colorAccent,
      width: "60%",
      cursor: "pointer",
    },
    background: {
      "& img": {
        height: "100%",
        width: "100%",
      },
    },
    formRoot: {
      maxWidth: "300px",
      margin: "0 auto",
      // textAlign: 'center',
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    formField: {
      "& .MuiInputBase-root": {
        // backgroundColor: 'white',
        borderRadius: "15px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    textWelcome: {
      padding: "20px 0",
      color: theme.colorGray,
    },
    buttonGroup: {
      justifyContent: "center",
      "& button": {
        // width: 300,
        // margin: '25px'
      },
    },
    forgotPassword: {
      cursor: "pointer",
      textAlign: "center",
    },
    registerContent: {
      marginTop: "30px",
      textAlign: "center",
    },
    registerLink: {
      cursor: "pointer",
    },
  };
});

const Login = (props) => {
  const {
    form: { getFieldDecorator, validateFields },
    isLogining,
    login,
    t,
    checkEmailExist,
    isCheckingEmail,
    checkingEmailResult,
    resetCheckingEmailResult,
  } = props;

  const [emailChecked, setEmailChecked] = useState(false);
  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateFields(async (error, values) => {
      if (!error) {
        if (emailChecked && get(checkingEmailResult, "has_email", false)) {
          login(values);
        } else {
          checkEmailExist({ email: values.email });
          setEmailChecked(true);
        }
      }
    });
  };

  const handleDisableEmailCheck = () => {
    setEmailChecked(false);
    resetCheckingEmailResult();
  };

  return (
    <>
      <Typography variant="h2" align="center" className={classes.aa}>
        <img
          src={Logo}
          className={classes.logo}
          onClick={() => history.push(paths.HomePage)}
        />
      </Typography>
      <Typography variant="h4" align="center">
        <span className="color-accent">{t("SignIn")}</span>{" "}
        <span className="color-gray">{t("or")}</span>{" "}
        <span className={classes.fontBold}>{t("Register")}</span>
      </Typography>
      <Typography variant="h5" align="center" className={classes.textWelcome}>
        {t("WelcomeBack")}
      </Typography>
      <Form
        className={classes.formRoot}
        autoComplete="off"
        onSubmit={handleSubmit}
        hideRequiredMark
      >
        <Grid container>
          <Grid item xs={12}>
            <Form.Item label="Email">
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: t("PleaseInputYourEmail") },
                  { type: "email", message: t("PleaseInputCorrectYourEmail") },
                ],
                getValueFromEvent: (e) => {
                  if (emailChecked) {
                    handleDisableEmailCheck();
                  }
                  return e.target.value;
                },
              })(
                <Input
                  onFocus={() => handleDisableEmailCheck()}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                />
              )}
            </Form.Item>
            {emailChecked && get(checkingEmailResult, "has_email", false) && (
              <Form.Item label={t("Password")}>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder={t("Password")}
                  />
                )}
              </Form.Item>
            )}
          </Grid>
          <Grid item xs={12} className={classes.buttonGroup}>
            {emailChecked && get(checkingEmailResult, "has_email", false) && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isCheckingEmail}
                >
                  {t("LogIn")}
                </Button>
              </Form.Item>
            )}
            {(!emailChecked || isCheckingEmail) && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isCheckingEmail}
                >
                  {t("Continue")}
                </Button>
              </Form.Item>
            )}
            {emailChecked &&
              !isCheckingEmail &&
              !get(checkingEmailResult, "has_email", false) && (
                <Form.Item>
                  <Typography align="center">
                    {t("LOGIN.YourAccountIsNotFoundInOurSystem")}
                  </Typography>
                  <Link to={paths.RegisterPage}>
                    <Button
                      type="primary"
                      block
                      loading={isLogining || isCheckingEmail}
                    >
                      {t("LOGIN.RegisterNow")}
                    </Button>
                  </Link>
                </Form.Item>
              )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.forgotPassword}>
              <Link to={paths.ForgotPasswordPage}>{t("ForgotPassword")}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.registerContent}>
            <Typography variant="body2">
              {t("NotAMemberYet")}{" "}
              <Link to={paths.RegisterPage} className={classes.registertLink}>
                {t("RegisterOne")}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

const mapState = ({ authReducer }) => ({
  isLogining: authReducer.isLogining,
  isCheckingEmail: authReducer.isCheckingEmail,
  checkingEmailResult: authReducer.checkingEmailResult,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      login,
      checkEmailExist,
      resetCheckingEmailResult,
    },
    dispatch
  );

Login.propTypes = {
  login: PropTypes.func,
  checkEmailExist: PropTypes.func,
  t: PropTypes.func,
  form: PropTypes.object,
  isLogining: PropTypes.bool,
};

export default Form.create({
  name: "login",
})(connect(mapState, mapDispatch)(withTranslation("common")(Login)));
