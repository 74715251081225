import { Grid, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Icon, Input } from "antd";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { withTranslation } from 'react-i18next'

import { resetPassword, resetPasswordFail } from "../../../actions/auth";
import Image from "../../../assets/images/ecos_Stockholm_1.jpg";

import { LogoImage as Logo } from "../../../constants/componentHelpers";
import * as paths from "../../../constants/paths.constants";

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      marginTop: "50px",
      marginBottom: "30px",
      color: theme.colorAccent,
      width: "60%",
      cursor: "pointer",
    },
    checkIcon: {
      "& svg": {
        width: "50px",
        height: "50px",
        color: theme.colorAccent,
      },
    },
    background: {
      "& img": {
        height: "100%",
        width: "100%",
      },
    },
    formRoot: {
      width: "400px",
      margin: "0 auto",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 300,
      },
      "& .ant-form-item": {
        textAlign: "left",
      },
    },
    formField: {
      "& .MuiInputBase-root": {
        // backgroundColor: 'white',
        borderRadius: "15px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    textWelcome: {
      padding: "20px 0",
      color: theme.colorAccent,
    },
    verifySuccessText: {
      padding: "0px 0 10px",
      color: theme.colorGray,
    },
    titleSubText: {
      color: theme.colorGray,
      padding: "0 20%",
    },
    buttonGroup: {
      justifyContent: "center",
      "& button": {
        width: 300,
        // padding: '10px 0',
        margin: "25px",
      },
    },
    forgotPassword: {
      cursor: "pointer",
    },
    registerContent: {
      marginTop: "30px",
    },
    registerLink: {
      cursor: "pointer",
    },
  };
});

const Verify = (props) => {
  const classes = useStyles();
  const {
    resetPassword,
    resetSuccessful,
    t,
    isResetingPassword,
    form: { validateFields, getFieldDecorator, getFieldValue },
    history,
    resetPasswordError,
    resetResponseError,
  } = props;
  const searchParams = new URLSearchParams(history.location.search);
  console.log(searchParams.get("token"));
  console.log(history.location.search);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateFields((errors, values) => {
      if (!errors) {
        resetPassword({
          ...values,
          uid: searchParams.get("uid"),
          token: searchParams.get("token"),
        });
      }
    });
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item md={4} sm={4} xs={4}>
        <Typography variant="h2" align="center" className={classes.aa}>
          <img
            src={Logo}
            className={classes.logo}
            onClick={() => history.push(paths.HomePage)}
          />
        </Typography>
        {!resetSuccessful ? (
          <>
            <Typography
              variant="h5"
              align="center"
              className={classes.textWelcome}
            >
              {t("ResetPassword")}
            </Typography>

            <Form
              className={classes.formRoot}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Form.Item label={t("NewPassword")}>
                    {getFieldDecorator("new_password", {
                      rules: [
                        {
                          required: true,
                          message: t("PleaseInputYourPassword"),
                        },
                      ],
                      getValueFromEvent: (e) => {
                        resetResponseError({});
                        return e.target.value;
                      },
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder={t("Password*")}
                        type="password"
                      />
                    )}
                  </Form.Item>
                  {/* <TextField
                    // error
                    className={classes.formField}
                    // id="outlined-error"
                    id="standard-basic"
                    type="password"
                    label={t('NewPassword')}
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  <Form.Item label={t("ReNewPassword")}>
                    {getFieldDecorator("re_new_password", {
                      rules: [
                        {
                          required: true,
                          message: t("PleaseInputYourConfirmPassword"),
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (value !== getFieldValue("new_password")) {
                              callback(
                                t("TwoPasswordsThatYouEnterIsInconsistent")
                              );
                            }
                            return callback();
                          },
                        },
                      ],
                      getValueFromEvent: (e) => {
                        resetResponseError({});
                        return e.target.value;
                      },
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder={t("Password*")}
                        type="password"
                      />
                    )}
                  </Form.Item>
                  {/* <TextField
                    // error
                    className={classes.formField}
                    // id="outlined-error"
                    id="standard-basic"
                    type="password"
                    label={t('ReEnterNewPassword')}
                  /> */}
                </Grid>
                {Object.keys(resetPasswordError).map((key) => {
                  console.log(resetPasswordError[key]);
                  return resetPasswordError[key].map((it) => {
                    return (
                      <em style={{ color: "red", fontSize: "13px" }}>- {it}</em>
                    );
                  });
                })}
                <Grid item xs={12}>
                  <Form.Item>
                    {/* <Toolbar className={classes.buttonGroup}> */}
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isResetingPassword}
                      style={{ width: "100%" }}
                    >
                      {t("ResetPassword")}
                    </Button>
                  </Form.Item>
                  {/* </Toolbar> */}
                </Grid>
              </Grid>
            </Form>
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              align="center"
              className={classes.checkIcon}
            >
              <CheckCircleIcon />
            </Typography>
            <Typography
              variant="h5"
              align="center"
              className={classes.verifySuccessText}
            >
              {t("Password")}
              <br />
              {t("SuccessfullyReset")}
            </Typography>
            <Typography
              variant="body2"
              align="center"
              className={classes.titleSubText}
            >
              {t("NowYouCanSignInWithYourNewPassword")} <br />
              {t("CheckYourEmailForTheUpdate")}
            </Typography>
            <Grid item xs={12}>
              <Toolbar className={classes.buttonGroup}>
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(paths.LoginPage)}
                >
                  {t("GoToSIGNIN")}
                </Button>
              </Toolbar>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item md={8} sm={8} xs={8} className={classes.background}>
        <img src={Image} />
      </Grid>
    </Grid>
  );
};

const mapState = ({ authReducer }) => ({
  isResetingPassword: authReducer.isResetingPassword,
  resetPasswordError: authReducer.resetPasswordError,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      resetPassword,
      resetResponseError: resetPasswordFail,
    },
    dispatch
  );

Verify.propTypes = {
  resetPassword: PropTypes.func,
  resetSuccessful: PropTypes.bool,
};

export default Form.create({
  name: "resetPasswordConfirm",
})(
  withTranslation("common")(connect(mapState, mapDispatch)(withRouter(Verify)))
);
