import React from "react";

import RegisterComponent from "../../../components/Auth/Register";

const Register = () => {
  return (
    <div>
      <RegisterComponent />
    </div>
  );
};

export default Register;
