import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getProfile } from "../../actions/auth";
import { getConfiguration } from "../../actions/public";

import * as paths from "../../constants/paths.constants";

import { PrivateRoute } from "../../Routes";

import ForgotPasswordPage from "../Auth/ForgotPassword";
import LoginPage from "../Auth/Login";
import RegisterPage from "../Auth/Register";
import ResetPasswordPage from "../Auth/ResetPassword";
import VerifyPage from "../Auth/Verify";
import BookingPage from "../Booking";
import Home from "../Home";
import MyBookingPage from "../MyBooking";
import Imprint from "../policies/Imprint";
import Privacy from "../policies/Privacy";
import ProfilePage from "../Profile";

class App extends React.Component {
  UNSAFE_componentWillMount() {
    const { getProfile, getConfiguration } = this.props;

    getProfile();
    getConfiguration();
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <PrivateRoute path={paths.ProfilePage} component={ProfilePage} />
          <PrivateRoute path={paths.MyBookingPage} component={MyBookingPage} />
          <Route path={paths.Privacy} component={Privacy} />
          <Route path={paths.Imprint} component={Imprint} />
          <Route path={paths.BookingPage} component={BookingPage} />
          <Route path={paths.ResetPasswordPage} component={ResetPasswordPage} />
          <Route path={paths.ChangePassword} component={LoginPage} />
          <Route path={paths.RegisterPage} component={RegisterPage} />
          <Route path={paths.ActivatePage} component={VerifyPage} />
          <Route path={paths.Verifypage} component={VerifyPage} />
          <Route
            path={paths.ForgotPasswordPage}
            component={ForgotPasswordPage}
          />
          <Route path={paths.LoginPage} component={LoginPage} />
          <Route path={paths.HomePage} component={Home} />
        </Switch>
      </div>
    );
  }
}

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getProfile,
      getConfiguration,
    },
    dispatch
  );

export default connect(null, mapDispatch)(App);
