import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import { Input, InputNumber, Select } from "antd";
import * as _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import { downloadInvoice } from "../../helpers/helpers";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    margin: "auto",
    paddingTop: 48,
    marginBottom: 96,
  },
  image: {
    width: 360,
    height: 300,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
  },
}));

const Stage1 = ({
  t,

  roomName,
  roomImage,
  permanentFacilities,
  bookingDate: { startDate, startTime, endDate, endTime },
  bookingParticipants,
  maxParticipants,
  roomPrice,
  fetchingSelectableExFacilityCategories,
  selectableExFacilityCategories,
  selectedExFacilityCategory,

  fetchingSelectableExFacilityQuantities,
  selectableExFacilityQuantities,
  selectedExFacilityQuantity,
  selectedExFacilityDays,
  selectedExFacilityPrice,
  selectedExFacilityTotal,
  isFetchingExFacilityItemPricings,
  isAddingExFacilityCategoryItem,
  removingExFacilityCategoryItem,
  exFacilityItems,

  selectableCateringServices,
  selectedCateringService,
  fetchingSelectableCateringServices,
  cateringServiceQuantity,
  fetchingCateringServicePricings,
  selectedCateringServicePrice,
  selectedCateringServiceTotal,
  selectedCateringServiceDays,
  isAddingCateringServiceItem,
  removingCateringServiceItem,
  cateringItems,
  isCancelSubmitting,
  isNextSubmitting,

  totalPrice,
  invoiceUrl,

  additionalRequirements,
  onChangeAdditionalRequirements,
  onChangeSelectedExFacilityCategory,
  onClickAddExFacilityCategoryItem,
  onClickAddCateringServiceItem,
  onChangeSelectedExFacilityQuantity,
  onChangeSelectedCateringService,
  onChangeCateringServiceQuantity,
  onRemoveExFacilityItem,
  onRemoveCateringItem,

  onClickCancel,
  onClickNext,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container spacing={6} style={{ margin: 16 }}>
          <Grid item className={classes.image} style={{ backgroundImage: `url(${roomImage})` }}>
            <div />
          </Grid>

          <Grid item xs={12} sm container direction="column" justify="space-between">
            <Grid item>
              <Typography variant="h6">
                <b>{roomName}</b>
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography variant="subtitle1">
                  <b>{t("Permanent facility")}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">{permanentFacilities}</Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography variant="subtitle1">
                  <b>Getränke-Flatrate</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  Wasser in Karaffen, Kaffee/Tee in der Kaffeelounge
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <b>{t("Booking Date")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {t("From")}: {startDate}, {startTime}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {t("To")}: {endDate}, {endTime}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography variant="subtitle1">
                  <b>{t("Max Participants")}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">{maxParticipants}</Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography variant="subtitle1">
                  <b>{t("Booking Participants")}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">{bookingParticipants}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ margin: 16, paddingTop: 32 }} justify="space-between">
          <Grid item xs={4}>
            <Typography variant="subtitle1">{t("Room Price")}</Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{roomPrice}</Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Divider />

        <Grid container style={{ margin: 16, paddingTop: 32 }} justify="space-between">
          <Grid item xs={4}>
            <Typography variant="subtitle1">{t("Extra Facility")}</Typography>
            <Typography variant="body1">({t("Plesae select and add with plus button")})</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">{t("Quantity/Day")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{t("Day(s)")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">{t("Unit Price")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{t("Total")}</Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Divider />

        <Grid container style={{ margin: 16 }} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <Select
              style={{ width: "90%" }}
              value={selectedExFacilityCategory || undefined}
              placeholder={t("Select an extra facility")}
              loading={fetchingSelectableExFacilityCategories}
              onChange={(e) => onChangeSelectedExFacilityCategory(e)}
              disabled={isAddingExFacilityCategoryItem || isNextSubmitting || isCancelSubmitting}>
              {_.map(selectableExFacilityCategories, (it) => (
                <Option key={it.id} value={it.id}>
                  {t(it.name)}
                </Option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <Select
              style={{ width: "90%" }}
              value={selectedExFacilityQuantity || undefined}
              placeholder={t("Select quantity")}
              loading={fetchingSelectableExFacilityQuantities}
              onChange={onChangeSelectedExFacilityQuantity}
              disabled={
                isFetchingExFacilityItemPricings ||
                fetchingSelectableExFacilityQuantities ||
                isAddingExFacilityCategoryItem ||
                isNextSubmitting ||
                isCancelSubmitting
              }>
              {_.map(selectableExFacilityQuantities, (it) => (
                <Option key={it} value={it}>
                  {it}
                </Option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">
              {isFetchingExFacilityItemPricings ? t("Loading...") : selectedExFacilityDays}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">
              {isFetchingExFacilityItemPricings ? t("Loading...") : selectedExFacilityPrice}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">
              {isFetchingExFacilityItemPricings ? t("Loading...") : selectedExFacilityTotal}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="Add"
              component="span"
              onClick={onClickAddExFacilityCategoryItem}
              disabled={isAddingExFacilityCategoryItem || isNextSubmitting || isCancelSubmitting}>
              {isAddingExFacilityCategoryItem ? <CircularProgress size={16} /> : <AddIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Divider />

        {_.map(exFacilityItems, (it) => [
          <Grid
            container
            style={{ margin: 16 }}
            justify="space-between"
            alignItems="center"
            key={_.get(it, "id")}>
            <Grid item xs={4}>
              <Typography variant="body1">{_.get(it, "name")}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">{_.get(it, "quantity")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1">{_.get(it, "days")}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">{_.get(it, "price")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1">{_.get(it, "total")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                color="secondary"
                aria-label="Delete"
                component="span"
                onClick={() => onRemoveExFacilityItem(_.get(it, "id"))}
                disabled={
                  removingExFacilityCategoryItem === _.get(it, "id") ||
                  isNextSubmitting ||
                  isCancelSubmitting
                }>
                {removingExFacilityCategoryItem === _.get(it, "id") ? (
                  <CircularProgress size={16} />
                ) : (
                  <DeleteIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>,
          <Divider key={`divider-${it.id}`} />,
        ])}

        <Grid container style={{ margin: 16, paddingTop: 32 }} justify="space-between">
          <Grid item xs={4}>
            <Typography variant="subtitle1">{t("Catering Service")}</Typography>
            <Typography variant="body1">({t("Plesae select and add with plus button")})</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">{t("Quantity/Day")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{t("Day(s)")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">{t("Unit Price")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{t("Total")}</Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Divider />

        <Grid container style={{ margin: 16 }} justify="space-between" alignItems="top">
          <Grid item xs={4}>
            <Select
              style={{ width: "90%" }}
              value={selectedCateringService || undefined}
              loading={fetchingSelectableCateringServices}
              placeholder={t("Select Catering Service")}
              onChange={onChangeSelectedCateringService}
              disabled={isAddingCateringServiceItem || isNextSubmitting || isCancelSubmitting}>
              {_.map(selectableCateringServices, ({ name, items }) => (
                <OptGroup label={t(name)} key={name}>
                  {_.map(items, (it) => (
                    <Option key={it.id} value={it.id}>
                      <Tooltip title={it.description} arrow placement="top">
                        <Grid container direction="column">
                          <Grid item>{it.name}</Grid>
                          <Grid item style={{ color: "grey", fontSize: 12 }}>
                            {it.description}
                          </Grid>
                        </Grid>
                      </Tooltip>
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <InputNumber
              min={1}
              placeholder={t("Input Quantity")}
              style={{ width: "90%" }}
              value={cateringServiceQuantity}
              onChange={onChangeCateringServiceQuantity}
              disabled={isAddingCateringServiceItem || isNextSubmitting || isCancelSubmitting}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">
              {fetchingCateringServicePricings ? t("Loading...") : selectedCateringServiceDays}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">
              {fetchingCateringServicePricings ? t("Loading...") : selectedCateringServicePrice}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">
              {fetchingCateringServicePricings ? t("Loading...") : selectedCateringServiceTotal}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="Add"
              component="span"
              style={{ padding: 0 }}
              onClick={onClickAddCateringServiceItem}
              disabled={isAddingCateringServiceItem || isNextSubmitting || isCancelSubmitting}>
              {isAddingCateringServiceItem ? <CircularProgress size={16} /> : <AddIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Divider />

        {_.map(cateringItems, (it) => [
          <Grid
            container
            style={{ margin: 16 }}
            justify="space-between"
            alignItems="center"
            key={_.get(it, "id")}>
            <Grid item xs={4}>
              <Typography variant="body1">{_.get(it, "name")}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">{_.get(it, "quantity")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1">{_.get(it, "days")}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">{_.get(it, "price")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1">{_.get(it, "total")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                color="secondary"
                aria-label="Delete"
                component="span"
                onClick={() => onRemoveCateringItem(_.get(it, "id"))}
                disabled={
                  removingCateringServiceItem === _.get(it, "id") ||
                  isNextSubmitting ||
                  isCancelSubmitting
                }>
                {removingCateringServiceItem === _.get(it, "id") ? (
                  <CircularProgress size={16} />
                ) : (
                  <DeleteIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>,
          <Divider key={`divider-${it.id}`} />,
        ])}

        <Grid container style={{ margin: 16, paddingTop: 32 }} justify="space-between">
          <Grid item xs={4}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography>
              <b>{t("Total")}</b>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="button">
              <b>{totalPrice}</b>
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Divider />

        <Grid container style={{ margin: 16 }} direction="column">
          <Grid item>
            <Typography variant="subtitle1">
              <b>{t("Extras")}</b>
            </Typography>
          </Grid>
          <Grid item style={{ marginRight: 32 }}>
            <TextArea
              rows={4}
              value={additionalRequirements}
              onChange={(e) => onChangeAdditionalRequirements(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ margin: 16, paddingTop: 32, paddingRight: 32 }}
          justify="space-between">
          <Grid item>
            <Button
              variant="contained"
              color="default"
              onClick={onClickCancel}
              disabled={isCancelSubmitting || isNextSubmitting}>
              {isCancelSubmitting && <CircularProgress size={16} />}&nbsp;
              {t("Abort")}
            </Button>
          </Grid>
          <Grid item>
            <span
              disabled={isNextSubmitting || isCancelSubmitting}
              onClick={() => downloadInvoice(invoiceUrl)}>
              <Button startIcon={<PrintIcon />} style={{ marginRight: 16 }}>
                {t("Print")}
              </Button>
            </span>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickNext}
              disabled={isNextSubmitting || isCancelSubmitting}>
              {isNextSubmitting && <CircularProgress size={16} />}&nbsp;
              {t("Next")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default withTranslation("booking")(Stage1);
