import { get } from "lodash";
import { combineReducers } from "redux";

import * as modalActionTypes from "../actionTypes/modal.actionTypes";
import * as actionTypes from "../actionTypes/public.actionTypes";

const locale = (
  state = { lang: localStorage.getItem("language") || navigator.language.slice(0, 2) },
  action,
) => {
  const { type } = action;
  switch (type) {
    case actionTypes.CHANGE_LANGUAGE_SUCCESS:
      state.lang = action.data;
      return state;
    default:
      return state;
  }
};

const isLoadingRoomTypes = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_LIST_ROOM_TYPES:
      return true;
    case actionTypes.GET_LIST_ROOM_TYPES_FAIL:
    case actionTypes.GET_LIST_ROOM_TYPES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const listRoomTypes = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_LIST_ROOM_TYPES_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const configurationInfo = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_CONFIGURATION_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const isSearchingRoom = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SEARCH_ROOM:
      return true;
    case actionTypes.GET_BOOKING_SESSIONS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const searchedRoom = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.SEARCH_ROOM_SUCCESS:
      return data;
    default:
      return state;
  }
};

const isGettingPermanentFacilities = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_LIST_PERMANENT_FACILITIES:
      return true;
    case actionTypes.GET_LIST_PERMANENT_FACILITIES_FAIL:
    case actionTypes.GET_LIST_PERMANENT_FACILITIES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const listPermanentFacilities = (state = [], action) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.GET_LIST_PERMANENT_FACILITIES_SUCCESS:
      return data;
    default:
      return state;
  }
};

const isGettingExtraFacilities = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES:
      return true;
    case actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS:
    case actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_FAIL:
      return false;
    default:
      return state;
  }
};

const listExtraFacilities = (state = [], action) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS:
      return data;
    case actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS:
      console.log(data);
      state.map((item) => {
        if (item.id === data.id) {
          item.listAvailable = data.listAvailable;
        }
      });
      return state;
    // case actionTypes.SEARCH_EXTRA_FACILITIES_PRICE_FAIL:
    //   state.map(item => {
    //     if(item.id === data.id) {
    //       item.notFound = true
    //       item.price = null
    //     }
    //   })
    //   return state
    default:
      return state;
  }
};

const isGettingCaterings = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_LIST_CATERINGS:
      return true;
    case actionTypes.GET_LIST_CATERINGS_FAIL:
    case actionTypes.GET_LIST_CATERINGS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const listCaterings = (state = [], action) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.GET_LIST_CATERINGS_SUCCESS:
      return data;
    // case actionTypes.SEARCH_EXTRA_FACILITIES_PRICE_SUCCESS:
    //   return data
    default:
      return state;
  }
};

const isSearchingExtraPrice = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES:
    case actionTypes.ADD_EXTRA_FACILITY:
    case actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS:
      return true;
    case actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_FAIL:
    case actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS:
    case actionTypes.ADD_EXTRA_FACILITY_FAIL:
    case actionTypes.ADD_EXTRA_FACILITY_SUCCESS:
    case actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_FAIL:
    case actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const extraFacilityPricings = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const isGettingCateringPrice = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS:
      return true;
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_FAIL:
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const cateringPricings = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const searchInfo = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.STORE_SEARCH_DATA:
      return action.data;
    default:
      return state;
  }
};

const isGettingBookingSessions = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_BOOKING_SESSIONS:
      return true;
    case actionTypes.GET_BOOKING_SESSIONS_FAIL:
    case actionTypes.GET_BOOKING_SESSIONS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const bookingSession = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_BOOKING_SESSIONS_SUCCESS:
      return action.data;
    case actionTypes.UPDATE_BOOKING_SESSION_SUCCESS:
      return action.data;
    case actionTypes.REMOVE_BOOKING_SESSION:
      return {};
    default:
      return state;
  }
};

const isUpdatingBookingSession = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.UPDATE_BOOKING_SESSION:
      return true;
    case actionTypes.UPDATE_BOOKING_SESSION_FAIL:
    case actionTypes.UPDATE_BOOKING_SESSION_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isBooking = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.ATTACH_SESSION_CUSTOMER:
      return true;
    case actionTypes.ATTACH_SESSION_CUSTOMER_FAIL:
    case actionTypes.ATTACH_SESSION_CUSTOMER_SUCCESS:
    case modalActionTypes.CLOSE_SEARCHED_MODAL:
      return false;
    default:
      return state;
  }
};

const isGettingListBookings = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_BOOKING:
      return true;
    case actionTypes.GET_BOOKING_SUCCESS:
    case actionTypes.GET_BOOKING_FAIL:
      return false;
    default:
      return state;
  }
};

const listBookings = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_BOOKING_SUCCESS:
      return get(action, "data.results");
    default:
      return state;
  }
};

const isAddingCatering = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.ADD_SESSION_CATERING:
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS:
      return true;
    case actionTypes.ADD_SESSION_CATERING_FAIL:
    case actionTypes.ADD_SESSION_CATERING_SUCCESS:
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_FAIL:
    case actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isRemovingSessionItems = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.REMOVE_SESSION_ITEMS:
      return true;
    case actionTypes.REMOVE_SESSION_ITEMS_FAIL:
    case actionTypes.REMOVE_SESSION_ITEMS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isPrintingInvoice = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.PRINT_INVOICE:
      return true;
    case actionTypes.PRINT_INVOICE_FAIL:
    case actionTypes.PRINT_INVOICE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isUpdatingBookingState = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.UPDATE_BOOKING_STATE:
      return true;
    case actionTypes.UPDATE_BOOKING_STATE_FAIL:
    case actionTypes.UPDATE_BOOKING_STATE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isLinkingSessionPayment = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.LINK_SESSION_PAYMENT:
      return true;
    case actionTypes.LINK_SESSION_PAYMENT_SUCCESS:
    case actionTypes.LINK_SESSION_PAYMENT_FAIL:
    case actionTypes.CONFIRM_BOOKING_SUCCESS:
    case actionTypes.CONFIRM_BOOKING_FAIL:
      return false;
    default:
      return state;
  }
};

const isSendingContactRequest = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SEND_CONTACT_REQUEST:
      return true;
    case actionTypes.SEND_CONTACT_REQUEST_SUCCESS:
    case actionTypes.SEND_CONTACT_REQUEST_FAIL:
      return false;
    default:
      return state;
  }
};

const listHoliday = (state = [], action) => {
  const { type } = action;

  switch (type) {
    case actionTypes.GET_LIST_HOLIDAY_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const publicReducer = combineReducers({
  locale,
  configurationInfo,
  isLoadingRoomTypes,
  listRoomTypes,
  isSearchingRoom,
  searchedRoom,
  isGettingPermanentFacilities,
  listPermanentFacilities,
  isGettingExtraFacilities,
  listExtraFacilities,
  isGettingCaterings,
  listCaterings,
  isSearchingExtraPrice,
  searchInfo,
  isGettingBookingSessions,
  bookingSession,
  isUpdatingBookingSession,
  isBooking,
  isGettingListBookings,
  listBookings,
  isAddingCatering,
  isRemovingSessionItems,
  isPrintingInvoice,
  isUpdatingBookingState,
  isLinkingSessionPayment,
  isSendingContactRequest,
  extraFacilityPricings,
  isGettingCateringPrice,
  cateringPricings,
  listHoliday,
});

export default publicReducer;
