import * as actionTypes from "../actionTypes/modal.actionTypes";

export function openSearchModal() {
  return { type: actionTypes.OPEN_SEARCH_MODAL };
}
export function closeSearchModal() {
  return { type: actionTypes.CLOSE_SEARCH_MODAL };
}

export function openViewRoomTypeModal() {
  return { type: actionTypes.OPEN_VIEW_ROOM_TYPE_MODAL };
}

export function closeViewRoomTypeModal() {
  return { type: actionTypes.CLOSE_VIEW_ROOM_TYPE_MODAL };
}

export function openSearchedModal() {
  return { type: actionTypes.OPEN_SEARCHED_MODAL };
}

export function closeSearchedModal() {
  return { type: actionTypes.CLOSE_SEARCHED_MODAL };
}

export function openLoginModal() {
  return { type: actionTypes.OPEN_LOGIN_MODAL };
}

export function closeLoginModal() {
  return { type: actionTypes.CLOSE_LOGIN_MODAL };
}

export function openConfirmSearchModal() {
  return { type: actionTypes.OPEN_CONFIRM_SEARCH_MODAL };
}

export function closeConfirmSearchModal() {
  return { type: actionTypes.CLOSE_CONFIRM_SEARCH_MODAL };
}

export function openBookingRequestModal() {
  return { type: actionTypes.OPEN_BOOKING_REQUEST_MODAL };
}

export function closeBookingRequestModal() {
  return { type: actionTypes.CLOSE_BOOKING_REQUEST_MODAL };
}
