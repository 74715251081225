import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import Stage3 from "../../components/Booking/Stage3";

import * as selector from "../../selectors/bookingSession/stage3";

const Stage3Container = (props) => {
  return <Stage3 {...props} />;
};

const mapState = (state) => ({
  paymentMethod: selector.getPaymentMethod(state),
  isNextSubmitting: selector.isNextSubmitting(state),
  isBackSubmitting: selector.isBackSubmitting(state),
  isCancelSubmitting: selector.isCancelSubmitting(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      onNextClick: actions.stage3NextClick,
      onBackClick: actions.stage3BackClick,
      onCancelClick: actions.stage3CancelClick,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(Stage3Container);
