export const OPEN_SEARCH_MODAL = "OPEN_SEARCH_MODAL";
export const CLOSE_SEARCH_MODAL = "CLOSE_SEARCH_MODAL";

export const OPEN_VIEW_ROOM_TYPE_MODAL = "OPEN_VIEW_ROOM_TYPE_MODAL";
export const CLOSE_VIEW_ROOM_TYPE_MODAL = "CLOSE_VIEW_ROOM_TYPE_MODAL";

export const OPEN_SEARCHED_MODAL = "OPEN_SEARCHED_MODAL";
export const CLOSE_SEARCHED_MODAL = "CLOSE_SEARCHED_MODAL";

export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

export const OPEN_CONFIRM_SEARCH_MODAL = "OPEN_CONFIRM_SEARCH_MODAL";
export const CLOSE_CONFIRM_SEARCH_MODAL = "CLOSE_CONFIRM_SEARCH_MODAL";

export const OPEN_BOOKING_REQUEST_MODAL = "OPEN_BOOKING_REQUEST_MODAL";
export const CLOSE_BOOKING_REQUEST_MODAL = "CLOSE_BOOKING_REQUEST_MODAL";
