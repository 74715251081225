import React from "react";

import ForgotPasswordComponent from "../../../components/Auth/ForgotPassword";

const ForgotPassword = () => {
  return (
    <div>
      <ForgotPasswordComponent />
    </div>
  );
};

export default ForgotPassword;
