import * as C from "../actionTypes/bookingSession";

export const continueBookingProcess = () => ({ type: C.CONTINUE_BOOKING_PROCESS });
export const showContactForm = () => ({ type: C.SHOW_CONTACT_FORM });
export const hideContactForm = () => ({ type: C.HIDE_CONTACT_FORM });

/** STAGE 0 - Initial Stage */
export const showPickDatePopup = () => ({ type: C.STAGE_0_SHOW_PICK_DATE_POPUP });
export const hidePickDatePopup = () => ({ type: C.STAGE_0_HIDE_PICK_DATE_POPUP });
export const startBookByRoomType = (payload) => ({
  type: C.STAGE_0_START_BOOK_FOR_ROOMTYPE,
  payload,
});
export const changeBookingType = (payload) => ({ type: C.STAGE_0_CHANGE_BOOKING_TYPE, payload });
export const changeParticipantNumber = (payload) => ({
  type: C.STAGE_0_CHANGE_PARTICIPANT_NUMBER,
  payload,
});
export const changeBookingStartDate = (payload) => ({
  type: C.STAGE_0_CHANGE_BOOKING_START_DATE,
  payload,
});
export const changeBookingEndDate = (payload) => ({
  type: C.STAGE_0_CHANGE_BOOKING_END_DATE,
  payload,
});
export const onChangeBookingStartTime = (payload) => ({
  type: C.STAGE_0_CHANGE_BOOKING_START_TIME,
  payload,
});
export const onChangeBookingEndTime = (payload) => ({
  type: C.STAGE_0_CHANGE_BOOKING_END_TIME,
  payload,
});
export const changePresentationDevice = (payload) => ({
  type: C.STAGE_0_TOGGLE_PRESENTATION_DEVICE,
  payload,
});
export const cancelSearchRoom = () => ({ type: C.STAGE_0_CLICK_CANCEL });
export const submitSearchRoom = () => ({ type: C.STAGE_0_CLICK_SEARCH_ROOM });
export const hideHolidayWarning = () => ({ type: C.STAGE_0_HIDE_HOLIDAY_WARNING_POPUP });

/** STAGE 1 - Booking Configuration */
export const stage1FetchExtraFacilityCategories = () => ({
  type: C.STAGE_1_FETCH_EX_FACILITY_CATEGORIES,
});
export const stage1FetchCateringServices = () => ({ type: C.STAGE_1_FETCH_CATERING_SERVICES });
export const stage1ChangeSelectedExFacilityCategory = (payload) => ({
  type: C.STAGE_1_CHANGE_EX_FACILITY,
  payload,
});
export const stage1AddExFacilityCategoryItem = () => ({ type: C.STAGE_1_ADD_EX_FACILITY_ITEM });
export const stage1RemoveExFacilityCategoryItem = (payload) => ({
  type: C.STAGE_1_REMOVE_EX_FACILITY_ITEM,
  payload,
});
export const stage1ChangeSelectedExFacilityQuantity = (payload) => ({
  type: C.STAGE_1_CHANGE_EX_FACILITY_QUANTITY,
  payload,
});
export const stage1ChangeSelectedCateringService = (payload) => ({
  type: C.STAGE_1_CHANGE_CATERING_SERVICE,
  payload,
});
export const stage1ChangeCateringServiceQuantity = (payload) => ({
  type: C.STAGE_1_CHANGE_CATERING_SERVICE_QUANTITY,
  payload,
});
export const stage1AddCateringServiceItem = () => ({ type: C.STAGE_1_ADD_CATERING_SERVICE_ITEM });
export const stage1RemoveCateringServiceItem = (payload) => ({
  type: C.STAGE_1_REMOVE_CATERING_SERVICE_ITEM,
  payload,
});
export const stage1ChangeAdditionalRequirements = (payload) => ({
  type: C.STAGE_1_CHANGE_ADDITIONAL_REQUIREMENTS,
  payload,
});
export const stage1CancelClick = () => ({ type: C.STAGE_1_CLICK_CANCEL });
export const stage1NextClick = () => ({ type: C.STAGE_1_CLICK_NEXT });

/** STAGE 2 - Booking Summary */
export const stage2BackClick = () => ({ type: C.STAGE_2_CLICK_BACK });
export const stage2NextClick = () => ({ type: C.STAGE_2_CLICK_NEXT });
export const stage2CancelClick = () => ({ type: C.STAGE_2_CLICK_CANCEL });

/** STAGE 3 - Payment */
export const stage3NextClick = (payload) => ({ type: C.STAGE_3_CLICK_NEXT, payload });
export const stage3BackClick = (payload) => ({ type: C.STAGE_3_CLICK_BACK, payload });
export const stage3CancelClick = (payload) => ({ type: C.STAGE_3_CLICK_CANCEL, payload });

/** STAGE 4 - Confirmation */
export const stage4NextClick = (payload) => ({ type: C.STAGE_4_CLICK_NEXT, payload });
export const stage4BackClick = (payload) => ({ type: C.STAGE_4_CLICK_BACK, payload });
export const stage4CancelClick = (payload) => ({ type: C.STAGE_4_CLICK_CANCEL, payload });
