import i18n from "i18next";
// import { reactI18nextModule } from 'react-i18next';
// import Cache from 'i18next-localstorage-cache';
// import postProcessor from 'i18next-sprintf-postprocessor';
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";

import { resources } from "../translations";
// const resources = {
//   en: {translation: require('../assets/locales/en')},
//   vn: {translation: require('../assets/locales/vn')},
//   cn: {translation: require('../assets/locales/cn')},
// };

i18n
  .use(backend)
  // .use(reactI18nextModule)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem("language") || navigator.language.slice(0, 2),
    fallbackLng: localStorage.getItem("language") || navigator.language.slice(0, 2),
    interpolation: {
      escapeValue: true,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
