import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Icon, Input } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { changePassword, changePasswordFail } from "../../actions/auth";

const useStyles = makeStyles((theme) => ({
  toolbarSecondary: {
    justifyContent: "space-between",
    backgroundColor: "#840810",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  formContent: {
    justifyContent: "center",
    marginBottom: "20px",
  },
  formRoot: {
    "& .ant-form-item-control-wrapper": {
      width: "90%",
    },
  },
  formField: {
    borderRadius: "15px",
    margin: "8px 0",
    minWidth: "100px",
    width: "90%",
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      borderRadius: "15px",
      marginRight: "20px",
    },
    "& .MuiSelect-root": {
      backgroundColor: "white",
      borderRadius: "15px",
    },
    "& .MuiInputBase-input": {
      padding: "10px",
    },
    "& .MuiInputLabel-formControl": {
      top: "-7px",
    },
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  pageTitle: {
    color: "white",
    padding: "15px 0",
  },
  searchTitle: {
    margin: "auto 0",
  },
  searchContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchField: {},
  searchButton: {
    margin: "8px",
  },
  subTitle: {
    borderBottom: "1px solid",
    margin: "20px 0",
  },
  container: {
    maxWidth: "75%",
  },
  buttonGroup: {
    textAlign: "center",
    marginBottom: "50px",
  },
  confirmChangeButton: {
    marginRight: "30px",
  },
}));

const ChangePassword = (props) => {
  const classes = useStyles();
  const {
    form: { validateFields, getFieldDecorator, getFieldValue },
    t,
    changePassword,
    isChangingPassword,
    changePasswordError,
    resetErrorValue,
  } = props;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateFields((errors, values) => {
      if (!errors) {
        changePassword(values);
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" align="left" className={classes.subTitle}>
          {t("119")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Form className={classes.formRoot} onSubmit={handleSubmit}>
          <Grid container>
            <Grid item sm={6} md={6} xs={12}>
              {t("111")}
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Form.Item label={t("112")}>
                    {getFieldDecorator("current_password", {
                      rules: [{ required: true, message: t("PleaseInputYourPassword") }],
                      getValueFromEvent: (e) => {
                        resetErrorValue({});
                        return e.target.value;
                      },
                    })(
                      <Input
                        prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                        placeholder={t("113")}
                        type="password"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label={t("114")}>
                    {getFieldDecorator("new_password", {
                      rules: [
                        { required: true, message: t("PleaseInputYourPassword") },
                        {
                          validator: (rule, value, callback) => {
                            if (value === getFieldValue("current_password")) {
                              callback(t("TheNewPasswordMustBeDifferentTheCurrentPassword"));
                            }
                            return callback();
                          },
                        },
                      ],
                      getValueFromEvent: (e) => {
                        resetErrorValue({});
                        return e.target.value;
                      },
                    })(
                      <Input
                        prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                        placeholder={t("115")}
                        type="password"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label={t("117")}>
                    {getFieldDecorator("re_new_password", {
                      rules: [
                        { required: true, message: t("116") },
                        {
                          validator: (rule, value, callback) => {
                            if (value !== getFieldValue("new_password")) {
                              callback(t("TwoPasswordsThatYouEnterIsInconsistent"));
                            }
                            return callback();
                          },
                        },
                      ],
                      getValueFromEvent: (e) => {
                        resetErrorValue({});
                        return e.target.value;
                      },
                    })(
                      <Input
                        prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                        placeholder={t("118")}
                        type="password"
                      />,
                    )}
                  </Form.Item>
                </Grid>
                {Object.keys(changePasswordError).map((key) => {
                  console.log(changePasswordError[key]);
                  return changePasswordError[key].map((it) => {
                    return <em style={{ color: "red", fontSize: "13px" }}>- {it}</em>;
                  });
                })}
                <Grid item xs={12} className={classes.buttonGroup}>
                  {/* <MaterialButton className={classes.cancelButton}>
                    Cancel
                  </MaterialButton> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={classes.confirmChangeButton}
                    loading={isChangingPassword}>
                    {t("119")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grid>
  );
};

const mapState = ({ authReducer }) => ({
  isChangingPassword: authReducer.isChangingPassword,
  changePasswordError: authReducer.changePasswordError,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      changePassword,
      resetErrorValue: changePasswordFail,
    },
    dispatch,
  );

ChangePassword.propTypes = {};

export default Form.create({
  name: "changePassword",
})(connect(mapState, mapDispatch)(withTranslation("common")(ChangePassword)));
