import { bind } from "decko";

import {
  GetRoomTypes,
  SearchAvailableRoom,
  GetListPermanentFacilities,
  GetListExtraFacilityCategories,
  GetListCaterings,
  SearchExtraFacilities,
  AddExtraFacilities,
  GetBookingSession,
  GetBookings,
  RemoveSessionItems,
  SearchAvailableCaterings,
  AddCatering,
  AttachSessionCustomer,
  PrintInvoice,
  GetConfiguration,
  UpdateBookingState,
  LinkSessionPayment,
  ConfirmBooking,
  DestroyBookingSession,
  SendContactRequest,
  RetrieveItemPricings,
  GetListHolidays,
} from "../../constants/endpoint.constants";

import BaseApi from "./baseApi";

export default class Public extends BaseApi {
  @bind
  async getRoomTypes(data) {
    return await this.http.get(GetRoomTypes(), data);
  }

  @bind
  async getConfiguration(data) {
    return await this.http.get(GetConfiguration(), data);
  }

  @bind
  async searchAvailableRoom(data) {
    return await this.http.post(SearchAvailableRoom(), data);
  }

  @bind
  async getListPermanentFacilities(data) {
    return await this.http.get(GetListPermanentFacilities(), data);
  }

  @bind
  async getListExtraFacilityCategories(data) {
    return await this.http.get(GetListExtraFacilityCategories(), data);
  }

  @bind
  async getListCaterings(data) {
    return await this.http.post(GetListCaterings(), data);
  }

  @bind
  async searchExtraFacilities(data) {
    return await this.http.post(SearchExtraFacilities(), data);
  }

  @bind
  async addExtraFacilities(data) {
    // console.log(data)
    return await this.http.post(AddExtraFacilities(), data);
  }

  @bind
  async searchAvailableCaterings(data) {
    // const {bookingSessionId, ...rest} = data
    return await this.http.post(SearchAvailableCaterings(), data);
  }

  @bind
  async addCatering(data) {
    return await this.http.post(AddCatering(), data);
  }

  @bind
  async removeSessionItems(data) {
    return await this.http.post(RemoveSessionItems(), data);
  }

  @bind
  async getBookingSession(data) {
    return await this.http.post(GetBookingSession(), data);
  }

  @bind
  async getBookings(data) {
    return await this.http.get(GetBookings(), data);
  }

  @bind
  async attachSessionCustomer(data) {
    return await this.http.post(AttachSessionCustomer(), data);
  }

  @bind
  async printInvoice(data) {
    return await this.http.get(PrintInvoice(), data);
  }

  @bind
  async updateBookingState(data) {
    return await this.http.post(UpdateBookingState(), data);
  }

  @bind
  async linkSessionPayment(data) {
    return await this.http.post(LinkSessionPayment(), data);
  }

  @bind
  async confirmBooking(data) {
    return await this.http.post(ConfirmBooking(), data);
  }

  @bind
  async destroyBookingSession(data) {
    return await this.http.post(DestroyBookingSession(), data);
  }

  @bind
  async sendContactRequest(data) {
    return await this.http.post(SendContactRequest(), data);
  }

  @bind
  async retrieveItemPricings(data) {
    return await this.http.post(RetrieveItemPricings(), data);
  }

  @bind
  async getListHolidays(data) {
    return await this.http.get(GetListHolidays(), data);
  }
}
