import { bind } from "decko";

import BaseApi from "./baseApi";

export default class Public extends BaseApi {
  @bind
  async listLeadTime(data) {
    return await this.http.get("/list_lead_time", data);
  }

  @bind
  async next(data, lang = "en") {
    return await this.http.post(`/booking-session/external/next?lang=${lang}`, data);
  }

  @bind
  async back(data) {
    return await this.http.post("/booking-session/external/back", data);
  }

  @bind
  async cancel(data) {
    return await this.http.post("/booking-session/external/cancel", data);
  }

  @bind
  async getBookingToken() {
    return await this.http.post("/booking/get_booking_token");
  }

  @bind
  async downloadBookingInvoiceHTML(data) {
    return await this.http.get("/booking/download_invoice_html", data);
  }
}
