import _ from "lodash";

import Images5 from "../assets/images/ecos_kopenhagen_1_1.jpg";
import Images4 from "../assets/images/ecos_kopenhagen_2_1.jpg";
import Images2 from "../assets/images/ecos_Raum_18.jpg";
import Images3 from "../assets/images/ecos_Raum_23.jpg";
import Images1 from "../assets/images/ecos_Raum_28_3P.jpg";
import Images0 from "../assets/images/ecos_Stockholm_2.jpg";

import listAllCountries from "../data/countries.json";

export const getImage = (value = 0) => {
  const id = value % 6;
  const listImages = {
    Images1,
    Images2,
    Images3,
    Images4,
    Images5,
    Images0,
  };
  return listImages[`Images${id}`];
};

export const roundToUnit = (data, unit) => {
  const unitString = unit ? unit.toString().replace("1", "") : "00";
  if (!data) return `0.${unitString}`;
  data = data / (unit || 1);
  let value = _.round(data, unitString.length).toString();
  if (value.split(".").length > 1) {
    return value;
  } else {
    value = value + `.${unitString}`;
  }
  const total1 = value.split(".")[0];
  const total2 = value.split(".")[1].substring(0, unitString.length);
  const result = total1 + "." + total2;
  return result;
};

export const timeslotsToTimeline = (value) => {
  const binary = (value >>> 0).toString(2);
  const firstTime = binary.indexOf("1") + 8;
  const lastTime = binary.lastIndexOf("1") + 9;
  return [firstTime, lastTime];
};

// const listAllCountries = JSON.parse(localStorage.getItem('listCountries')) || []
/**
 * get list all countries and format to [{value: "VN", label: "Vietnam"}]
 * @param {*} param0
 */
export const getListAllCountries = (language = "en") => {
  let results = [];
  if (listAllCountries.data && listAllCountries.data.length > 0) {
    results = listAllCountries.data.map((item) =>
      item[language]
        ? { value: item[language], label: item[language] }
        : { value: item[language], label: item["en"] },
    );
    results.sort((a, b) => {
      var nameA = (a.label || "").toUpperCase();
      var nameB = (b.label || "").toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  return results || [];
};

export const downloadInvoice = async (invoiceUrl) => {
  await fetch(invoiceUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const file = new Blob([blob], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    });
};
