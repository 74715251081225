import HttpClient from "../../config/httpClient";
import PublicApi from "./public";
import AuthApi from "./auth";
import BookingSessionApi from "./bookingSession";
import BookingApi from "./booking";

export default class Api {
  constructor() {
    this.http = new HttpClient("");
    this.public = new PublicApi(this.http);
    this.auth = new AuthApi(this.http);
    this.bookingSessionApi = new BookingSessionApi(this.http);
    this.bookingApi = new BookingApi(this.http);
  }
}
