import * as _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import Image from "../../assets/images/ecos_stuttgart_3.jpg";

import { ITEM_TYPE } from "../../constants/bookingSession";
import { PrintInvoice } from "../../constants/endpoint.constants";

import { formatMoney } from "../../helpers/bookings";
import { LOCAL_STORAGE_KEY } from "../../helpers/constant";

import * as storage from "../../services/storage";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const INVOICE_URL = `${BASE_URL}${PrintInvoice()}?session=${storage.getLocalStorageItem(
  LOCAL_STORAGE_KEY.session,
)}`;

const getLocale = ({ publicReducer: { locale } }) => _.get(locale, "lang", "en");

const getReducer = ({ bookingSession }) => bookingSession.stage1;
const getSessionData = ({ bookingSession: { sessionData } }) => {
  return sessionData;
};

export const getRoomImage = createSelector([getSessionData], ({ items }) => {
  const room = _.find(items, { item_type: ITEM_TYPE.ROOM });
  return `${BASE_URL}/${_.get(room, "item.images[0].image.full_size")}` || Image;
});

export const getRoomName = createSelector([getSessionData], ({ items }) => {
  const room = _.find(items, { item_type: ITEM_TYPE.ROOM });
  return _.get(room, "item.room_type_name", "");
});

export const getPermanentFacilities = createSelector([getSessionData], ({ items }) => {
  const room = _.find(items, { item_type: ITEM_TYPE.ROOM });
  return _.map(_.get(room, "item.permanent_facilities", []), (it) => it.name).join(", ");
});

export const getBookingDate = createSelector([getSessionData, getLocale], ({ items }, locale) => {
  moment.locale(locale);

  const roomItems = _.sortBy(_.filter(items, { item_type: ITEM_TYPE.ROOM }), ["date"]);
  const startAt = _.pick(_.first(roomItems), ["date", "timeslots"]);
  const startTime =
    _.indexOf(_.padStart(_.get(startAt, "timeslots", 0).toString(2), 14, "0"), "1") + 8;

  const endAt = _.pick(_.last(roomItems), ["date", "timeslots"]);
  const endTime =
    _.lastIndexOf(_.padStart(_.get(endAt, "timeslots", 0).toString(2), 14, "0"), "1") + 9;

  const result = {
    startDate: moment(startAt.date).format("DD.MM.YYYY"),
    startTime: `${_.padStart(startTime, 2, "0")}:00`,

    endDate: moment(endAt.date).format("DD.MM.YYYY"),
    endTime: `${_.padStart(endTime, 2, "0")}:00`,
  };
  return result;
});

export const getBookingParticipants = createSelector(
  [getSessionData],
  ({ participants }) => participants,
);

export const getMaxParticipants = createSelector([getSessionData], ({ items }) => {
  const roomItem = _.first(_.filter(items, { item_type: ITEM_TYPE.ROOM }));
  return _.get(roomItem, "item.max_attendances");
});

export const getRoomPrice = createSelector([getSessionData], ({ items }) => {
  const roomItems = _.sortBy(_.filter(items, { item_type: ITEM_TYPE.ROOM }), ["date"]);
  return _.sum(_.map(roomItems, (it) => it.price));
});

export const getRoomPriceBeautify = createSelector([getRoomPrice], (price) => {
  return formatMoney(price);
});

export const isFetchingSelectableExFacilityCategories = createSelector(
  [getReducer],
  ({ isFetchingSelectableExFacilityCategories }) => isFetchingSelectableExFacilityCategories,
);

export const getSelectableExFacilityCategories = createSelector(
  [getReducer, getSessionData],
  ({ selectableExFacilityCategories }, { items }) => {
    const exFacilityItems = _.filter(items, (it) => it.item_type === ITEM_TYPE.EX_FACILITY);
    const listIds = _.uniq(_.map(exFacilityItems, (it) => _.get(it, "item.category.id")));
    const selectableCategories = _.filter(
      selectableExFacilityCategories,
      (it) => !_.includes(listIds, it.id),
    );
    return _.map(selectableCategories, (it) => _.pick(it, ["id", "name"]));
  },
);

export const getSelectedExFacilityCategory = createSelector(
  [getReducer],
  ({ selectedExFacilityCategory }) => {
    return selectedExFacilityCategory;
  },
);

export const isFetchingSelectableExFacilityAvailabilities = createSelector(
  [getReducer],
  ({ isFetchingSelectableExFacilityAvailabilities }) =>
    isFetchingSelectableExFacilityAvailabilities,
);

export const getSelectableExFacilityQuantities = createSelector(
  [getReducer],
  ({ bookableExFacilityAvailabilities }) => {
    return _.range(1, (bookableExFacilityAvailabilities || []).length + 1);
  },
);

export const isFetchingExFacilityItemPricings = createSelector(
  [getReducer],
  ({ isFetchingExFacilityItemPricings }) => isFetchingExFacilityItemPricings,
);

export const getSelectedExFacilityPricing = createSelector(
  [getReducer],
  ({ exFacilityItemPricings: { results } }) => {
    return _.mean(_.map(results, (it) => _.get(it, "price")));
  },
);

export const getSelectedExFacilityPricingBeautify = createSelector(
  [getSelectedExFacilityPricing],
  (pricing) => {
    return formatMoney(pricing);
  },
);

export const getSelectedExFacilityPricingTotal = createSelector(
  [getReducer],
  ({ exFacilityItemPricings: { results } }) => {
    return _.sum(_.map(results, (it) => _.get(it, "price")));
  },
);

export const getSelectedExFacilityPricingTotalBeautify = createSelector(
  [getSelectedExFacilityPricingTotal],
  (pricing) => {
    return formatMoney(pricing);
  },
);

export const getSelectedExFacilityQuantity = createSelector(
  [getReducer],
  ({ selectedExFacilityCategoryQuantity }) => selectedExFacilityCategoryQuantity,
);

export const getSelectedExFacilityDays = createSelector(
  [getReducer],
  ({ exFacilityItemPricings: { results } }) => {
    let days = Object.keys(_.groupBy(results, (it) => it.date)).length;
    return days;
  },
);

export const isAddingExFacilityCategoryItems = createSelector(
  [getReducer],
  ({ isAddingExFacilityCategoryItems }) => isAddingExFacilityCategoryItems,
);

export const removingExFacilityCategoryItems = createSelector(
  [getReducer],
  ({ removingExFacilityCategoryItems }) => removingExFacilityCategoryItems,
);

export const getExFacilityItems = createSelector([getSessionData], ({ items }) => {
  const exFacilityItems = _.filter(items, { item_type: ITEM_TYPE.EX_FACILITY });
  const categoryGroups = _.groupBy(exFacilityItems, (it) => it.item.category.id);
  const results = _.map(categoryGroups, (v, k) => {
    const category = _.get(_.first(v), "item.category");
    const dateGroups = _.groupBy(v, (it) => it.date);
    return {
      id: _.get(category, "id"),
      name: _.get(category, "name"),
      days: Object.keys(dateGroups).length,
      quantity: _.first(_.map(dateGroups, (gItems) => gItems.length)),
      price: _.mean(_.map(v, (it) => it.price)),
      total: _.sum(_.map(v, (it) => it.price)),
    };
  });
  return results;
});

export const getExFacilityItemsBeautify = createSelector([getExFacilityItems], (items) => {
  return _.map(items, (it) => ({
    ...it,
    price: formatMoney(it.price),
    total: formatMoney(it.total),
  }));
});

export const isFetchingSelectableCateringServices = createSelector(
  [getReducer],
  ({ isFetchingSelectableCateringServices }) => isFetchingSelectableCateringServices,
);

export const getSelectableCateringServices = createSelector(
  [getReducer, getSessionData],
  ({ selectableCateringServices }, { items, participants }) => {
    const cateringItems = _.filter(items, (it) => it.item_type === ITEM_TYPE.CATERING);
    const listIds = _.map(cateringItems, (it) => it.item_id);
    let selectableList = _.filter(selectableCateringServices, (it) => !_.includes(listIds, it.id));
    selectableList = _.filter(selectableList, (it) => it.min_participants <= participants);
    const results = _.groupBy(selectableList, (it) => (it.is_combo ? "Package" : "Single"));
    return [
      { name: "Package", items: _.get(results, "Package") },
      { name: "Single", items: _.get(results, "Single") },
    ];
  },
);

export const getCateringServiceQuantity = createSelector(
  [getReducer],
  ({ cateringServiceQuantity }) => cateringServiceQuantity,
);

export const getSelectedCateringService = createSelector(
  [getReducer],
  ({ selectedCateringService }) => selectedCateringService,
);

export const isFetchingCateringServicePricings = createSelector(
  [getReducer],
  ({ isFetchingCateringServicePricings }) => isFetchingCateringServicePricings,
);

export const getSelectedCateringServicePricing = createSelector(
  [getReducer],
  ({ cateringServiceItemPricings: { results } }) => {
    return _.mean(_.map(results, (it) => _.get(it, "price")));
  },
);

export const getSelectedCateringServicePricingBeautify = createSelector(
  [getSelectedCateringServicePricing],
  (pricing) => {
    return formatMoney(pricing);
  },
);

export const getSelectedCateringServicePricingTotal = createSelector(
  [getReducer],
  ({ cateringServiceItemPricings: { results } }) => {
    return _.sum(_.map(results, (it) => _.get(it, "price") * _.get(it, "quantity")));
  },
);

export const getSelectedCateringServicePricingTotalBeautify = createSelector(
  [getSelectedCateringServicePricingTotal],
  (pricing) => {
    return formatMoney(pricing);
  },
);

export const isAddingCateringServiceItem = createSelector(
  [getReducer],
  ({ isAddingCateringServiceItem }) => isAddingCateringServiceItem,
);

export const removingCateringServiceItem = createSelector(
  [getReducer],
  ({ removingCateringServiceItem }) => removingCateringServiceItem,
);

export const getSelectedCateringServiceDays = createSelector(
  [getReducer],
  ({ cateringServiceItemPricings: { results } }) => {
    let days = Object.keys(_.groupBy(results, (it) => it.date)).length;
    return days;
  },
);

export const getCateringServiceItems = createSelector([getSessionData], ({ items }) => {
  const cateringItems = _.filter(items, { item_type: ITEM_TYPE.CATERING });
  const cateringGroups = _.groupBy(cateringItems, (it) => it.item_id);
  return _.map(cateringGroups, (caterings, itemId) => {
    const firstItem = _.first(caterings);
    return {
      id: itemId,
      name: _.get(firstItem, "item.name"),
      quantity: _.get(firstItem, "quantity"),
      price: _.get(firstItem, "price"),
      days: caterings.length,
      total: _.sum(_.map(caterings, (it) => it.price * it.quantity)),
    };
  });
});

export const getCateringServiceItemsBeautify = createSelector(
  [getCateringServiceItems],
  (items) => {
    return _.map(items, (it) => ({
      ...it,
      price: formatMoney(it.price),
      total: formatMoney(it.total),
    }));
  },
);

export const getTotalPrice = createSelector([getSessionData], ({ items }) => {
  return _.sum(_.map(items, (it) => it.price * it.quantity));
});

export const getTotalPriceBeautify = createSelector([getTotalPrice], (pricing) =>
  formatMoney(pricing),
);

export const getInvoiceUrl = createSelector(
  [getLocale],
  (locale) => `${INVOICE_URL}&lang=${locale}`,
);

export const isCancelSubmitting = createSelector(
  [getReducer],
  ({ isCancelSubmitting }) => isCancelSubmitting,
);

export const getAdditionalRequirements = createSelector(
  [getReducer],
  ({ additionalRequirements }) => additionalRequirements,
);

export const isNextSubmitting = createSelector(
  [getReducer],
  ({ isNextSubmitting }) => isNextSubmitting,
);

export const generateNextReqPayload = createSelector([getReducer], ({ additionalRequirements }) => {
  return { additional_requirement: additionalRequirements };
});

export const generateExFacilityAvailabilitiesReqPayload = createSelector(
  [getReducer, getSessionData],
  ({ selectedExFacilityCategory }, { items }) => {
    const result = {
      category: selectedExFacilityCategory,
      timeslots: _.map(_.filter(items, { item_type: 1 }), (it) => ({
        date: _.get(it, "date"),
        time: _.get(it, "timeslots"),
      })),
    };
    return result;
  },
);

export const generateExFacilityPricingReqPayload = createSelector(
  [getReducer, getSessionData],
  ({ bookableExFacilityAvailabilities, selectedExFacilityCategoryQuantity }, { items }) => {
    let results = [];
    _.forEach(_.filter(items, { item_type: 1 }), (it) => {
      const date = _.get(it, "date");
      const time = _.get(it, "timeslots");
      for (let i = 0; i < selectedExFacilityCategoryQuantity; i++) {
        const item_id = bookableExFacilityAvailabilities[i];
        results.push({ date, time, quantity: 1, item_type: ITEM_TYPE.EX_FACILITY, item_id });
      }
    });

    return { items: results };
  },
);

export const generateAddExFacilityItemReqPayload = createSelector(
  [generateExFacilityPricingReqPayload],
  ({ items }) => items,
);

export const generateCateringServicePricingReqPayload = createSelector(
  [getReducer, getSessionData],
  ({ selectedCateringService, cateringServiceQuantity }, { items }) => {
    let results = [];
    _.forEach(_.filter(items, { item_type: ITEM_TYPE.ROOM }), (it) => {
      const date = _.get(it, "date");
      const time = _.get(it, "timeslots");
      results.push({
        date,
        time,
        quantity: cateringServiceQuantity,
        item_type: ITEM_TYPE.CATERING,
        item_id: selectedCateringService,
      });
    });

    return { items: results };
  },
);

export const generateAddCateringItemReqPayload = createSelector(
  [generateCateringServicePricingReqPayload],
  ({ items }) => items,
);

export const generateRemoveExFacilityItemsReqPayload = (itemId) =>
  createSelector([getSessionData], ({ items }) => {
    const exFacilityItems = _.filter(
      items,
      (it) => it.item_type === ITEM_TYPE.EX_FACILITY && _.get(it, "item.category.id") === itemId,
    );
    return {
      ids: _.map(exFacilityItems, (it) => it.id),
    };
  });

export const generateRemoveCateringItemsReqPayload = (itemId) =>
  createSelector([getSessionData], ({ items }) => {
    const cateringItems = _.filter(
      items,
      (it) => it.item_type === ITEM_TYPE.CATERING && it.item_id === +itemId,
    );
    console.log(itemId, cateringItems);
    return {
      ids: _.map(cateringItems, (it) => it.id),
    };
  });
