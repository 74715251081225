import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Input, Modal } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { sendContactRequest } from "../../actions/public";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    padding: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  searchButton: {
    padding: "0 20px",
    height: 30,
  },
  radioContent: {
    textAlign: "center",
    justifyContent: "center",
    "& .ant-form-item": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  title: {
    marginBottom: "30px",
  },
}));

const BookingRequestModal = (props) => {
  const classes = useStyles();

  const {
    t,
    isOpen,
    closeModal,
    form: { getFieldDecorator, validateFields, resetFields },
    sendContactRequest,
    isSendingContactRequest,
  } = props;

  const handleClose = () => {
    closeModal();
    resetFields();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateFields((errors, value) => {
      if (!errors) {
        sendContactRequest(value);
        resetFields();
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <Modal
      // title="Basic Modal"
      visible={isOpen}
      // onOk={this.handleOk}
      onCancel={handleClose}
      footer={false}
      width={700}>
      <Container>
        <Form layout="horizontal" {...formItemLayout} onSubmit={handleSubmit}>
          <Typography variant="h5" align="center" className={classes.title}>
            {t("27")}
          </Typography>
          <Grid container className={classes.radioContent}>
            <Grid item xs={12}>
              <Form.Item label={t("LastName")}>
                <Input.Group size="large">
                  {getFieldDecorator("last_name", {
                    rules: [{ required: true, message: "Please input your Last Name" }],
                  })(<Input placeholder={t("LastName")} />)}
                </Input.Group>
              </Form.Item>
            </Grid>
            <Grid item xs={12}>
              <Form.Item label={t("FirstName")}>
                <Input.Group size="large">
                  {getFieldDecorator("first_name", {
                    rules: [{ required: true, message: t("PleaseInputYourFirstName") }],
                  })(<Input placeholder={t("FirstName")} />)}
                </Input.Group>
              </Form.Item>
            </Grid>
            <Grid item xs={12}>
              <Form.Item label={t("Phone")}>
                <Input.Group size="large">
                  {getFieldDecorator("phone", {
                    rules: [{ required: true, message: t("PleaseInputYourPhone") }],
                  })(<Input placeholder={t("Phone")} />)}
                </Input.Group>
              </Form.Item>
            </Grid>
            <Grid item xs={12}>
              <Form.Item label="Email">
                <Input.Group size="large">
                  {getFieldDecorator("email", {
                    rules: [
                      { required: true, message: t("PleaseInputYourEmail") },
                      { type: "email", message: t("PleaseInputCorrectYourEmail") },
                    ],
                  })(<Input placeholder="Email" />)}
                </Input.Group>
              </Form.Item>
            </Grid>

            <Grid item xs={12}>
              <Form.Item label={t("Company")}>
                <Input.Group size="large">
                  {getFieldDecorator("company", {
                    rules: [
                      // { required: true, message: 'Please input your Last Name' },
                    ],
                  })(<Input placeholder={t("Company")} />)}
                </Input.Group>
              </Form.Item>
            </Grid>
            <Grid item xs={12}>
              <Form.Item label={t("28")}>
                <Input.Group size="large">
                  {getFieldDecorator("message", {
                    rules: [{ required: true, message: t("29") }],
                  })(
                    <Input.TextArea placeholder={t("28")} autoSize={{ minRows: 3, maxRows: 6 }} />,
                  )}
                </Input.Group>
              </Form.Item>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.buttonGroup}>
            <Button
              variant="contained"
              className={classes.searchButton}
              onClick={() => closeModal()}>
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              type="primary"
              className={classes.searchButton}
              htmlType="submit"
              loading={isSendingContactRequest}>
              {t("Submit")}
            </Button>
          </Grid>
        </Form>
      </Container>
    </Modal>
  );
};

const mapState = ({ publicReducer }) => ({
  isSendingContactRequest: publicReducer.isSendingContactRequest,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      sendContactRequest,
    },
    dispatch,
  );

BookingRequestModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  form: PropTypes.object,
  isSendingContactRequest: PropTypes.bool,
  sendContactRequest: PropTypes.bool,
};

export default Form.create({
  name: "bookingRequest",
})(connect(mapState, mapDispatch)(withTranslation("common")(BookingRequestModal)));
