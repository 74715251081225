import { Grid, Popover } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { loginNotRedirect, logout } from "../../actions/auth";
import { openLoginModal, openSearchedModal } from "../../actions/modal";
import { changeLanguage } from "../../actions/public";
import Avatar from "../../assets/images/blank-ava.png";

import { history } from "../../config/store";
import ASSETS from "../../constants/assets";
import * as paths from "../../constants/paths.constants";

import TopbarMenuLinks from "./TopbarMenuLink";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    margin: "10px 0 0",

    alignItems: "flex-start",
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    height: "15px",
    minHeight: "15px",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  colorRed: {
    color: "red",
  },
  signInContent: {
    position: "relative",
    top: "-25px",
    justifyContent: "flex-end",
    marginBottom: "20px",
    "& .MuiTextField-root": {
      marginLeft: "10px",
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  logo: {
    width: "250px",
    cursor: "pointer",
  },
  logoSmall: {
    width: "130px",
    cursor: "pointer",
  },
  groupAuthButton: {
    "& .MuiButton-text": {
      fontSize: "16px",
    },
  },
  continueBooking: {
    position: "fixed",
    bottom: "0",
    right: "0",
  },
}));
function Header(props) {
  const classes = useStyles();

  const { title, isLogined, logout, size, userProfile, changeLanguage, i18n, t } = props;

  const [anchorEl, setAnchorEl] = useState(false);
  const [languageEl, setLanguageEl] = useState(false);
  const [preLogined, setPreLogined] = useState(null);

  if (preLogined !== isLogined) {
    setPreLogined(isLogined);
  }

  const handleOpen = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleOpenChangeLanguge = (event) => setLanguageEl(event.currentTarget);

  const handleCloseChangeLanguge = () => setLanguageEl(null);

  const open = Boolean(anchorEl);
  const openLang = Boolean(languageEl);

  const getTitleProfile = (userProfile = {}) => {
    if (userProfile.first_name && userProfile.last_name) {
      return `${userProfile.first_name} ${userProfile.last_name}`;
    } else {
      return userProfile.email;
    }
  };

  return (
    <React.Fragment>
      <Toolbar
        className={classes.toolbar}
        style={{
          borderBottom: size === "small" ? "1px solid #ddd" : "",
        }}>
        <div size="small" onClick={() => history.push(paths.HomePage)}>
          <img src={ASSETS.LOGO} className={size === "small" ? classes.logoSmall : classes.logo} />
        </div>

        <Grid container style={{ marginTop: 16 }}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            noWrap
            className={classes.toolbarTitle}>
            {title}
          </Typography>
          {isLogined && (
            <>
              <div className={size === "small" ? classes.smallProfile : "topbar__profile"}>
                <button type="button" className="topbar__avatar" onClick={handleOpen}>
                  <img className="topbar__avatar-img" src={Avatar} alt="avatar" />
                  &nbsp;
                  <p className="topbar__avatar-name" style={{ textTransform: "uppercase" }}>
                    {getTitleProfile(userProfile)}
                  </p>
                  <ChevronDownIcon className="topbar__icon" />
                </button>
              </div>
              <Popover
                id="simple-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}>
                <TopbarMenuLinks title={t("MyProfile")} path={paths.ProfilePage} />
                <TopbarMenuLinks title={t("MyBooking")} path={paths.MyBookingPage} />
                <TopbarMenuLinks title={t("New Booking")} path={paths.HomePage} />
                <TopbarMenuLinks
                  title={t("Logout")}
                  // path={paths.LoginPage}
                  onClick={() => {
                    logout();
                    setAnchorEl(null);
                  }}
                />

                {/* <Typography className={classes.typography}>The content of the Popover.</Typography> */}
              </Popover>
            </>
          )}

          {!isLogined && (
            <div className={classes.groupAuthButton}>
              <Button size="small" className={classes.colorRed} onClick={props.openLoginModal}>
                {t("SignIn")}
              </Button>{" "}
              |
              <Button size="small" onClick={() => history.push(paths.RegisterPage)}>
                {t("Register")}
              </Button>
            </div>
          )}

          <div>
            <button type="button" className="topbar__avatar" onClick={handleOpenChangeLanguge}>
              <img
                className=""
                src={require(`../../assets/images/flags/${i18n.language || "en"}-flag.png`)}
                alt="flag"
              />
              &nbsp;
              <ChevronDownIcon className="topbar__icon" />
            </button>
          </div>
          <Popover
            id="simple-popover"
            open={openLang}
            anchorEl={languageEl}
            onClose={handleCloseChangeLanguge}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}>
            <TopbarMenuLinks
              title={
                <>
                  <img
                    className=""
                    src={require("../../assets/images/flags/en-flag.png")}
                    alt="flag"
                  />
                  &nbsp;English
                </>
              }
              // path={paths.LoginPage}
              onClick={() => {
                changeLanguage("en");
                setAnchorEl(null);
              }}
            />
            <TopbarMenuLinks
              title={
                <>
                  <img
                    className=""
                    src={require("../../assets/images/flags/de-flag.png")}
                    alt="flag"
                  />
                  &nbsp;Deutsch
                </>
              }
              // path={paths.LoginPage}
              onClick={() => {
                changeLanguage("de");
                setAnchorEl(null);
              }}
            />

            {/* <Typography className={classes.typography}>The content of the Popover.</Typography> */}
          </Popover>
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
}

const mapState = ({ router, authReducer, publicReducer }) => ({
  isLogining: authReducer.isLogining,
  email: (authReducer.userProfile || {}).email,
  pathname: router.location.pathname,
  bookingSession: publicReducer.bookingSession,
  locale: publicReducer.locale,
  userProfile: authReducer.userProfile,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      login: loginNotRedirect,
      logout,
      openLoginModal,
      openSearchedModal,
      changeLanguage,
    },
    dispatch,
  );

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
  isLogined: PropTypes.bool,
  isLogining: PropTypes.bool,
  login: PropTypes.func,
  logout: PropTypes.func,
  email: PropTypes.string,
  size: PropTypes.string,
  pathname: PropTypes.string,
  bookingSession: PropTypes.object,
  i18n: PropTypes.object,
  userProfile: PropTypes.object,
  openLoginModal: PropTypes.func,
  openSearchedModal: PropTypes.func,
};

export default connect(mapState, mapDispatch)(withTranslation("common")(Header));
