import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import Stage1 from "../../components/Booking/Stage1";

import * as selector from "../../selectors/bookingSession/stage1";

const Stage1Container = (props) => {
  useEffect(() => {
    const { fetchExFacilityCategories, fetchCateringServices } = props;

    fetchExFacilityCategories();
    fetchCateringServices();
  }, []);

  return <Stage1 {...props} />;
};

const mapState = (state) => ({
  roomName: selector.getRoomName(state),
  roomImage: selector.getRoomImage(state),
  permanentFacilities: selector.getPermanentFacilities(state),
  bookingDate: selector.getBookingDate(state),
  bookingParticipants: selector.getBookingParticipants(state),
  maxParticipants: selector.getMaxParticipants(state),
  roomPrice: selector.getRoomPriceBeautify(state),
  fetchingSelectableExFacilityCategories: selector.isFetchingSelectableExFacilityCategories(state),
  selectableExFacilityCategories: selector.getSelectableExFacilityCategories(state),
  selectedExFacilityCategory: selector.getSelectedExFacilityCategory(state),

  fetchingSelectableExFacilityQuantities: selector.isFetchingSelectableExFacilityAvailabilities(
    state,
  ),
  selectableExFacilityQuantities: selector.getSelectableExFacilityQuantities(state),
  selectedExFacilityQuantity: selector.getSelectedExFacilityQuantity(state),
  selectedExFacilityDays: selector.getSelectedExFacilityDays(state),
  isAddingExFacilityCategoryItem: selector.isAddingExFacilityCategoryItems(state),
  removingExFacilityCategoryItem: selector.removingExFacilityCategoryItems(state),
  exFacilityItems: selector.getExFacilityItemsBeautify(state),

  selectedExFacilityPrice: selector.getSelectedExFacilityPricingBeautify(state),
  selectedExFacilityTotal: selector.getSelectedExFacilityPricingTotalBeautify(state),
  isFetchingExFacilityItemPricings: selector.isFetchingExFacilityItemPricings(state),

  selectableCateringServices: selector.getSelectableCateringServices(state),
  fetchingSelectableCateringServices: selector.isFetchingSelectableCateringServices(state),
  selectedCateringService: selector.getSelectedCateringService(state),
  cateringServiceQuantity: selector.getCateringServiceQuantity(state),
  fetchingCateringServicePricings: selector.isFetchingCateringServicePricings(state),
  selectedCateringServicePrice: selector.getSelectedCateringServicePricingBeautify(state),
  selectedCateringServiceTotal: selector.getSelectedCateringServicePricingTotalBeautify(state),
  selectedCateringServiceDays: selector.getSelectedCateringServiceDays(state),
  isAddingCateringServiceItem: selector.isAddingCateringServiceItem(state),
  removingCateringServiceItem: selector.removingCateringServiceItem(state),
  cateringItems: selector.getCateringServiceItemsBeautify(state),
  additionalRequirements: selector.getAdditionalRequirements(state),

  totalPrice: selector.getTotalPriceBeautify(state),
  invoiceUrl: selector.getInvoiceUrl(state),

  isCancelSubmitting: selector.isCancelSubmitting(state),
  isNextSubmitting: selector.isNextSubmitting(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      fetchExFacilityCategories: actions.stage1FetchExtraFacilityCategories,
      fetchCateringServices: actions.stage1FetchCateringServices,
      onChangeSelectedExFacilityCategory: actions.stage1ChangeSelectedExFacilityCategory,
      onClickAddExFacilityCategoryItem: actions.stage1AddExFacilityCategoryItem,
      onChangeSelectedExFacilityQuantity: actions.stage1ChangeSelectedExFacilityQuantity,
      onChangeSelectedCateringService: actions.stage1ChangeSelectedCateringService,
      onChangeCateringServiceQuantity: actions.stage1ChangeCateringServiceQuantity,
      onClickAddCateringServiceItem: actions.stage1AddCateringServiceItem,
      onRemoveExFacilityItem: actions.stage1RemoveExFacilityCategoryItem,
      onRemoveCateringItem: actions.stage1RemoveCateringServiceItem,

      onChangeAdditionalRequirements: actions.stage1ChangeAdditionalRequirements,
      onClickCancel: actions.stage1CancelClick,
      onClickNext: actions.stage1NextClick,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(Stage1Container);
