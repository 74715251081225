
import publicSaga from './public';
import authSaga from './auth'
import bookingSessionSaga from './bookingSession'
import bookingSaga from './booking'

export default function rootSaga() {
  return [
    publicSaga,
    authSaga,
    bookingSessionSaga,
    bookingSaga,
  ]
}
