export const BOOK_FOR = {
  SINGLE_DAY: 0,
  MULTIPLE_DAY: 1,
};

export const ITEM_TYPE = {
  ROOM: 1,
  EX_FACILITY: 2,
  CATERING: 3,
};

export const PAYMENT_METHOD = {
  STRIPE: 1,
  INVOICE: 2,
};
