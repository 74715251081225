import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Stage5 from "../../components/Booking/Stage5";

import * as selector from "../../selectors/bookingSession/stage5";

const Stage5Container = (props) => {
  return <Stage5 {...props} />;
};

const mapState = (state) => ({
  isLoadingInvoiceHtml: selector.isLoadingInvoiceHtml(state),
  invoiceHtml: selector.getInvoiceHtml(state),
  invoiceUrl: selector.getInvoicePdfUrl(state),
});

const mapDispatch = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapState, mapDispatch)(Stage5Container);
