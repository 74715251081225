import axios from "axios";

import { LOCAL_STORAGE_KEY } from "../helpers/constant";

import * as storage from "../services/storage";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default class HttpClient {
  constructor() {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    instance.interceptors.request.use(
      (config) => {
        const authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization);
        const session = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.session);
        if (authorization) {
          config.headers.Authorization = `JWT ${(authorization || {}).access}`;
        }
        if (session) {
          config.headers.Session = session;
        }
        return config;
      },
      (error) => {
        throw Error(error);
      },
    );

    instance.interceptors.response.use(
      (res) => res.data,
      (error) => {
        if (error.response) {
          storage.setLocalStorageItem(
            LOCAL_STORAGE_KEY.session,
            (((error.response || {}).data || {}).meta || {}).session,
          );
          throw error.response;
        }
      },
    );

    this.request = instance;
  }

  get(url, params, options) {
    return this.request.get(url, { params, ...options });
  }

  post(url, data, options) {
    const response = this.request.post(url, data, options);
    return response;
  }

  put(url, data, params, options) {
    return this.request.put(url, data, { params, ...options });
  }

  patch(url, data, params, options) {
    return this.request.patch(url, data, { params, ...options });
  }

  del(url, data, params, options) {
    return this.request.delete(url, { data, params, ...options });
  }

  getSource(url) {
    const config = {
      baseURL: window.location.origin,
      withCredentials: true,
    };
    return axios.get(url, config);
  }
}
