import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 48,
    margin: "auto",
    marginBottom: 84,
  },
  image: {
    width: 240,
    height: 240,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  },
}));

const Stage4 = ({
  t,

  bookingDate: { startDate, startTime, endDate, endTime },
  participants,
  customer: { fullname, email, company, deparment, address },
  items,
  additionalRequirement,

  subTotal,
  tax,
  taxRate,
  grandTotal,
  paymentMethod,
  freeCancelDate,

  isBackSubmitting,
  isCancelSubmitting,
  isNextSubmitting,

  onBackClick,
  onCancelClick,
  onNextClick,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={6}>
        <Grid item container xs={7} direction="column">
          <Grid item>
            <Typography variant="h6" color="primary">
              {t("BOOKING CONFIRMATION")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">--</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              <b>{t("Booking date")}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("From {date}").replace("{date}", startDate)}, {startTime}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("To {date}").replace("{date}", endDate)}, {endTime}
            </Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                <b>{t("Participants")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{participants}</Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="subtitle1">
              <b>{t("Extras")}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{additionalRequirement}</Typography>
          </Grid>
        </Grid>

        <Grid item container xs={5} direction="column">
          <Grid item>
            <Typography variant="h6" color="primary">
              {t("CUSTOMER'S INFO")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">--</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                <b>{t("Full name")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{fullname}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                <b>{t("Email")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{email}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                <b>{t("Company")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{company}</Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xl={2}>
              <Typography variant="subtitle1">
                <b>{t("Address")}</b>
              </Typography>
            </Grid>
            <Grid item xl={10}>
              <Typography variant="subtitle1">{address}</Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                <b>{t("Payment Method")}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{t(paymentMethod)}</Typography>
            </Grid>
          </Grid>

          <Grid item spacing={2}>
            <Typography variant="subtitle1">
              <b>
                {t("Free cancellation before")} {freeCancelDate}, 18:00
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={6} style={{ paddingTop: 32 }}>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <b>{t("Booking Items")}</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">
            <b>{t("Date")}</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">
            <b>{t("Price")}</b>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle1">
            <b>{t("Quantity")}</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">
            <b>{t("Discount")}</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">
            <b>{t("Total")}</b>
          </Typography>
        </Grid>
      </Grid>
      <Divider />

      {_.map(items, (it, ix) => [
        <Grid container spacing={6} style={{ paddingTop: 12, paddingBottom: 12 }} key={ix}>
          <Grid item xs={3}>
            <Typography variant="subtitle1">{_.get(it, "name")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">{_.get(it, "date")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">{_.get(it, "price")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{_.get(it, "quantity")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">{_.get(it, "discount")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">{_.get(it, "total")}</Typography>
          </Grid>
        </Grid>,
        <Divider key={ix} />,
      ])}

      <Grid container spacing={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
        <Grid item xs={8}></Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t("Subtotal")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{subTotal}</Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container spacing={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
        <Grid item xs={8}></Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t("Tax ({tax})").replace("{tax}", taxRate)}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{tax}</Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container spacing={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
        <Grid item xs={8}></Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" color="primary">
            {t("GRAND TOTAL")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1" color="primary">
            {grandTotal}
          </Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container style={{ marginTop: 56 }} justify="space-between">
        <Grid item>
          <Button
            variant="contained"
            color="default"
            onClick={onCancelClick}
            style={{ marginRight: 16 }}
            disabled={isNextSubmitting || isBackSubmitting || isCancelSubmitting}>
            {isCancelSubmitting && <CircularProgress size={16} />}&nbsp;
            {t("Abort")}
          </Button>

          <Button
            variant="contained"
            color="default"
            onClick={onBackClick}
            disabled={isNextSubmitting || isBackSubmitting || isCancelSubmitting}>
            {isBackSubmitting && <CircularProgress size={16} />}&nbsp;
            {t("Back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={onNextClick}
            disabled={isNextSubmitting || isBackSubmitting || isCancelSubmitting}>
            {isNextSubmitting && <CircularProgress size={16} />}&nbsp;
            {t("Confirm")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withTranslation("booking")(Stage4);
