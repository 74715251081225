import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import ContactModal from "../../components/Modal/BookingRequestModal";

import * as selector from "../../selectors/bookingSession/contact";

const ContactContainer = (props) => {
  return <ContactModal {...props} />;
};

const mapState = (state) => ({
  isOpen: selector.isShowing(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      closeModal: actions.hideContactForm,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(ContactContainer);
