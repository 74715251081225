import * as _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import SearchRoomModal from "../../components/Booking/Stage0";

import * as selector from "../../selectors/bookingSession/stage0";

const Stage0Container = (props) => {
  return <SearchRoomModal {...props} />;
};

const mapState = (state) => ({
  locale: _.get(state, "publicReducer.locale.lang", "de"),
  isShowing: selector.isShowing(state),
  bookingType: selector.getBookingType(state),
  maxParticipants: selector.getMaximumParticipants(state),
  participantNumber: selector.getParticipantNum(state),
  minimumDate: selector.getMinimumSelectableDate(state),
  selectionType: selector.getDateSelectionType(state),
  numberOfCalendars: selector.getNumberOfCalendars(state),
  dateRange: selector.getDateRange(state),

  selectableStartTimes: selector.getSelectableStartTimes(state),
  startTime: selector.getStartTime(state),

  selectableEndTimes: selector.getSelectableEndTimes(state),
  endTime: selector.getEndTime(state),

  hasPresentationDevice: selector.hasPresentationDevice(state),

  canSubmit: selector.canSearch(state),
  isSubmitting: selector.isSearching(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      onClose: actions.hidePickDatePopup,
      onChangeBookingType: actions.changeBookingType,
      onChangeParticipantNumber: actions.changeParticipantNumber,
      onBookingStartDateChange: actions.changeBookingStartDate,
      onBookingEndDateChange: actions.changeBookingEndDate,
      onChangeStartTime: actions.onChangeBookingStartTime,
      onChangeEndTime: actions.onChangeBookingEndTime,
      onChangePresentationDevice: actions.changePresentationDevice,
      onCancel: actions.cancelSearchRoom,
      onSubmit: actions.submitSearchRoom,
      onContact: actions.showContactForm,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(Stage0Container);
