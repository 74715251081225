import { bind } from "decko";

import BaseApi from "./baseApi";

export default class Public extends BaseApi {
  @bind
  async listMyBookings(data) {
    return await this.http.post("/booking/external/list_my_bookings", data);
  }

  @bind
  async getBookingToken() {
    return await this.http.post("/booking/get_booking_token");
  }

  @bind
  async getBookingDetail(bookingId) {
    return await this.http.get(`/bookings/${bookingId}`);
  }

  @bind
  async cancelBooking(data, lang = "en") {
    return await this.http.post(`/booking/external/cancel_booking?lang=${lang}`, data);
  }
}
