import { Grid, Toolbar, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Input } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { activateUser, resendActivationCode } from "../../../actions/auth";
import Image from "../../../assets/images/ecos_Stockholm_1.jpg";

import { history } from "../../../config/store";
import { LogoImage as Logo } from "../../../constants/componentHelpers";
import * as paths from "../../../constants/paths.constants";

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      marginTop: "50px",
      marginBottom: "30px",
      color: theme.colorAccent,
      width: "60%",
      cursor: "pointer",
    },
    checkIcon: {
      "& svg": {
        width: "50px",
        height: "50px",
        color: theme.colorAccent,
      },
    },
    background: {
      "& img": {
        height: "100%",
        width: "100%",
      },
    },
    formRoot: {
      width: "300px",
      margin: "0 auto",
      textAlign: "left",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    formField: {
      "& .MuiInputBase-root": {
        // backgroundColor: 'white',
        borderRadius: "15px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    textWelcome: {
      padding: "20px 0",
      color: theme.colorGray,
    },
    verifySuccessText: {
      padding: "0px 0 10px",
      color: theme.colorGray,
    },
    titleSubText: {
      color: theme.colorGray,
      padding: "0 20%",
    },
    buttonGroup: {
      justifyContent: "center",
      "& button": {
        width: 300,
        margin: "25px",
      },
    },
    forgotPassword: {
      cursor: "pointer",
    },
    registerContent: {
      marginTop: "30px",
    },
    registerLink: {
      cursor: "pointer",
    },
    signInContent: {
      textAlign: "center",
      marginTop: "40px",
    },
    buttonShow: {
      justifyContent: "center",
      marginTop: "50px",
      "& button": {
        width: 300,
        margin: "25px",
      },
    },
  };
});

const Verify = (props) => {
  const classes = useStyles();

  const {
    activateUser,
    resendActivationCode,
    t,
    form: { getFieldDecorator, validateFields },
    isResending,
  } = props;

  const [showResendScreen, setShowResendScreen] = React.useState(false);
  const [calledActivation, setCalledActivation] = React.useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!calledActivation) {
      activateUser({
        uid: urlParams.get("uid"),
        token: urlParams.get("token"),
      });
      setCalledActivation(true);
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateFields((errors, value) => {
      if (!errors) {
        resendActivationCode(value);
      }
    });
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item md={4} sm={4} xs={4}>
        <Typography variant="h2" align="center" className={classes.aa}>
          <img
            src={Logo}
            className={classes.logo}
            onClick={() => history.push(paths.HomePage)}
          />
        </Typography>

        <Typography variant="h5" align="center" className={classes.textWelcome}>
          {t("1")}
        </Typography>

        {!showResendScreen && (
          <>
            <Typography
              variant="body2"
              align="center"
              className={classes.titleSubText}
            >
              <CircularProgress />
            </Typography>
            <Grid item xs={12}>
              <Toolbar className={classes.buttonShow}>
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={() => setShowResendScreen(true)}
                >
                  {t("2")}
                </Button>
              </Toolbar>
            </Grid>
          </>
        )}
        {showResendScreen && (
          <Form
            className={classes.formRoot}
            hideRequiredMark
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container>
              <Grid md={12} xs={12}>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [
                      { required: true, message: t("PleaseInputYourEmail") },
                      {
                        type: "email",
                        message: t("PleaseInputCorrectYourEmail"),
                      },
                    ],
                  })(<Input placeholder="Email" />)}
                </Form.Item>
                <Grid item xs={12}>
                  <Toolbar className={classes.buttonGroup}>
                    <Button
                      autoFocus
                      type="primary"
                      htmlType="submit"
                      block
                      loading={isResending}
                    >
                      {t("3")}
                    </Button>
                  </Toolbar>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
        <Grid item xs={12} className={classes.signInContent}>
          <Typography variant="body2">
            {t("AlreadyAMember")}{" "}
            <Link to={paths.LoginPage} className={classes.registertLink}>
              {t("SignIn")}
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid item md={8} sm={8} xs={8} className={classes.background}>
        <img src={Image} />
      </Grid>
    </Grid>
  );
};

const mapState = (state) => ({
  isResending: state.authReducer.isResending,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      activateUser,
      resendActivationCode,
    },
    dispatch
  );

Verify.propTypes = {
  verifyAccount: PropTypes.func,
  activateUser: PropTypes.func,
  isVerified: PropTypes.bool,
  resendActivationCode: PropTypes.func,
  form: PropTypes.object,
  isResending: PropTypes.bool,
};

export default Form.create({
  name: "resendForm",
})(connect(mapState, mapDispatch)(withTranslation("common")(Verify)));
