import { Grid, Link, Typography } from "@material-ui/core";
import DirectionsBusOutlinedIcon from "@material-ui/icons/DirectionsBusOutlined";
import DirectionsCarOutlinedIcon from "@material-ui/icons/DirectionsCarOutlined";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Avatar } from "antd";
import React from "react";
import { Image } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import assets from "../../constants/assets";
import * as PATH from "../../constants/paths.constants";

const Footer = (props) => {
  const { t } = props;

  return (
    <div>
      <div className="contact">
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Image height="100%" width="100%" src={assets.FOOTER_IMG}></Image>
          </Grid>

          <Grid item xs={12} sm={12} xs={12} md={3} lg={3} xl={3} style={{ paddingLeft: 12 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="subtitle1">ePlaza office center </Typography>
                </Grid>
              </Grid>

              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="subtitle1">Stockholmer Platz 1</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">70173 Stuttgart</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" color="secondary">
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://www.google.de/maps/place/ecos+office+center+Stuttgart/@48.7897344,9.1817181,17z/data=!3m1!4b1!4m5!3m4!1s0x4799dc55b24a0be1:0xa18c8549a88ba364!8m2!3d48.789523!4d9.183784">
                      <LocationOnIcon /> Google Map
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Tel.: +49 711 7811 - 500</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Fax: +49 711 7811 - 501</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Email: <Link href="mailto:info@eplaza-office.de">info@eplaza-office.de</Link></Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Website: <Link
                      target="_blank"
                      rel="noopener"
                      href="https://eplaza-office.de">
                      https://eplaza-office.de
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            style={{ backgroundColor: "mistyrose", padding: 8 }}>
            <Grid container direction="column" spacing={2} alignItems="stretch">
              <Grid item container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                  <Typography variant="h6">{t("FOOTER.Directions")}</Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <Avatar icon={<DirectionsCarOutlinedIcon />} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="subtitle1">{t("FOOTER.ByCar")}</Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <Avatar icon={<DirectionsBusOutlinedIcon />} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="subtitle1">{t("FOOTER.ByBus")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <nav className="copyright-collection">
        <ul>
          <li className="item">© ePlaza office center 2021</li>
          <li className="item">
            <Link href={PATH.Imprint} target="_blank">
              {t("FOOTER.Imprint")}
            </Link>
          </li>
          <li className="item">
            <Link href={PATH.Privacy} target="_blank">
              {t("FOOTER.PrivacyPolicy")}
            </Link>
          </li>

          <li className="item">
            <a href={assets.AGB_PDF} target="_blank">
              {t("FOOTER.AGBs")}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default withTranslation("common")(Footer);
