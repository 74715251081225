import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import ContinueButton from "../../components/Booking/components/ContinueFloatButton";

import * as selector from "../../selectors/bookingSession";

const ButtonContainer = (props) => {
  const { hasContinueBooking } = props;

  return hasContinueBooking ? <ContinueButton {...props} /> : <div />;
};

const mapState = (state) => ({
  hasContinueBooking: selector.hasContinueBooking(state),
  isProcessing: selector.isBookingProcessContinuing(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      onClick: actions.continueBookingProcess,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(ButtonContainer);
