import { message } from "antd";
import * as _ from "lodash";
import { all, call, put, select, takeLatest, takeLeading } from "redux-saga/effects";

import * as AUTH_C from "../actionTypes/auth.actionTypes";
import * as C from "../actionTypes/bookingSession";
import * as MD from "../actionTypes/modal.actionTypes";

import i18n from "../config/i18next";
import { history } from "../config/store";
import { PAYMENT_METHOD } from "../constants/bookingSession";
import * as PATH from "../constants/paths.constants";

import * as authSelector from "../selectors/auth";
import * as sessionSelector from "../selectors/bookingSession";
import * as stage0Selector from "../selectors/bookingSession/stage0";
import * as stage1Selector from "../selectors/bookingSession/stage1";
import * as stage3Selector from "../selectors/bookingSession/stage3";
import { getLocale } from "../selectors/home";
import * as storage from "../services/storage";

const URL = process.env.REACT_APP_BASE_URL;

function* loadPickDateData({ api }) {
  const leadTimeList = yield select(stage0Selector.getLeadTimeList);
  if (leadTimeList && leadTimeList.length) {
    yield put({ type: C.STAGE_0_RETRIEVE_LEAD_TIME_COMPLETED, payload: leadTimeList });
  } else {
    try {
      const response = yield call(api.bookingSessionApi.listLeadTime);
      yield put({ type: C.STAGE_0_RETRIEVE_LEAD_TIME_COMPLETED, payload: response });
    } catch (e) {
      yield put({ type: C.STAGE_0_RETRIEVE_LEAD_TIME_FAILED, e });
      console.error(e);
    }
  }
}

function* continueBookingProcess({ api }) {
  const currentSession = yield select(sessionSelector.getSessionData);
  if (_.get(currentSession, "id")) {
    yield put({ type: C.CONTINUE_BOOKING_PROCESS_COMPLETED, payload: currentSession });
    history.push(PATH.BookingPage);
  } else {
    try {
      const booking = yield call(api.public.getBookingSession);
      if (booking && booking.stage !== null) {
        yield put({ type: C.CONTINUE_BOOKING_PROCESS_COMPLETED, payload: booking });
        history.push(PATH.BookingPage);
      } else {
        yield put({ type: C.CONTINUE_BOOKING_PROCESS_FAILED });
      }
    } catch (e) {
      yield put({ type: C.CONTINUE_BOOKING_PROCESS_FAILED });
    }
  }
}

function* searchRoom({ api }) {
  yield put({ type: C.STAGE_0_SEARCHING_ROOM });
  const data = yield select(stage0Selector.generateSearchPayload);
  try {
    console.log("Removing old booking session");
    yield call(api.public.destroyBookingSession);
  } catch (e) {
    console.log("Old booking session already removed");
  }

  try {
    const response = yield call(api.bookingSessionApi.next, data);
    yield put({ type: C.STAGE_0_SEARCHING_ROOM_SUCCESS, payload: response });
    yield storage.setLocalStorageItem("booking", _.pick(response, ["id", "stage"]));

    history.push(PATH.BookingPage);
  } catch (e) {
    yield put({ type: C.STAGE_0_SEARCHING_ROOM_FAIL });
    const errorMessage = i18n.t(`booking:No available room`);
    message.error(errorMessage, 10);
  }
}

function* searchRoomWithType({ api }) {
  yield put({ type: MD.CLOSE_VIEW_ROOM_TYPE_MODAL });
  yield put({ type: C.STAGE_0_SHOW_PICK_DATE_POPUP });
}

function* loadExFacilityCategories({ api }) {
  try {
    const response = yield call(api.public.getListExtraFacilityCategories);
    console.log(response);
    yield put({ type: C.STAGE_1_FETCH_EX_FACILITY_CATEGORIES_COMPLETED, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_FETCH_EX_FACILITY_CATEGORIES_FAILED });
  }
}

function* loadExFacilityAvailabilities({ api }) {
  yield put({ type: C.STAGE_1_FETCH_EX_FACILITY_AVAILABILITY });
  const data = yield select(stage1Selector.generateExFacilityAvailabilitiesReqPayload);
  try {
    const response = yield call(api.public.searchExtraFacilities, data);
    console.log(response);
    yield put({ type: C.STAGE_1_FETCH_EX_FACILITY_AVAILABILITY_COMPLETED, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_FETCH_EX_FACILITY_AVAILABILITY_FAILED });
  }
}

function* retrieveExFacilityItemPricings({ api }) {
  yield put({ type: C.STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS });
  const data = yield select(stage1Selector.generateExFacilityPricingReqPayload);
  try {
    const response = yield call(api.public.retrieveItemPricings, data);
    console.log(response);
    yield put({ type: C.STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS_COMPLETED, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_FETCH_EX_FACILITY_ITEM_PRICINGS_FAILED });
  }
}

function* addExFacilityItem({ api }) {
  const data = yield select(stage1Selector.generateAddExFacilityItemReqPayload);
  try {
    const response = yield call(api.public.addExtraFacilities, data);
    yield put({ type: C.STAGE_1_ADD_EX_FACILITY_ITEM_COMPLETE, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_ADD_EX_FACILITY_ITEM_FAILED });
  }
}

function* loadCateringServices({ api }) {
  try {
    const response = yield call(api.public.getListCaterings);
    yield put({ type: C.STAGE_1_FETCH_CATERING_SERVICES_COMPLETED, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_FETCH_CATERING_SERVICES_FAILED });
  }
}

function* retrieveCateringItemPricings({ api }) {
  yield put({ type: C.STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS });
  const data = yield select(stage1Selector.generateCateringServicePricingReqPayload);
  try {
    const response = yield call(api.public.retrieveItemPricings, data);
    console.log(response);
    yield put({
      type: C.STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS_COMPLETED,
      payload: response,
    });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_FETCH_CATERING_SERVICE_ITEM_PRICINGS_FAILED });
  }
}

function* addCateringServiceItem({ api }) {
  const data = yield select(stage1Selector.generateAddCateringItemReqPayload);
  try {
    const response = yield call(api.public.addCatering, data);
    yield put({ type: C.STAGE_1_ADD_CATERING_SERVICE_ITEM_COMPLETED, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_ADD_CATERING_SERVICE_ITEM_FAILED });
  }
}

function* removeCateringServiceItem({ api }, { payload }) {
  const data = yield select(stage1Selector.generateRemoveCateringItemsReqPayload(payload));
  try {
    const response = yield call(api.public.removeSessionItems, data);
    yield put({ type: C.STAGE_1_REMOVE_CATERING_SERVICE_ITEM_COMPLETED, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_REMOVE_CATERING_SERVICE_ITEM_FAILED });
  }
}

function* removeExFacilityItem({ api }, { payload }) {
  const data = yield select(stage1Selector.generateRemoveExFacilityItemsReqPayload(payload));
  try {
    const response = yield call(api.public.removeSessionItems, data);
    yield put({ type: C.STAGE_1_REMOVE_EX_FACILITY_ITEM_COMPLETE, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_REMOVE_EX_FACILITY_ITEM_FAILED });
  }
}

const cancelPostProcess = () => {
  storage.removeLocalStorage("booking");
  history.push(PATH.HomePage);
};

function* cancelBookingProcess({ api }) {
  try {
    const response = yield call(api.public.destroyBookingSession);
    yield put({ type: C.STAGE_1_CLICK_CANCEL_COMPLETED, payload: response });
  } catch (e) {
    console.error(e);
    yield put({ type: C.STAGE_1_CLICK_CANCEL_FAILED });
  }
  cancelPostProcess();
}

function* stage1Next({ api }) {
  const isAuthenticated = yield select(authSelector.getIsLogined);
  if (!isAuthenticated) {
    yield put({ type: MD.OPEN_LOGIN_MODAL });
    yield put({ type: C.STAGE_1_CLICK_NEXT_FAILED });
  } else {
    const data = yield select(stage1Selector.generateNextReqPayload);

    try {
      const response = yield call(api.bookingSessionApi.next, data);
      yield put({ type: C.STAGE_1_CLICK_NEXT_COMPLETED, payload: response });
    } catch (e) {
      yield put({ type: C.STAGE_1_CLICK_NEXT_FAILED, payload: e });
      console.error(e);
    }
  }
}

function* stage2Back({ api }) {
  const isAuthenticated = yield select(authSelector.getIsLogined);
  if (!isAuthenticated) {
    yield put({ type: MD.OPEN_LOGIN_MODAL });
    yield put({ type: C.STAGE_2_CLICK_BACK_FAILED });
  } else {
    try {
      const response = yield call(api.bookingSessionApi.back);
      yield put({ type: C.STAGE_2_CLICK_BACK_COMPLETED, payload: response });
    } catch (e) {
      yield put({ type: C.STAGE_2_CLICK_BACK_FAILED, payload: e });
      console.error(e);
    }
  }
}

function* stage2Next({ api }) {
  const isAuthenticated = yield select(authSelector.getIsLogined);
  if (!isAuthenticated) {
    yield put({ type: MD.OPEN_LOGIN_MODAL });
    yield put({ type: C.STAGE_2_CLICK_NEXT_FAILED });
  } else {
    try {
      const response = yield call(api.bookingSessionApi.next);
      yield put({ type: C.STAGE_2_CLICK_NEXT_COMPLETED, payload: response });
    } catch (e) {
      yield put({ type: C.STAGE_2_CLICK_NEXT_FAILED, payload: e });
      console.error(e);
    }
  }
}

function* stage2Cancel({ api }) {
  try {
    const response = yield call(api.bookingSessionApi.cancel);
    yield put({ type: C.STAGE_2_CLICK_CANCEL_COMPLETED, payload: response });
  } catch (e) {
    yield put({ type: C.STAGE_2_CLICK_CANCEL_FAILED, payload: e });
    console.error(e);
  }
  cancelPostProcess();
}

function* stage3Next({ api }, { payload }) {
  const paymentMethod = yield select(stage3Selector.getPaymentMethod);

  try {
    if (paymentMethod === PAYMENT_METHOD.STRIPE) {
      const { stripe, card } = payload;
      const result = yield call(stripe.createToken, card);
      const response = yield call(api.bookingSessionApi.next, {
        payment_token: _.get(result, "token.id"),
      });
      yield put({ type: C.STAGE_3_CLICK_NEXT_COMPLETED, payload: response });
    } else {
      const response = yield call(api.bookingSessionApi.next);
      yield put({ type: C.STAGE_3_CLICK_NEXT_COMPLETED, payload: response });
    }
  } catch (e) {
    yield put({ type: C.STAGE_3_CLICK_NEXT_FAILED, payload: e });
    console.error(e);
  }
}

function* stage3Back({ api }) {
  const isAuthenticated = yield select(authSelector.getIsLogined);
  if (!isAuthenticated) {
    yield put({ type: MD.OPEN_LOGIN_MODAL });
    yield put({ type: C.STAGE_3_CLICK_BACK_FAILED });
  } else {
    try {
      const response = yield call(api.bookingSessionApi.back);
      yield put({ type: C.STAGE_3_CLICK_BACK_COMPLETED, payload: response });
    } catch (e) {
      yield put({ type: C.STAGE_3_CLICK_BACK_FAILED, payload: e });
      console.error(e);
    }
  }
}

function* stage3Cancel({ api }) {
  try {
    const response = yield call(api.bookingSessionApi.cancel);
    yield put({ type: C.STAGE_3_CLICK_CANCEL_COMPLETED, payload: response });
  } catch (e) {
    yield put({ type: C.STAGE_3_CLICK_CANCEL_FAILED, payload: e });
    console.error(e);
  }
  cancelPostProcess();
}

function* stage4Next({ api }) {
  const isAuthenticated = yield select(authSelector.getIsLogined);
  if (!isAuthenticated) {
    yield put({ type: MD.OPEN_LOGIN_MODAL });
    yield put({ type: C.STAGE_4_CLICK_NEXT_FAILED });
  } else {
    try {
      const locale = yield select(getLocale);
      const response = yield call(api.bookingSessionApi.next, null, locale);

      yield put({ type: C.STAGE_4_CLICK_NEXT_COMPLETED, payload: response });
      storage.removeLocalStorage("booking");

      yield put({ type: C.STAGE_5_LOAD_INVOICE_HTML, payload: response });
    } catch (e) {
      yield put({ type: C.STAGE_4_CLICK_NEXT_FAILED, payload: e });
      console.error(e);
    }
  }
}

function* stage4Back({ api }) {
  const isAuthenticated = yield select(authSelector.getIsLogined);
  if (!isAuthenticated) {
    yield put({ type: MD.OPEN_LOGIN_MODAL });
    yield put({ type: C.STAGE_4_CLICK_BACK_FAILED });
  } else {
    try {
      const response = yield call(api.bookingSessionApi.back);
      yield put({ type: C.STAGE_4_CLICK_BACK_COMPLETED, payload: response });
    } catch (e) {
      yield put({ type: C.STAGE_4_CLICK_BACK_FAILED, payload: e });
      console.error(e);
    }
  }
}

function* stage4Cacnel({ api }) {
  try {
    const response = yield call(api.bookingSessionApi.cancel);
    yield put({ type: C.STAGE_4_CLICK_CANCEL_COMPLETED, payload: response });
  } catch (e) {
    yield put({ type: C.STAGE_4_CLICK_CANCEL_FAILED, payload: e });
    console.error(e);
  }
  cancelPostProcess();
}

function* stage5LoadInvoiceHtml({ api }, { payload: { id } }) {
  try {
    const locale = yield select(getLocale);
    const response = yield call(api.bookingSessionApi.getBookingToken);
    const token = _.get(response, "token", "");
    const pdfUrl = `${URL}/booking/download_invoice_pdf?booking_id=${id}&token=${token}&lang=${locale}`;
    const html = yield call(api.bookingSessionApi.downloadBookingInvoiceHTML, {
      booking_id: id,
      token,
      lang: locale,
    });
    yield put({ type: C.STAGE_5_LOAD_INVOICE_HTML_COMPLETED, payload: { html, pdfUrl } });
  } catch (e) {
    yield put({ type: C.STAGE_5_LOAD_INVOICE_HTML_FAILED });
  }
}

function* checkNonWorkingDate({ api }) {
  const hasNonWorkindDate = yield select(stage0Selector.hasWeekendOrHoliday);
  if (hasNonWorkindDate) {
    yield put({ type: C.STAGE_0_SHOW_HOLIDAY_WARNING_POPUP });
  }
  console.log("Has Non working date ", hasNonWorkindDate);
}

function publicSaga(deps) {
  return function* saga() {
    yield all([
      takeLeading(C.STAGE_0_SHOW_PICK_DATE_POPUP, loadPickDateData, deps),
      takeLatest(C.STAGE_0_CHANGE_BOOKING_START_DATE, checkNonWorkingDate, deps),
      takeLatest(C.STAGE_0_CHANGE_BOOKING_END_DATE, checkNonWorkingDate, deps),
      takeLeading(C.CONTINUE_BOOKING_PROCESS, continueBookingProcess, deps),
      takeLeading(C.STAGE_0_CLICK_SEARCH_ROOM, searchRoom, deps),
      takeLatest(C.STAGE_0_START_BOOK_FOR_ROOMTYPE, searchRoomWithType, deps),
      takeLeading(C.STAGE_1_FETCH_EX_FACILITY_CATEGORIES, loadExFacilityCategories, deps),
      takeLeading(C.STAGE_1_FETCH_CATERING_SERVICES, loadCateringServices, deps),
      takeLatest(C.STAGE_1_CHANGE_EX_FACILITY, loadExFacilityAvailabilities, deps),
      takeLatest(C.STAGE_1_CHANGE_EX_FACILITY_QUANTITY, retrieveExFacilityItemPricings, deps),
      takeLeading(C.STAGE_1_ADD_EX_FACILITY_ITEM, addExFacilityItem, deps),
      takeLatest(C.STAGE_1_CHANGE_CATERING_SERVICE_QUANTITY, retrieveCateringItemPricings, deps),
      takeLeading(C.STAGE_1_ADD_CATERING_SERVICE_ITEM, addCateringServiceItem, deps),
      takeLeading(C.STAGE_1_REMOVE_CATERING_SERVICE_ITEM, removeCateringServiceItem, deps),
      takeLeading(C.STAGE_1_REMOVE_EX_FACILITY_ITEM, removeExFacilityItem, deps),
      takeLeading(C.STAGE_1_CLICK_CANCEL, cancelBookingProcess, deps),
      takeLeading(C.STAGE_1_CLICK_NEXT, stage1Next, deps),
      takeLeading(C.STAGE_2_CLICK_BACK, stage2Back, deps),
      takeLeading(C.STAGE_2_CLICK_NEXT, stage2Next, deps),
      takeLeading(C.STAGE_2_CLICK_CANCEL, stage2Cancel, deps),
      takeLeading(C.STAGE_3_CLICK_NEXT, stage3Next, deps),
      takeLeading(C.STAGE_3_CLICK_BACK, stage3Back, deps),
      takeLeading(C.STAGE_3_CLICK_CANCEL, stage3Cancel, deps),
      takeLeading(C.STAGE_4_CLICK_NEXT, stage4Next, deps),
      takeLeading(C.STAGE_4_CLICK_BACK, stage4Back, deps),
      takeLeading(C.STAGE_4_CLICK_CANCEL, stage4Cacnel, deps),
      takeLeading(C.STAGE_5_LOAD_INVOICE_HTML, stage5LoadInvoiceHtml, deps),
      takeLeading(AUTH_C.LOGIN_SUCCESS, continueBookingProcess, deps),
    ]);
  };
}

export default publicSaga;
