import { message, Modal } from "antd";
import { push } from "connected-react-router";
import jwtDecode from "jwt-decode";
import * as _ from "lodash";
import { get } from "lodash";
import moment from "moment";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";

import * as actions from "../actions/auth";
import * as modalActions from "../actions/modal";
import * as publicActions from "../actions/public";
import {
  ACTIVATE_USER,
  CHANGE_PASSWORD,
  CHECK_EMAIL_EXIST,
  CHECK_TOKEN_EXPIRATION,
  GET_PROFILE,
  LOGIN,
  LOGIN_NOT_REDIRECT,
  LOGOUT,
  REGISTER,
  REQUEST_RESET_PASSWORD,
  RESEND_ACTIVATION_CODE,
  RESET_PASSWORD,
  UPDATE_PROFILE,
} from "../actionTypes/auth.actionTypes";

import i18n from "../config/i18next";
import { history } from "../config/store";
import * as paths from "../constants/paths.constants";

import { LOCAL_STORAGE_KEY } from "../helpers/constant";

import * as storage from "../services/storage";

const { success } = Modal;

function* login({ api }, { data }) {
  try {
    const response = yield call(api.auth.Login, data);

    yield storage.setLocalStorageItem(LOCAL_STORAGE_KEY.authorization, response);
    if ((response.meta || {}).session) {
      yield storage.setLocalStorageItem(LOCAL_STORAGE_KEY.session, (response.meta || {}).session);
    }
    yield put(actions.getProfile());
    yield put(actions.loginSuccess());
    yield put(modalActions.closeLoginModal());
  } catch (e) {
    yield storage.removeLocalStorage(LOCAL_STORAGE_KEY.authorization);
    const msg = get(e, "data.detail", "Error");
    message.error(i18n.t(`common:${msg}`));
    yield put(actions.loginFail());
  }
}

function* register({ api }, { data }) {
  try {
    const response = yield call(api.auth.Register, data, i18n.language);
    console.log(response);
    yield put(actions.registerSuccess());
    success({
      width: 720,
      okText: "OK",
      content: i18n
        .t("common:Register successfully Please check your email to active the account")
        .replace("{email}", _.get(data, "email")),
      onOk() {
        history.push(paths.HomePage);
      },
    });
  } catch (e) {
    message.error(e.data.email[0]);
    yield put(actions.registerFail());
  }
}

function* activateUser({ api }, { data }) {
  try {
    const response = yield call(api.auth.ActivateAccount, data, i18n.language);
    console.log(response);
    yield put(actions.activateUserSuccess());
    message.success(i18n.t("common:Activate successfully!"));
    history.push(paths.LoginPage);
  } catch (e) {
    message.error(e.data.detail);
    yield put(actions.activateUserFail());
  }
}

function* logout() {
  try {
    storage.removeLocalStorage(LOCAL_STORAGE_KEY.authorization);
    // yield put(userActions.clearProfile())
    // yield put(actions.logout())
    yield put(push("/"));
    // message.success('You are completely logged out!')
  } catch (e) {
    console.error(e.message);
  }
}

function* loginNotRedirect({ api }, { data }) {
  try {
    const response = yield call(api.auth.Login, data);
    yield storage.setLocalStorageItem(LOCAL_STORAGE_KEY.authorization, response);
    if ((response.meta || {}).session) {
      yield storage.setLocalStorageItem(LOCAL_STORAGE_KEY.session, (response.meta || {}).session);
    }
    yield put(actions.loginSuccess());
    yield put(actions.getProfile());
    yield put(modalActions.closeLoginModal());
    message.success(i18n.t("common:Login successfully!"));
  } catch (e) {
    const msg = get(e, "data.detail", "Error");
    message.error(i18n.t(`common:${msg}`));
    yield put(actions.loginFail());
  }
}

function* resendActivationCode({ api }, { data }) {
  try {
    yield call(api.auth.ResendActivation, data);
    yield put(actions.resendActivationCodeSuccess());
    message.success(i18n.t("common:Resend successfully!"));
  } catch (e) {
    message.error(e.data.detail);
    yield put(actions.resendActivationCodeFail());
  }
}

function* checkTokenExpiration({ api }) {
  try {
    console.log("1");
    const authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization);
    const session = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.session);
    if (!session) {
      const sessionRes = yield call(api.auth.getSession);
      if (sessionRes.meta) {
        storage.setLocalStorageItem(LOCAL_STORAGE_KEY.session, sessionRes.meta.session);
      }
      console.log("Session", sessionRes);
    } else {
      yield put(publicActions.getBookingSessions());
    }
    console.log(authorization);
    if (authorization && authorization.access) {
      const jwtDecodeText1 = jwtDecode(authorization.access);
      const jwtDecodeText2 = jwtDecode(authorization.refresh);
      if (moment.unix(jwtDecodeText1.exp) < moment()) {
        if (moment.unix(jwtDecodeText2.exp) < moment()) {
          yield put(actions.logout());
        } else {
          const response = yield call(api.auth.RefreshToken, { refresh: authorization.refresh });
          yield storage.setLocalStorageItem(LOCAL_STORAGE_KEY.authorization, {
            ...authorization,
            access: response.access,
          });
        }
      }
      // console.log('2')
    } else {
      storage.removeLocalStorage(LOCAL_STORAGE_KEY.authorization);
    }
  } catch (e) {
    console.log(e);
    if (e.data.meta) {
      storage.setLocalStorageItem(LOCAL_STORAGE_KEY.session, e.data.meta.session);
    }
    message.error(e.data.detail);
    yield put(actions.logout());
  }
}

function* getProfile({ api }) {
  try {
    // console.log('0')
    yield checkTokenExpiration({ api }); // checkTokenExpiration({api})
    yield delay(1000);
    const authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization);
    // console.log('3')
    // if (authorization) {
    //   const jwtDecodeText1 = jwtDecode(authorization.access)
    //   const jwtDecodeText2 = jwtDecode(authorization.refresh)
    //   if(moment.unix(jwtDecodeText1.exp) < moment()) {
    //     if (moment.unix(jwtDecodeText2.exp) < moment()) {
    //       yield put(actions.logout())
    //     } else {
    //       const response = yield call(api.auth.RefreshToken, {refresh: authorization.refresh })
    //       yield storage.setLocalStorageItem(LOCAL_STORAGE_KEY.authorization, {...authorization, access: response.access})
    //     }
    //   }
    // } else {
    //   yield put(actions.logout())
    // }
    console.log(authorization);
    if (authorization) {
      const response = yield call(api.auth.GetProfile);
      // console.log(response)
      yield put(actions.getProfileSuccess(response));
      if (_.get(response, "is_staff") || _.get(response, "is_admin")) {
        message.error(i18n.t("common:Only customer account can login to the booking site!!!"));
        yield put(actions.logout());
      }
    }
  } catch (e) {
    // message.error(e)
    yield put(actions.getProfileFail());
  }
}

function* updateProfile({ api }, { data }) {
  try {
    const response = yield call(api.auth.UpdateProfile, data);
    yield put(actions.getProfile());
    yield put(actions.updateProfileSuccess(response));
    history.goBack();
    message.success(i18n.t("Updated"));
  } catch (e) {
    yield put(actions.updateProfileFail());
  }
}

function* checkEmailExist({ api }, { data }) {
  try {
    const response = yield call(api.auth.CheckEmailExist, data);
    yield put(actions.checkEmailExistSuccess({ ...response, ...data }));
  } catch (e) {
    yield put(actions.checkEmailExistFail({ ...data }));
  }
}

function* requestResetPassword({ api }, { data }) {
  try {
    const response = yield call(api.auth.RequestResetPassword, data, i18n.language);
    message.success(i18n.t("common:Request Successful. Please check your email."));
    yield put(actions.requestResetPasswordSuccess(response));
  } catch (e) {
    message.error(i18n.t("common:Cannot found your email in our system. Please sign up first."));
    yield put(actions.requestResetPasswordFail());
  }
}

function* resetPassword({ api }, { data }) {
  try {
    const response = yield call(api.auth.ResetPassword, data);
    message.success(i18n.t("common:Reset password successful. Please login again"));
    history.push(paths.LoginPage);
    yield put(actions.resetPasswordSuccess(response));
  } catch (e) {
    message.error(get(e, "data.detail"));
    yield put(actions.resetPasswordFail(e.data));
  }
}

function* changePassword({ api }, { data }) {
  try {
    const response = yield call(api.auth.ChangePassword, data);
    message.success(i18n.t("common:Changed password successful."));
    // history.push(paths.LoginPage)
    yield put(actions.changePasswordSuccess(response));
  } catch (e) {
    message.error(get(e, "data.detail"));
    yield put(actions.changePasswordFail(e.data));
  }
}

function authSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(LOGIN, login, deps),
      takeLatest(REGISTER, register, deps),
      takeLatest(LOGIN_NOT_REDIRECT, loginNotRedirect, deps),
      takeLatest(RESEND_ACTIVATION_CODE, resendActivationCode, deps),
      takeLatest(ACTIVATE_USER, activateUser, deps),
      takeLatest(LOGOUT, logout, deps),
      takeLatest(CHECK_TOKEN_EXPIRATION, checkTokenExpiration, deps),
      takeLatest(GET_PROFILE, getProfile, deps),
      takeLatest(UPDATE_PROFILE, updateProfile, deps),
      takeLatest(CHECK_EMAIL_EXIST, checkEmailExist, deps),
      takeLatest(REQUEST_RESET_PASSWORD, requestResetPassword, deps),
      takeLatest(RESET_PASSWORD, resetPassword, deps),
      takeLatest(CHANGE_PASSWORD, changePassword, deps),
    ]);
  };
}

export default authSaga;
