import { Grid, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Form, Icon, Input, Radio, Select } from "antd";
import parse from "html-react-parser";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { register } from "../../../actions/auth";
import Image from "../../../assets/images/ecos_Stockholm_1.jpg";

import { history } from "../../../config/store";
import assets from "../../../constants/assets";
import { LogoImage as Logo } from "../../../constants/componentHelpers";
import * as PATH from "../../../constants/paths.constants";
import * as paths from "../../../constants/paths.constants";

import { getListAllCountries } from "../../../helpers/helpers";

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      marginTop: "50px",
      marginBottom: "30px",
      color: theme.colorAccent,
      width: "60%",
      cursor: "pointer",
      maxWidth: "350px",
    },
    background: {
      "& img": {
        height: "100%",
        // width: '100%'
      },
    },
    formRoot: {
      maxWidth: "550px",
      margin: "0 auto",
      textAlign: "left",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    formField: {
      "& .MuiInputBase-root": {
        // backgroundColor: 'white',
        borderRadius: "15px",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    textWelcome: {
      padding: "20px 0",
      color: theme.colorGray,
    },
    buttonGroup: {
      justifyContent: "center",
      "& button": {
        width: 300,
        margin: "25px",
      },
    },
    forgotPassword: {
      cursor: "pointer",
    },
    registerContent: {
      marginTop: "15px",
      marginBottom: "15px",
      textAlign: "center",
    },
    registerLink: {
      cursor: "pointer",
    },
    alignItemsCenter: {
      display: "flex",
      alignItems: "center",
    },
  };
});

const Register = (props) => {
  const classes = useStyles();
  const [accepted, setAccepted] = useState(false);

  const {
    form: { getFieldDecorator, validateFields, getFieldValue },
    register,
    isRegisting,
    t,
    checkingEmailResult,
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((error, values) => {
      if (!error && values.policy_confirmed) {
        console.log("Submiting..");

        const { confirmPassword, ...rest } = values;
        register({ ...rest });
      }
    });
  };

  const listCountries = getListAllCountries("en");

  const onFilter = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item md={7} sm={7} xs={7}>
        <Typography variant="h2" align="center" className={classes.aa}>
          <img
            src={Logo}
            className={classes.logo}
            onClick={() => history.push(paths.HomePage)}
          />
        </Typography>
        <Typography variant="h4" align="center">
          <span className="color-accent">{t("SignIn")}</span>{" "}
          <span className="color-gray">{t("or")}</span>{" "}
          <span className={classes.fontBold}>{t("Register")}</span>
        </Typography>
        <Typography variant="h5" align="center" className={classes.textWelcome}>
          {t("CreateAFreeAccount")}
        </Typography>
        <Form
          className={classes.formRoot}
          hideRequiredMark
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Grid container>
            <Grid item md={5} xs={12}>
              <Form.Item>
                {getFieldDecorator("first_name", {
                  rules: [
                    { required: true, message: t("PleaseInputYourFirstName") },
                  ],
                })(<Input placeholder={t("FirstName*")} />)}
              </Form.Item>
            </Grid>
            <Grid item md={1} xs={0}></Grid>
            <Grid item md={6} xs={12}>
              <Form.Item>
                {getFieldDecorator("last_name", {
                  rules: [
                    { required: true, message: t("PleaseInputYourLastName") },
                  ],
                })(<Input placeholder={t("LastName*")} />)}
              </Form.Item>
            </Grid>
            <Grid item md={12} xs={12}>
              <Form.Item>
                {getFieldDecorator("email", {
                  initialValue: get(checkingEmailResult, "email"),
                  rules: [
                    { required: true, message: t("PleaseInputYourEmail") },
                    {
                      type: "email",
                      message: t("PleaseInputCorrectYourEmail"),
                    },
                  ],
                })(<Input placeholder="Email *" />)}
              </Form.Item>
              <Form.Item
                label={t("AccountType")}
                className={classes.alignItemsCenter}
              >
                {getFieldDecorator("is_belong_to_company", {
                  initialValue: false,
                })(
                  <Radio.Group>
                    <Radio value={false}>{t("Private")}</Radio>
                    <Radio value={true}>{t("Company")}</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("company", {
                  rules: [
                    {
                      required: getFieldValue("is_belong_to_company"),
                      message: "Please input your company!",
                    },
                  ],
                })(
                  <Input
                    placeholder={`${t("CompanyName")}${
                      getFieldValue("is_belong_to_company")
                        ? " *"
                        : "(" + t("optional") + ")"
                    }`}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("street", {
                  rules: [
                    { required: true, message: t("PleaseInputYourStreet") },
                  ],
                })(<Input placeholder={t("Street*")} />)}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("house_nbr", {
                  rules: [
                    { required: true, message: t("PleaseInputYourHouseNr") },
                  ],
                })(<Input placeholder={t("HouseNr*")} />)}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("city", {
                  rules: [
                    { required: true, message: t("PleaseInputYourCity") },
                  ],
                })(<Input placeholder={t("City*")} />)}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("post_code", {
                  rules: [
                    { required: true, message: t("PleaseInputYourPostCode") },
                  ],
                })(<Input placeholder={t("PostCode*")} />)}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("country", {
                  rules: [
                    { required: true, message: t("PleaseInputYourCountry") },
                  ],
                })(
                  <Select
                    placeholder={t("Country*")}
                    showSearch
                    optionFilterProp="children"
                    // disabled={true}
                    filterOption={onFilter}
                  >
                    {listCountries.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator(
                  "phone",
                  {}
                )(<Input placeholder={t("Phone(optional)")} />)}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator(
                  "contact_person",
                  {}
                )(<Input placeholder={t("ContactPerson(optional)")} />)}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator(
                  "addition",
                  {}
                )(<Input placeholder={t("Addition(optional)")} />)}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: t("PleaseInputYourPassword") },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder={t("Password*")}
                    type="password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("confirmPassword", {
                  rules: [
                    {
                      required: true,
                      message: t("PleaseInputYourConfirmPassword"),
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (value !== getFieldValue("password")) {
                          callback(t("TwoPasswordsThatYouEnterIsInconsistent"));
                        }
                        return callback();
                      },
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder={t("ConfirmPassword*")}
                    type="password"
                  />
                )}
              </Form.Item>
            </Grid>

            <Grid item>
              <Form.Item>
                {getFieldDecorator("policy_confirmed", {
                  valuePropName: "checked",
                  rules: [
                    {
                      required: true,
                      message: t("Please accept the condition"),
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (!value) {
                          callback(t("Please accept the condition"));
                        }
                        return callback();
                      },
                    },
                  ],
                })(
                  <Checkbox onChange={(e) => setAccepted(!accepted)}>
                    {parse(
                      t("PolicyAcceptNotice")
                        .replace("{policy}", PATH.Privacy)
                        .replace("{agbs}", assets.AGB_PDF)
                    )}
                  </Checkbox>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("ad_accepted", {
                  valuePropName: "checked",
                })(<Checkbox>{t("AdAcceptNotice")}</Checkbox>)}
              </Form.Item>
            </Grid>

            <Grid item xs={12}>
              <Toolbar className={classes.buttonGroup}>
                <Form.Item>
                  <Button
                    autoFocus
                    type="primary"
                    htmlType="submit"
                    loading={isRegisting}
                    disabled={isRegisting || !accepted}
                  >
                    {t("SignUp")}
                  </Button>
                </Form.Item>
              </Toolbar>
            </Grid>
            <Grid item xs={12} className={classes.registerContent}>
              <Typography variant="body2">
                {t("AlreadyAMember")}{" "}
                <Link to={paths.LoginPage} className={classes.registertLink}>
                  {t("SignIn")}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </Grid>
      <Grid item md={5} sm={5} xs={5} className={classes.background}>
        <img src={Image} />
      </Grid>
    </Grid>
  );
};

Register.propTypes = {
  form: PropTypes.object,
  register: PropTypes.func,
};

Register.defaultProps = {
  isRegisting: false,
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      register,
    },
    dispatch
  );

const mapState = ({ authReducer }) => ({
  isRegisting: authReducer.isRegisting,
  checkingEmailResult: authReducer.checkingEmailResult,
});

export default Form.create({
  name: "registerForm",
})(connect(mapState, mapDispatch)(withTranslation("common")(Register)));
