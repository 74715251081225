import { Container, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";

const Introduction = ({ t }) => (
  <Container style={{ padding: "16px 64px" }} maxWidth="xl">
    <Grid>
      <Typography variant="h5">
        <b>
          <span style={{ color: "red" }}>{t("ePlaza office center")}</span>&nbsp;
          {t("Intro")}
        </b>
      </Typography>
    </Grid>
    <Grid container spacing={15}>
      <Grid style={{ paddingTop: 16, paddingRight: 10 }} xs={6} item>
        <Typography variant="subtitle1">
          <b>{t("Title1") + ": "}</b>
          {t("Intro1")}
        </Typography>
      </Grid>

      <Grid style={{ paddingTop: 16, paddingLeft: 10 }} xs={6}>
        <Typography variant="subtitle1">
          <b>{t("Title2") + ": "}</b>
          {t("Intro2")}
        </Typography>
      </Grid>

      <Grid style={{ paddingTop: 16, paddingRight: 10 }} xs={6}>
        <Typography variant="subtitle1">
          <b>{t("Title3") + ": "}</b>
          {t("Intro3")}
        </Typography>
      </Grid>
      <Grid style={{ paddingTop: 16, paddingLeft: 10 }} xs={6}>
        <Typography variant="subtitle1">
          <b>{t("Title4") + ": "}</b>
          {t("Intro4")}
        </Typography>
      </Grid>
      <Grid style={{ paddingTop: 16, paddingRight: 10 }} xs={6}>
        <Typography variant="subtitle1">
          <b>{t("Title5") + ": "}</b>
          {t("Intro5")}
        </Typography>
      </Grid>
    </Grid>
    <Grid
      container
      style={{ paddingTop: 32, color: "red", fontSize: 20, fontWeight: 600 }}
      justify="center"
      alignItems="center"
      className="text-center">
      {t("Intro6")}
    </Grid>

    <Grid style={{ paddingTop: 32 }}>
      <Divider />
    </Grid>
  </Container>
);

export default withTranslation("common")(Introduction);
