export const GetSession = () => "/get_configuration";

export const CheckEmailExist = () => "/auth/check_exists";
export const Login = () => "/auth/jwt/create";
export const RequestResetPassword = () => "/auth/users/reset_password/";
export const ResetPassword = () => "/auth/users/reset_password_confirm/";
export const ChangePassword = () => "/auth/users/set_password/";

export const RefreshToken = () => "/auth/jwt/refresh";
export const SignUp = () => "/auth/users/";
export const ActivateAccount = () => "/auth/users/activation/";
export const ResendActivation = () => "/auth/users/resend_activation/";
export const GetProfile = () => "/auth/users/me/";
export const UpdateProfile = () => "/auth/users/me/";

export const GetRoomTypes = () => "/room-types";
export const GetListExtraFacilityCategories = () => "/ex-facility-categories";
export const GetListCaterings = () => "/booking-session/find_available_caterings";
export const GetListPermanentFacilities = () => "/permanent-facilities";
export const GetConfiguration = () => "/get_configuration";

// search available room
export const SearchAvailableRoom = () => "/booking-session/find_a_room_and_create_booking_session";

//Search available extra facilities
export const SearchExtraFacilities = () => "/booking-session/find_available_exfacilities";
export const AddExtraFacilities = () => "/booking-session/add_session_exfacility_items";

// Search available catering
export const SearchAvailableCaterings = () => "/booking-session/find_available_caterings";
export const AddCatering = () => "/booking-session/add_session_catering_items";
export const RetrieveItemPricings = () => "/booking-session/retrieve_item_pricings";

//Booking room: attach customer id to booking session
export const AttachSessionCustomer = () => "/booking-session/attach_session_customer";

// Payment
export const LinkSessionPayment = () => "/booking-session/link_session_payment";
export const ConfirmBooking = () => "/booking-session/confirm_booking";

export const PrintInvoice = () => "/booking-session/download_invoice_pdf";

export const UpdateBookingState = () => "/booking-session/update_session_info";

export const RemoveSessionItems = () => "/booking-session/remove_session_items";

export const GetBookingSession = () => "/booking-session/retrieve_booking_session";

export const DestroyBookingSession = () => "/booking-session/destroy_booking_session";

export const GetBookings = () => "/bookings";

export const SendContactRequest = () => "/contacts";

export const GetListHolidays = () => "/list_holidays";
