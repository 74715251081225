import * as _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import { MY_BOOKING_UPCOMING_TAB } from "../../constants/booking";
import { PAYMENT_METHOD } from "../../constants/bookingSession";

import * as helpers from "../../helpers/bookings";
import { formatMoney } from "../../helpers/bookings";

export const formatCurrency = (pricing) => `€ ${((pricing || 0) / 100).toFixed(2)}`;

const getReducer = ({ booking }) => booking.myBooking;

export const getCurrentPage = createSelector([getReducer], ({ currentPage }) => currentPage);

export const getPagination = createSelector(
  [getReducer],
  ({ bookings: { page_size, count }, currentPage }) => {
    return { total: count, pageSize: page_size, currentPage };
  },
);

export const getActivatedView = createSelector([getReducer], ({ activatedView }) => activatedView);

export const isFetchingBookings = createSelector(
  [getReducer],
  ({ fetchingBookings }) => fetchingBookings,
);

export const getItems = createSelector([getReducer], ({ bookings: { results } }) => {
  return _.map(results, (item) => {
    const startDate = moment(_.get(item, "start_date")).format("DD.MM.YYYY");
    const startTime =
      _.indexOf(_.padStart(_.get(item, "start_slot_int", 0).toString(2), 14, "0"), "1") + 8;

    const endDate = moment(_.get(item, "end_date")).format("DD.MM.YYYY");
    const endTime =
      _.lastIndexOf(_.padStart(_.get(item, "end_slot_int", 0).toString(2), 14, "0"), "1") + 9;

    const confirmNo = `${_.get(item, "confirmation_prefix")}${_.padStart(
      _.get(item, "id"),
      4,
      "0",
    )}`;

    let paymentStatus = helpers.getPaymentStatusTag(_.get(item, "payment_status"));
    if (_.get(item, "payment_method") === PAYMENT_METHOD.INVOICE) {
      paymentStatus = helpers.getPaymentMethodTag(PAYMENT_METHOD.INVOICE);
    }

    return {
      id: _.get(item, "id"),
      startAt: `${startDate}, ${_.padStart(startTime, 2, "0")}:00`,
      endAt: `${endDate}, ${_.padStart(endTime, 2, "0")}:00`,
      confirmNo,
      participants: _.get(item, "participants"),
      price: formatMoney(_.get(item, "price")),
      roomType: _.get(item, "room_type"),
      paymentStatus: paymentStatus,
      bookingStatus: helpers.getBookingStatusTag(_.get(item, "status")),
    };
  });
});

export const getRequestPayload = createSelector(
  [getCurrentPage, getActivatedView],
  (currentPage, activatedView) => {
    return {
      page: currentPage,
      page_size: 10,
      incoming: activatedView === MY_BOOKING_UPCOMING_TAB,
    };
  },
);
