import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";

import { myBooking as actions } from "../../actions/booking";

import BookingDetail from "../../components/MyBooking/BookingDetail";

import * as selector from "../../selectors/booking/bookingDetail";

class BookingDetailContainer extends Component {
  componentDidMount() {
    const { bookingId } = this.props.match.params;
    this.props.loadData(bookingId);
  }

  render() {
    return <BookingDetail {...this.props} />;
  }
}

const mapState = (state) => ({
  confirmationNo: selector.getConfirmationNo(state),
  invoiceHtml: selector.getInvoiceHtml(state),
  invoiceUrl: selector.getInvoicePdfUrl(state),
  isFetching: selector.isFetching(state),
  isLoadingInvoiceHtml: selector.isLoadingInvoice(state),
  paymentStatus: selector.getPaymentStatus(state),
  bookingStatus: selector.getBookingStatus(state),
  paymentMethod: selector.getPaymentMethod(state),

  isCancelling: selector.isCancelling(state),
  canCancelBooking: selector.canCancelBooking(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      loadData: actions.retrieveBookingDetail,
      onCancelBooking: actions.cancelBooking,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(withRouter(BookingDetailContainer));
