import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import ContactFloatButton from "../../components/Booking/components/ContactFloatButton";

const ButtonContainer = (props) => {
  return <ContactFloatButton {...props} />;
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      onClick: actions.showContactForm,
    },
    dispatch,
  );

export default connect(null, mapDispatch)(ButtonContainer);
