export const HomePage = "/";
export const LoginPage = "/login";
export const RegisterPage = "/register";
export const ForgotPasswordPage = "/forgotPassword";
export const Verifypage = "/verify";
export const ResetPasswordPage = "/resetPassword";
export const ChangePassword = "/accountSetting";
export const BookingPage = "/booking";
export const ProfilePage = "/profile";
export const MyBookingPage = "/my-booking";
export const ActivatePage = "/activate";
export const Imprint = "/imprint";
export const Privacy = "/privacy";
