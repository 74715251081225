import { createSelector } from "reselect";
import * as _ from "lodash";

import { BOOKING_STATUS, PAYMENT_STATUS } from "../../constants/booking";
import { PAYMENT_METHOD } from "../../constants/bookingSession";

const getReducer = ({ booking }) => booking.detail;

const getBookingStatusText = (status) => {
  switch (status) {
    case BOOKING_STATUS.CANCELLED:
      return "CANCELLED";
    default:
      return "SCHEDULED";
  }
};

const getPaymentStatusText = (status) => {
  switch (status) {
    case PAYMENT_STATUS.DONE:
      return "DONE";
    case PAYMENT_STATUS.FAILED:
      return "FAILED";
    case PAYMENT_STATUS.CANCELLED:
      return "CANCELLED";
    case PAYMENT_STATUS.PARTIAL_REFUND:
      return "PARTIAL_REFUND";
    case PAYMENT_STATUS.FULL_REFUND:
      return "FULL_REFUND";
    default:
      return "OUTSTANDING";
  }
};

const getPaymentMethodText = (method) => {
  switch (method) {
    case PAYMENT_METHOD.STRIPE:
      return "CREDIT CARD";
    case PAYMENT_METHOD.INVOICE:
      return "INVOICE";
    default:
      return "INVOICE";
  }
};

export const getConfirmationNo = createSelector(
  [getReducer],
  ({ data: { confirmation_prefix, id } }) => {
    return `${confirmation_prefix}${_.padStart(id, 4, "0")}`;
  },
);

export const getBookingStatus = createSelector([getReducer], ({ data: { status } }) =>
  getBookingStatusText(status),
);

export const getPaymentStatus = createSelector(
  [getReducer],
  ({ data: { payment_status, payment_method } }) => {
    if (payment_method === PAYMENT_METHOD.INVOICE) {
      return "INVOICE";
    }
    return getPaymentStatusText(payment_status);
  },
);

export const getPaymentMethod = createSelector([getReducer], ({ data: { payment_method } }) =>
  getPaymentMethodText(payment_method),
);

export const isFetching = createSelector([getReducer], ({ isFetching }) => isFetching);

export const isLoadingInvoice = createSelector(
  [getReducer],
  ({ isLoadingInvoiceHtml }) => isLoadingInvoiceHtml,
);

export const getInvoiceHtml = createSelector([getReducer], ({ invoice: { html } }) => html);

export const getInvoicePdfUrl = createSelector([getReducer], ({ invoice: { pdfUrl } }) => pdfUrl);

export const isCancelling = createSelector([getReducer], ({ isCancelling }) => isCancelling);

export const canCancelBooking = createSelector([getReducer], ({ data: { cancelable, status } }) => {
  return status === BOOKING_STATUS.SCHEDULED && cancelable;
});

export const generateCancelBookingPayload = createSelector([getReducer], ({ data: { id } }) => ({
  booking_id: id,
}));
