import jwtDecode from "jwt-decode";
import moment from "moment";
import { combineReducers } from "redux";

import * as actionTypes from "../actionTypes/auth.actionTypes";

import { LOCAL_STORAGE_KEY } from "../helpers/constant";

import * as storage from "../services/storage";

const token = (storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization) || {}).refresh || null;

const isLogined = (
  state = token ? moment.unix(jwtDecode(token).exp) > moment() : false,
  action,
) => {
  const { type } = action;
  switch (type) {
    case actionTypes.LOGIN_SUCCESS:
      return true;
    case actionTypes.LOGOUT:
      return false;
    default:
      return state;
  }
};

const isRegisting = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.REGISTER:
      return true;
    case actionTypes.REGISTER_SUCCESS:
      return false;
    case actionTypes.REGISTER_FAIL:
      return false;
    default:
      return state;
  }
};

const isResending = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.RESEND_ACTIVATION_CODE:
      return true;
    case actionTypes.RESEND_ACTIVATION_CODE_FAIL:
      return false;
    case actionTypes.RESEND_ACTIVATION_CODE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isLogining = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.LOGIN:
      return true;
    case actionTypes.LOGIN_NOT_REDIRECT:
      return true;
    case actionTypes.LOGIN_FAIL:
      return false;
    case actionTypes.LOGIN_SUCCESS:
      return false;
    default:
      return state;
  }
};

const userProfile = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.GET_PROFILE_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const isUpdatingProfile = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.UPDATE_PROFILE:
      return true;
    case actionTypes.UPDATE_PROFILE_FAIL:
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return true;
    default:
      return state;
  }
};

const isCheckingEmail = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.CHECK_EMAIL_EXIST:
      return true;
    case actionTypes.CHECK_EMAIL_EXIST_FAIL:
    case actionTypes.CHECK_EMAIL_EXIST_SUCCESS:
      return false;
    default:
      return state;
  }
};

const checkingEmailResult = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.CHECK_EMAIL_EXIST_SUCCESS:
      return action.data;
    case actionTypes.RESET_CHECKING_EMAIL_RESULT:
      return {};
    default:
      return state;
  }
};

const isRequestingResetPassword = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.REQUEST_RESET_PASSWORD:
      return true;
    case actionTypes.REQUEST_RESET_PASSWORD_FAIL:
    case actionTypes.REQUEST_RESET_PASSWORD_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isResetingPassword = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.RESET_PASSWORD:
      return true;
    case actionTypes.RESET_PASSWORD_FAIL:
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return false;
    default:
      return state;
  }
};

const resetPasswordError = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.RESET_PASSWORD_FAIL:
      return action.data;
    default:
      return state;
  }
};

const isChangingPassword = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.CHANGE_PASSWORD:
      return true;
    case actionTypes.CHANGE_PASSWORD_FAIL:
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return false;
    default:
      return state;
  }
};

const changePasswordError = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return action.data;
    default:
      return state;
  }
};

const authReducer = combineReducers({
  isCheckingEmail,
  checkingEmailResult,
  isLogined,
  isRegisting,
  isResending,
  isLogining,
  userProfile,
  isUpdatingProfile,
  isRequestingResetPassword,
  isResetingPassword,
  resetPasswordError,
  isChangingPassword,
  changePasswordError,
});

export default authReducer;
