import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { Modal } from "antd";
import { getImage } from "../../helpers/helpers";
import { withTranslation } from "react-i18next";
import * as _ from "lodash";
import parse from "html-react-parser";

const useStyles = makeStyles(() => ({
  roomImageGrid: {
    overflow: "hidden",
    "& img": {
      height: "500px",
    },
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  roomInfoContent: {
    padding: "40px",
  },
  priceContent: {
    borderTop: "1px solid #ddd",
    justifyContent: "space-between",
    position: "relative",
    bottom: "-225px",
  },
  priceText: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "20px",
    color: "#cb4c4d",
  },
  roomName: {
    color: "#cb4c4d",
  },
  buttonGroup: {
    position: "relative",
    bottom: "-200px",
    right: "-300px",
    width: "max-content",
  },
  detailInfo: {
    lineHeight: "25px",
    "& p": {
      marginBottom: 0,
    },
  },
}));

function ViewRoomModal(props) {
  const { isOpen, closeModal, roomTypeSelected, offerRoom, t } = props;

  const classes = useStyles();

  return (
    <Modal
      // title="Basic Modal"
      visible={isOpen}
      // onOk={this.handleOk}
      onCancel={closeModal}
      footer={[
        <Button
          autoFocus
          onClick={() => offerRoom(roomTypeSelected)}
          color="primary"
          variant="contained">
          {t("65")}
        </Button>,
      ]}
      width={1000}>
      <Grid container>
        <Grid item sm={5} className={classes.roomImageGrid}>
          <img
            src={_.get(roomTypeSelected, "images[0].url", getImage(roomTypeSelected.id))}
            alt=""
          />
        </Grid>
        <Grid item sm={7} className={classes.roomInfoContent}>
          <Typography className={classes.roomName} variant="h5" align="left">
            {roomTypeSelected.room_type}
          </Typography>
          <Typography variant="h6" align="left">
            {t("36")}
          </Typography>
          <Typography variant="body2" align="left" className={classes.detailInfo}>
            {parse((roomTypeSelected.description || "").replace(/\n/gi, "<br />"))}
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
}

ViewRoomModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  offerRoom: PropTypes.func,
  roomTypeSelected: PropTypes.object,
};

export default withTranslation("common")(ViewRoomModal);
