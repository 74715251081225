import React from "react";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import EventIcon from "@material-ui/icons/Event";
import { withTranslation } from "react-i18next";

const ContinueBookingButton = ({ t, onClick, isProcessing }) => (
  <Fab
    variant="extended"
    color="primary"
    aria-label="Continue booking"
    onClick={onClick}
    style={{ position: "fixed", bottom: "16px", right: "192px" }}
    disabled={isProcessing}>
    {isProcessing ? <CircularProgress size={18} /> : <EventIcon />}&nbsp;
    {t("CONTINUE BOOKING")}
  </Fab>
);

export default withTranslation("booking")(ContinueBookingButton);
