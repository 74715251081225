import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import Stage2 from "../../components/Booking/Stage2";

import * as sessionSelector from "../../selectors/bookingSession";
import * as selector from "../../selectors/bookingSession/stage2";

const Stage2Container = (props) => {
  return <Stage2 {...props} />;
};

const mapState = (state) => ({
  bookingDate: sessionSelector.getBookingDate(state),
  participants: sessionSelector.getBookingParticipants(state),
  customer: sessionSelector.getCustomer(state),
  items: selector.getItemsBeautify(state),
  subTotal: selector.getTotalBeautify(state),
  tax: selector.getTaxBeautify(state),
  taxRate: selector.getTaxRateBeautify(state),
  grandTotal: selector.getGrandTotalBeautify(state),
  additionalRequirement: selector.getAdditionalRequirement(state),

  isNextSubmitting: selector.isNextSubmitting(state),
  isCancelSubmitting: selector.isCancelSubmitting(state),
  isBackSubmitting: selector.isBackSubmitting(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      onBackClick: actions.stage2BackClick,
      onNextClick: actions.stage2NextClick,
      onCancelClick: actions.stage2CancelClick,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(Stage2Container);
