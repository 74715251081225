import React, { Component } from "react";

import ResetPasswordComponent from "../../../components/Auth/ResetPassword";
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResetSuccessful: false,
    };
  }

  resetPassword = () => {
    this.setState({ isResetSuccessful: true });
  };

  render() {
    const { isResetSuccessful } = this.state;

    return (
      <div>
        <ResetPasswordComponent
          resetPassword={() => this.resetPassword()}
          resetSuccessful={isResetSuccessful}
        />
      </div>
    );
  }
}

export default ResetPassword;
