import Holidays from "date-holidays";
import * as _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import { ITEM_TYPE } from "../../constants/bookingSession";

import * as storage from "../../services/storage";

const hd = new Holidays("DE", "BW");
const isWeekend = (date) => date.isoWeekday() >= 6;
const isHoliday = (date) => {
  const hld = hd.isHoliday(date);
  return hld;
};

const getReducer = ({ bookingSession }) => bookingSession;

const getLocale = ({ publicReducer: { locale } }) => _.get(locale, "lang", "en");

export const getSessionData = ({ bookingSession: { sessionData } }) => {
  return sessionData;
};

export const getStage = createSelector([getReducer], ({ sessionData }) => {
  return _.get(sessionData, "stage", 0);
});

export const getBookingDate = createSelector([getSessionData, getLocale], ({ items }, locale) => {
  moment.locale(locale);

  const roomItems = _.sortBy(_.filter(items, { item_type: ITEM_TYPE.ROOM }), ["date"]);
  const startAt = _.pick(_.first(roomItems), ["date", "timeslots"]);
  const startTime =
    _.indexOf(_.padStart(_.get(startAt, "timeslots", 0).toString(2), 14, "0"), "1") + 8;

  const endAt = _.pick(_.last(roomItems), ["date", "timeslots"]);
  const endTime =
    _.lastIndexOf(_.padStart(_.get(endAt, "timeslots", 0).toString(2), 14, "0"), "1") + 9;

  const result = {
    startDate: moment(startAt.date).format("DD.MM.YYYY"),
    startTime: `${_.padStart(startTime, 2, "0")}:00`,

    endDate: moment(endAt.date).format("DD.MM.YYYY"),
    endTime: `${_.padStart(endTime, 2, "0")}:00`,
  };
  return result;
});

export const getBookingParticipants = createSelector(
  [getSessionData],
  ({ participants }) => participants,
);

export const getCustomer = createSelector([getSessionData], ({ customer }) => {
  return {
    ..._.pick(customer, ["email", "company", "department"]),
    fullname: `${_.get(customer, "first_name")} ${_.get(customer, "last_name")}`,
    address: `${_.get(customer, "street", "")} ${_.get(customer, "house_nbr", "")}, ${_.get(
      customer,
      "city",
      "",
    )}, ${_.get(customer, "country", "")}`,
  };
});

export const getFreeCancelDate = createSelector([getSessionData], ({ items }) => {
  const roomItems = _.sortBy(_.filter(items, { item_type: ITEM_TYPE.ROOM }), ["date"]);
  const room = _.first(roomItems);

  const freeCancelDays = _.get(room, "item.free_cancel_days");
  let startAt = moment(_.get(room, "date"));
  let count = 0;
  while (count < freeCancelDays) {
    startAt = startAt.add(-1, "days");
    if (isWeekend(startAt) || isHoliday(startAt)) {
      continue;
    }
    count += 1;
  }
  return startAt.format("DD.MM.YYYY");
});

export const hasContinueBooking = () => {
  const session = storage.getLocalStorageItem("booking");
  return !!_.get(session, "id");
};

export const isBookingProcessContinuing = createSelector(
  [getReducer],
  ({ isBookingProcessContinuing }) => isBookingProcessContinuing,
);
