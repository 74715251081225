export const GET_LIST_ROOM_TYPES = "GET_LIST_ROOM_TYPES";
export const GET_LIST_ROOM_TYPES_SUCCESS = "GET_LIST_ROOM_TYPES_SUCCESS";
export const GET_LIST_ROOM_TYPES_FAIL = "GET_LIST_ROOM_TYPES_FAIL";

export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAIL = "GET_CONFIGURATION_FAIL";

export const SEARCH_ROOM = "SEARCH_ROOM";
export const SEARCH_ROOM_SUCCESS = "SEARCH_ROOM_SUCCESS";
export const SEARCH_ROOM_FAIL = "SEARCH_ROOM_FAIL";

export const GET_LIST_PERMANENT_FACILITIES = "GET_LIST_PERMANENT_FACILITIES";
export const GET_LIST_PERMANENT_FACILITIES_SUCCESS = "GET_LIST_PERMANENT_FACILITIES_SUCCESS";
export const GET_LIST_PERMANENT_FACILITIES_FAIL = "GET_LIST_PERMANENT_FACILITIES_FAIL";

export const GET_LIST_EXTRA_FACILITIES_CATEGORIES = "GET_LIST_EXTRA_FACILITIES_CATEGORIES";
export const GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS =
  "GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS";
export const GET_LIST_EXTRA_FACILITIES_CATEGORIES_FAIL =
  "GET_LIST_EXTRA_FACILITIES_CATEGORIES_FAIL";

export const GET_LIST_CATERINGS = "GET_LIST_CATERINGS";
export const GET_LIST_CATERINGS_SUCCESS = "GET_LIST_CATERINGS_SUCCESS";
export const GET_LIST_CATERINGS_FAIL = "GET_LIST_CATERINGS_FAIL";

export const SEARCH_AVAILABLE_EXTRA_FACILITIES = "SEARCH_AVAILABLE_EXTRA_FACILITIES";
export const SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS =
  "SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS";
export const SEARCH_AVAILABLE_EXTRA_FACILITIES_FAIL = "SEARCH_AVAILABLE_EXTRA_FACILITIES_FAIL";

export const ADD_EXTRA_FACILITY = "ADD_EXTRA_FACILITY";
export const ADD_EXTRA_FACILITY_SUCCESS = "ADD_EXTRA_FACILITY_SUCCESS";
export const ADD_EXTRA_FACILITY_FAIL = "ADD_EXTRA_FACILITY_FAIL";

export const REMOVE_EXTRA_FACILITY = "REMOVE_EXTRA_FACILITY";
export const REMOVE_EXTRA_FACILITY_SUCCESS = "REMOVE_EXTRA_FACILITY_SUCCESS";
export const REMOVE_EXTRA_FACILITY_FAIL = "REMOVE_EXTRA_FACILITY_FAIL";

export const GET_BOOKING_SESSIONS = "GET_BOOKING_SESSIONS";
export const GET_BOOKING_SESSIONS_SUCCESS = "GET_BOOKING_SESSIONS_SUCCESS";
export const GET_BOOKING_SESSIONS_FAIL = "GET_BOOKING_SESSIONS_FAIL";

export const GET_BOOKING = "GET_BOOKING";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_FAIL = "GET_BOOKING_FAIL";

export const UPDATE_BOOKING_SESSION = "UPDATE_BOOKING_SESSION";
export const UPDATE_BOOKING_SESSION_SUCCESS = "UPDATE_BOOKING_SESSION_SUCCESS";
export const UPDATE_BOOKING_SESSION_FAIL = "UPDATE_BOOKING_SESSION_FAIL";

export const SEARCH_AVAILABLE_CATERINGS = "SEARCH_AVAILABLE_CATERINGS";
export const SEARCH_AVAILABLE_CATERINGS_SUCCESS = "SEARCH_AVAILABLE_CATERINGS_SUCCESS";
export const SEARCH_AVAILABLE_CATERINGS_FAIL = "SEARCH_AVAILABLE_CATERINGS_FAIL";

export const ADD_SESSION_CATERING = "ADD_SESSION_CATERING";
export const ADD_SESSION_CATERING_SUCCESS = "ADD_SESSION_CATERING_SUCCESS";
export const ADD_SESSION_CATERING_FAIL = "ADD_SESSION_CATERING_FAIL";

export const REMOVE_SESSION_ITEMS = "REMOVE_SESSION_ITEMS";
export const REMOVE_SESSION_ITEMS_SUCCESS = "REMOVE_SESSION_ITEMS_SUCCESS";
export const REMOVE_SESSION_ITEMS_FAIL = "REMOVE_SESSION_ITEMS_FAIL";

export const ATTACH_SESSION_CUSTOMER = "ATTACH_SESSION_CUSTOMER";
export const ATTACH_SESSION_CUSTOMER_SUCCESS = "ATTACH_SESSION_CUSTOMER_SUCCESS";
export const ATTACH_SESSION_CUSTOMER_FAIL = "ATTACH_SESSION_CUSTOMER_FAIL";

export const PRINT_INVOICE = "PRINT_INVOICE";
export const PRINT_INVOICE_SUCCESS = "PRINT_INVOICE_SUCCESS";
export const PRINT_INVOICE_FAIL = "PRINT_INVOICE_FAIL";

export const UPDATE_BOOKING_STATE = "UPDATE_BOOKING_STATE";
export const UPDATE_BOOKING_STATE_SUCCESS = "UPDATE_BOOKING_STATE_SUCCESS";
export const UPDATE_BOOKING_STATE_FAIL = "UPDATE_BOOKING_STATE_FAIL";

export const LINK_SESSION_PAYMENT = "LINK_SESSION_PAYMENT";
export const LINK_SESSION_PAYMENT_SUCCESS = "LINK_SESSION_PAYMENT_SUCCESS";
export const LINK_SESSION_PAYMENT_FAIL = "LINK_SESSION_PAYMENT_FAIL";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_FAIL = "CONFIRM_BOOKING_FAIL";

export const DESTROY_BOOKING_SESSION = "DESTROY_BOOKING_SESSION";
export const DESTROY_BOOKING_SESSION_SUCCESS = "DESTROY_BOOKING_SESSION_SUCCESS";
export const DESTROY_BOOKING_SESSION_FAIL = "DESTROY_BOOKING_SESSION_FAIL";

export const SEND_CONTACT_REQUEST = "SEND_CONTACT_REQUEST";
export const SEND_CONTACT_REQUEST_SUCCESS = "SEND_CONTACT_REQUEST_SUCCESS";
export const SEND_CONTACT_REQUEST_FAIL = "SEND_CONTACT_REQUEST_FAIL";

export const BOOKING_ROOM = "BOOKING_ROOM";

export const STORE_SEARCH_DATA = "STORE_SEARCH_DATA";

export const REMOVE_BOOKING_SESSION = "REMOVE_BOOKING_SESSION";

export const RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS = "RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS";
export const RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS =
  "RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS";
export const RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_FAIL =
  "RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_FAIL";

export const RETRIEVE_CATERING_ITEM_PRICINGS = "RETRIEVE_CATERING_ITEM_PRICINGS";
export const RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS = "RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS";
export const RETRIEVE_CATERING_ITEM_PRICINGS_FAIL = "RETRIEVE_CATERING_ITEM_PRICINGS_FAIL";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_LANGUAGE_SUCCESS = "CHANGE_LANGUAGE_SUCCESS";

export const GET_LIST_HOLIDAY = "GET_LIST_HOLIDAY";
export const GET_LIST_HOLIDAY_SUCCESS = "GET_LIST_HOLIDAY_SUCCESS";
export const GET_LIST_HOLIDAY_FAIL = "GET_LIST_HOLIDAY_FAIL";
