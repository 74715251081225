/* eslint-disable camelcase */
import common_en from './en/common.json'
import common_de from './de/common.json'
import booking_stage_0_de from './de/booking_stage_0.json';
import booking_stage_0_en from './en/booking_stage_0.json';
import booking_de from './de/booking.json'
import booking_en from './en/booking.json'
import booking_stage_1_de from './de/booking_stage_1.json'
import booking_stage_1_en from './en/booking_stage_1.json'
import booking_stage_2_de from './de/booking_stage_2.json'
import booking_stage_2_en from './en/booking_stage_2.json'

export default {
  en: {
    common: common_en,
    booking: {...common_en, ...booking_en},
    booking_stage_0: {...common_en, ...booking_stage_0_en},
    booking_stage_1: booking_stage_1_en,
    booking_stage_2: booking_stage_2_en,
  },
  de: {
    common: common_de,
    booking: {...common_de, ...booking_de},
    booking_stage_0: booking_stage_0_de,
    booking_stage_1: booking_stage_1_de,
    booking_stage_2: booking_stage_2_de,
  },
}
