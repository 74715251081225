import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Modal } from "antd";
import * as _ from "lodash";
import moment from "moment";
import React from "react";
import DateRangePicker from "react-daterange-picker";
import { withTranslation } from "react-i18next";

import { BOOK_FOR } from "../../constants/bookingSession";

import "react-daterange-picker/dist/css/react-calendar.css";

require("moment/locale/de");

moment.locale("en");

const SearchRoomModal = ({
  t,
  locale = "en",
  isShowing,
  onClose,
  onSubmit,
  onContact,
  isSubmitting,
  canSubmit,

  bookingType,
  onChangeBookingType,

  participantNumber,
  maxParticipants,
  onChangeParticipantNumber,

  minimumDate,
  selectionType,
  numberOfCalendars,

  dateRange,
  onBookingStartDateChange,
  onBookingEndDateChange,

  selectableStartTimes,
  startTime,
  onChangeStartTime,

  selectableEndTimes,
  endTime,
  onChangeEndTime,

  hasPresentationDevice,
  onChangePresentationDevice,
}) => (
  <Modal
    title={t("Find your perfect meeting room")}
    visible={isShowing}
    onCancel={onClose}
    width={360 * numberOfCalendars}
    footer={[
      <Button variant="outlined" onClick={onContact}>
        {t("Contact")}
      </Button>,
      <Button
        variant="contained"
        disabled={isSubmitting || !canSubmit}
        onClick={onSubmit}
        color="primary">
        {!isSubmitting ? (
          t("Search")
        ) : (
          <span>
            <CircularProgress size={16} /> {t("Searching...")}
          </span>
        )}
      </Button>,
    ]}>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          error={participantNumber > maxParticipants}
          helperText={t("Max {number} people").replace("{number}", maxParticipants)}
          label={t("Number of participants")}
          type="number"
          value={participantNumber}
          disabled={isSubmitting}
          onChange={(e) => onChangeParticipantNumber(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined">
          <InputLabel style={{ backgroundColor: "white", paddingLeft: 4, paddingRight: 4 }}>
            {t("Book for")}
          </InputLabel>
          <Select
            value={bookingType}
            disabled={isSubmitting}
            onChange={(e) => onChangeBookingType(e.target.value)}>
            <MenuItem value={BOOK_FOR.SINGLE_DAY}>{t("Single day")}</MenuItem>
            <MenuItem value={BOOK_FOR.MULTIPLE_DAY}>{t("Multiple days")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    <Grid container justify="center" style={{ marginTop: 16 }}>
      <Grid item xs={12} justify="center" style={{ textAlign: "center" }}>
        <DateRangePicker
          locale={locale}
          firstOfWeek={1}
          numberOfCalendars={numberOfCalendars}
          minimumDate={minimumDate}
          selectionType={selectionType}
          value={dateRange}
          disabled={isSubmitting}
          onSelect={(value) => {
            onBookingStartDateChange(_.get(value, "start", value));
            if (_.get(value, "end")) {
              onBookingEndDateChange(_.get(value, "end"));
            }
          }}
        />
      </Grid>
    </Grid>
    {bookingType === BOOK_FOR.SINGLE_DAY && (
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        <Grid item xs={6}>
          <FormControl variant="outlined">
            <InputLabel style={{ backgroundColor: "white", paddingLeft: 4, paddingRight: 4 }}>
              {t("Start time")}
            </InputLabel>
            <Select
              value={startTime}
              disabled={isSubmitting}
              onChange={(e) => onChangeStartTime(e.target.value)}>
              {_.map(selectableStartTimes, (item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined">
            <InputLabel style={{ backgroundColor: "white", paddingLeft: 4, paddingRight: 4 }}>
              {t("End time")}
            </InputLabel>
            <Select
              disabled={isSubmitting}
              value={endTime}
              onChange={(e) => onChangeEndTime(e.target.value)}>
              {_.map(selectableEndTimes, (item) => (
                <MenuItem disabled={item.disabled} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )}
    <Grid container style={{ marginTop: 16 }}>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isSubmitting}
                checked={hasPresentationDevice}
                onChange={(e) => onChangePresentationDevice(e.target.value)}
                name="presentationRequired"
              />
            }
            label={t("Presentation device required")}
          />
        </FormGroup>
      </Grid>
    </Grid>
  </Modal>
);

export default withTranslation("booking")(SearchRoomModal);
