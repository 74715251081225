import {
  ACTIVATE_USER,
  ACTIVATE_USER_FAIL,
  ACTIVATE_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_NOT_REDIRECT,
  LOGOUT,
  REGISTER,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESEND_ACTIVATION_CODE,
  RESEND_ACTIVATION_CODE_FAIL,
  RESEND_ACTIVATION_CODE_SUCCESS,
  CHECK_TOKEN_EXPIRATION,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  CHECK_EMAIL_EXIST,
  CHECK_EMAIL_EXIST_FAIL,
  CHECK_EMAIL_EXIST_SUCCESS,
  RESET_CHECKING_EMAIL_RESULT,
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_FAIL,
  REQUEST_RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
} from "../actionTypes/auth.actionTypes";

export function checkEmailExist(data) {
  return { type: CHECK_EMAIL_EXIST, data };
}

export function checkEmailExistFail(data) {
  return { type: CHECK_EMAIL_EXIST_FAIL, data };
}

export function checkEmailExistSuccess(data) {
  return { type: CHECK_EMAIL_EXIST_SUCCESS, data };
}

export function login(data) {
  return { type: LOGIN, data };
}

export function loginSuccess(data) {
  return { type: LOGIN_SUCCESS, data };
}

export function loginFail(error) {
  return { type: LOGIN_FAIL, error };
}

export function loginNotRedirect(data) {
  return { type: LOGIN_NOT_REDIRECT, data };
}

export function register(data) {
  return { type: REGISTER, data };
}

export function registerSuccess() {
  return { type: REGISTER_SUCCESS };
}

export function registerFail(error) {
  return { type: REGISTER_FAIL, error };
}

export function activateUser(data) {
  return { type: ACTIVATE_USER, data };
}

export function activateUserSuccess(data) {
  return { type: ACTIVATE_USER_SUCCESS, data };
}

export function activateUserFail(error) {
  return { type: ACTIVATE_USER_FAIL, error };
}

export function resendActivationCode(data) {
  return { type: RESEND_ACTIVATION_CODE, data };
}

export function resendActivationCodeSuccess() {
  return { type: RESEND_ACTIVATION_CODE_SUCCESS };
}

export function resendActivationCodeFail(error) {
  return { type: RESEND_ACTIVATION_CODE_FAIL, error };
}

export function forgotPassword(data) {
  return { type: FORGOT_PASSWORD, data };
}

export function forgotPasswordSuccess() {
  return { type: FORGOT_PASSWORD_SUCCESS };
}

export function forgotPasswordFail(error) {
  return { type: FORGOT_PASSWORD_FAIL, error };
}

export function logout() {
  return { type: LOGOUT };
}

export function checkTokenExpiration() {
  return { type: CHECK_TOKEN_EXPIRATION };
}

export function getProfile() {
  return { type: GET_PROFILE };
}

export function getProfileSuccess(data) {
  return { type: GET_PROFILE_SUCCESS, data };
}

export function getProfileFail() {
  return { type: GET_PROFILE_FAIL };
}

export function updateProfile(data) {
  return { type: UPDATE_PROFILE, data };
}

export function updateProfileSuccess(data) {
  return { type: UPDATE_PROFILE_SUCCESS, data };
}

export function updateProfileFail(data) {
  return { type: UPDATE_PROFILE_FAIL, data };
}

export function requestResetPassword(data) {
  return { type: REQUEST_RESET_PASSWORD, data };
}

export function requestResetPasswordSuccess(data) {
  return { type: REQUEST_RESET_PASSWORD_SUCCESS, data };
}

export function requestResetPasswordFail(data) {
  return { type: REQUEST_RESET_PASSWORD_FAIL, data };
}

export function resetPassword(data) {
  return { type: RESET_PASSWORD, data };
}

export function resetPasswordSuccess(data) {
  return { type: RESET_PASSWORD_SUCCESS, data };
}

export function resetPasswordFail(data) {
  return { type: RESET_PASSWORD_FAIL, data };
}

export function changePassword(data) {
  return { type: CHANGE_PASSWORD, data };
}

export function changePasswordSuccess(data) {
  return { type: CHANGE_PASSWORD_SUCCESS, data };
}

export function changePasswordFail(data) {
  return { type: CHANGE_PASSWORD_FAIL, data };
}

export function resetCheckingEmailResult(data) {
  return { type: RESET_CHECKING_EMAIL_RESULT, data };
}
