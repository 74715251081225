import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "antd";
import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Carousel } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import { Parallax } from "react-scroll-parallax";
import { bindActionCreators } from "redux";

import * as bookingActions from "../../../actions/bookingSession";
import { searchRoom } from "../../../actions/public";

import assets from "../../../constants/assets";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  colorRed: {
    color: "red",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  gridContainer: {
    // filter: 'blur(5px)',
    // opacity: '0.8',
    // height: '350px',
    justifyContent: "center",
    // backgroundImage: `url(${Images})`,
    // padding: '0 0 12% 0',
    backgroundSize: "100% 100%",
    // backgroundPositionY: '-500px',
    backgroundRepeat: "no-repeat",
    minHeight: "200px",
  },
  backgroundOpacity: {
    position: "absolute",
    padding: "12% 0",
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 20%)",
    height: "100%",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  typographyTitle: {
    textTransform: "uppercase",
    fontWeight: "400",
    color: "white",
    paddingBottom: "20px",
    "& span": {
      textShadow: "3px 3px 2px #828282",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2.75rem",
    },
    // [theme.breakpoints.up('md')]: {
    //   paddingLeft: '270px',
    // },
  },
  formRoot: {
    // width:
    "& .ant-form-item-control": {
      margin: theme.spacing(1),
      width: 250,
    },
    "& .ant-calendar-picker": {
      width: "100%",
    },
  },
  formField: {
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      borderRadius: "15px",
    },
  },
  switchGroup: {
    padding: "10px 0 20px",
    "& .ant-switch": {
      margin: "0 10px",
      padding: "15px",
    },
    "& .ant-switch::after": {
      width: "28px",
      height: "28px",
    },
    "& .ant-switch-inner": {
      top: "-10px",
      position: "relative",
      fontSize: "16px",
    },
  },
  searchButton: {
    padding: "0 100px",
    height: "40px",
  },
  title: {
    position: "absolute",
    top: "40%",
  },
  titleContent: {
    textAlign: "center",
  },
  bookNowButton: {
    width: "15%",
    height: "50px",
    textTransform: "uppercase",
    fontSize: "16pt",
    minWidth: "max-content",
  },
}));

const SearchingAnyRoomsComponent = (props) => {
  const { t } = props;

  const classes = useStyles();

  return (
    <Parallax className="custom-class" tagOuter="figure">
      <Carousel className={classes.gridContainer}>
        {map(
          [
            assets.HEADER_SLIDE_01_IMG,
            assets.HEADER_SLIDE_02_IMG,
            assets.HEADER_SLIDE_03_IMG,
            assets.HEADER_SLIDE_04_IMG,
            assets.HEADER_SLIDE_05_IMG,
            assets.HEADER_SLIDE_06_IMG,
            assets.HEADER_SLIDE_07_IMG,
            assets.HEADER_SLIDE_08_IMG,
            assets.HEADER_SLIDE_09_IMG,
            assets.HEADER_SLIDE_10_IMG,
          ],
          (src) => (
            <Carousel.Item>
              <LazyLoadImage
                width="100%"
                style={{ minHeight: (window.innerWidth / 1600) * 490 }}
                fluid
                className="d-block w-100"
                src={src}
              />
            </Carousel.Item>
          ),
        )}
      </Carousel>

      <Grid container className={classes.backgroundOpacity}></Grid>
      <Grid container className={classes.title}>
        <Grid item xs={12} className={classes.titleContent}>
          <Typography
            component="h1"
            variant="h2"
            color="inherit"
            align="center"
            noWrap
            className={classes.typographyTitle}>
            <span>{t("FindTheIdealMeetingRoomNow")}</span>
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className={classes.bookNowButton}
            onClick={props.onStartBookingClick}>
            {t("StartBooking")}
          </Button>
        </Grid>
      </Grid>
    </Parallax>
  );
};

const mapState = ({ publicReducer }) => ({
  permanents: publicReducer.listPermanentFacilities,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      searchRoom,
      onStartBookingClick: bookingActions.showPickDatePopup,
    },
    dispatch,
  );

SearchingAnyRoomsComponent.propTypes = {
  form: PropTypes.object,
  openSearchRoomModal: PropTypes.func,
  permanents: PropTypes.array,
};

export default Form.create({
  name: "searchMeetingRoom",
})(connect(mapState, mapDispatch)(withTranslation("common")(SearchingAnyRoomsComponent)));
