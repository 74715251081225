import { combineReducers } from "redux";

import * as C from "../actionTypes/booking";

import { MY_BOOKING_UPCOMING_TAB } from "../constants/booking";

const myBooking = combineReducers({
  currentPage: (state = 1, action) => {
    const { type, payload } = action;
    switch (type) {
      case C.MY_BOOKING_CHANGE_CURRENT_PAGE:
        return payload;
      case C.MY_BOOKING_CHANGE_ACTIVATED_VIEW:
        return 1;
      default:
        return state;
    }
  },

  activatedView: (state = MY_BOOKING_UPCOMING_TAB, action) => {
    const { type, payload } = action;
    switch (type) {
      case C.MY_BOOKING_CHANGE_ACTIVATED_VIEW:
        return payload;
      default:
        return state;
    }
  },

  bookings: (state = { results: [], page_size: 10, count: 0 }, action) => {
    const { type, payload } = action;
    switch (type) {
      case C.MY_BOOKING_LIST_BOOKING_COMPLETED:
        return payload;
      default:
        return state;
    }
  },

  fetchingBookings: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.MY_BOOKING_LIST_BOOKING:
        return true;
      case C.MY_BOOKING_LIST_BOOKING_COMPLETED:
      case C.MY_BOOKING_LIST_BOOKING_FAILED:
        return false;
      default:
        return state;
    }
  },
});

const detail = combineReducers({
  isFetching: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.MY_BOOKING_RETRIEVE_BOOKING_DETAIL:
        return true;
      case C.MY_BOOKING_RETRIEVE_BOOKING_DETAIL_COMPLETED:
      case C.MY_BOOKING_RETRIEVE_BOOKING_DETAIL_FAILED:
        return false;
      default:
        return state;
    }
  },

  isLoadingInvoiceHtml: (state = false, action) => {
    const { type } = action;
    switch (type) {
      case C.MY_BOOKING_LOADING_INVOICE_HTML:
        return true;
      case C.MY_BOOKING_LOADING_INVOICE_HTML_COMPLETED:
      case C.MY_BOOKING_LOADING_INVOICE_HTML_FAILED:
        return false;
      default:
        return state;
    }
  },

  data: (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
      case C.MY_BOOKING_RETRIEVE_BOOKING_DETAIL_COMPLETED:
        return payload;
      case C.MY_BOOKING_CANCEL_BOOKING_COMPLETED:
        return payload;
      default:
        return state;
    }
  },

  invoice: (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
      case C.MY_BOOKING_LOADING_INVOICE_HTML_COMPLETED:
        return payload;
      default:
        return state;
    }
  },

  isCancelling: (state = false, action) => {
    const { type, payload } = action;
    switch (type) {
      case C.MY_BOOKING_CANCEL_BOOKING:
        return true;
      case C.MY_BOOKING_CANCEL_BOOKING_COMPLETED:
      case C.MY_BOOKING_CANCEL_BOOKING_FAILED:
        return false;
      default:
        return state;
    }
  },
});

const booking = combineReducers({
  myBooking,
  detail,
});

export default booking;
