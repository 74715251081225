import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component: Component, isLogined, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogined ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapState = (state) => ({
  isLogined: state.authReducer.isLogined,
});

PrivateRoute.propTypes = {
  component: PropTypes.any,
  isLogined: PropTypes.bool,
  location: PropTypes.object,
};

PrivateRoute.defaultProps = {
  isLogined: false,
};

export default React.memo(connect(mapState)(PrivateRoute));
