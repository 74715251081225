import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from "react-i18next";

import Image from "../../../assets/images/ecos_Stockholm_1.jpg";

import LoginComponent from "./Login";

const useStyles = makeStyles(() => {
  return {
    background: {
      "& img": {
        height: "100%",
        width: "100%",
      },
    },
  };
});

const Login = () => {
  const classes = useStyles();

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item md={4} sm={4} xs={4}>
        <LoginComponent />
      </Grid>
      <Grid item md={8} sm={8} xs={8} className={classes.background}>
        <img src={Image} />
      </Grid>
    </Grid>
  );
};

export default withTranslation("common")(Login);
