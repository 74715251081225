import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const TopbarMenuLinks = ({ title, icon, path, onClick }) => {
  if (path) {
    return (
      <Link className="topbar__link" to={path} style={{ textDecoration: "none" }}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </Link>
    );
  }

  if (onClick) {
    return (
      <a className="topbar__link" onClick={() => onClick()}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </a>
    );
  }
};

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  path: PropTypes.string,
  onClick: PropTypes.func,
};

export default TopbarMenuLinks;
