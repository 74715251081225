import { bind } from "decko";

import {
  SignUp,
  Login,
  ActivateAccount,
  ResendActivation,
  RefreshToken,
  GetProfile,
  GetSession,
  UpdateProfile,
  CheckEmailExist,
  RequestResetPassword,
  ResetPassword,
  ChangePassword,
} from "../../constants/endpoint.constants";

import BaseApi from "./baseApi";
export default class Public extends BaseApi {
  @bind
  async getSession(data) {
    return await this.http.get(GetSession(), data);
  }

  @bind
  async Register(data, lang = "en") {
    return await this.http.post(`${SignUp()}?lang=${lang}`, data);
  }

  @bind
  async Login(data) {
    return await this.http.post(Login(), data);
  }

  @bind
  async ActivateAccount(data, lang = "de") {
    return await this.http.post(`${ActivateAccount()}?lang=${lang}`, data);
  }

  @bind
  async ResendActivation(data) {
    return await this.http.post(ResendActivation(), data);
  }

  @bind
  async RefreshToken(data) {
    return await this.http.post(RefreshToken(), data);
  }

  @bind
  async GetProfile(data) {
    return await this.http.get(GetProfile(), data);
  }

  @bind
  async UpdateProfile(data) {
    return await this.http.patch(UpdateProfile(), data);
  }

  @bind
  async CheckEmailExist(data) {
    return await this.http.post(CheckEmailExist(), data);
  }

  @bind
  async RequestResetPassword(data, lang = "en") {
    return await this.http.post(`${RequestResetPassword()}?lang=${lang}`, data);
  }

  @bind
  async ResetPassword(data) {
    return await this.http.post(ResetPassword(), data);
  }

  @bind
  async ChangePassword(data) {
    return await this.http.post(ChangePassword(), data);
  }
}
