import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import * as authActions from "../../../actions/auth";
import LoginComponent from "../../../components/Auth/Login";

const Login = (props) => {
  const router = useHistory();
  const { isLogined } = props;

  useEffect(() => {
    if (isLogined) return router.replace("/");
  }, [isLogined]);

  return (
    <div>
      <LoginComponent {...props} />
    </div>
  );
};

const mapState = ({ authReducer }) => ({
  isLogined: authReducer.isLogined,
});

const mapDispatch = (dispatch) => ({
  login() {
    dispatch(authActions.login());
  },
});

Login.propTypes = {
  login: PropTypes.func,
  isLogined: PropTypes.bool,
};

export default connect(mapState, mapDispatch)(Login);
