import { Container, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as bookingActions from "../../actions/bookingSession";
import {
  closeBookingRequestModal,
  closeConfirmSearchModal,
  closeLoginModal,
  closeSearchedModal,
  closeSearchModal,
  closeViewRoomTypeModal,
  openBookingRequestModal,
  openConfirmSearchModal,
  openLoginModal,
  openSearchedModal,
  openSearchModal,
  openViewRoomTypeModal,
} from "../../actions/modal";
import {
  attachSessionCustomer,
  getListCaterings,
  getListExtraFacilities,
  getListPermanentFacilities,
  getRoomTypes,
  storeSearchData,
  getHolidays,
} from "../../actions/public";

import Footer from "../../components/Footer";
import IntroductionSection from "../../components/Home/Introduction";
import ListAvailableRooms from "../../components/Home/ListAvailableRooms";
import SeachingAnyRooms from "../../components/Home/SeachingAnyRooms";
import ViewRoomTypeModal from "../../components/Modal/ViewRoomTypeModal";
import ContactContainer from "../../containers/Booking/Contact";
import HolidayWarningContainer from "../../containers/Booking/HolidayWarning";
import Stage0Container from "../../containers/Booking/Stage0";

import ContactFloatButton from "../Booking/ContactFloatButton";
import ContinueBookingButton from "../Booking/ContinueBookingButton";
import Header from "../Header";
import CookieConsent from "./CookieConsent";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInfo: {},
      roomTypeSelected: {},
    };
  }

  UNSAFE_componentWillMount() {
    const {
      getRoomTypes,
      getListPermanentFacilities,
      getListExtraFacilities,
      getListCaterings,
      getHolidays,
    } = this.props;

    getRoomTypes();
    // getConfiguration()
    getListPermanentFacilities();
    getListExtraFacilities();
    getListCaterings();
    getHolidays();
  }

  componentWillUnmount() {
    const {
      closeSearchModal,
      // closeSearchedModal,
      closeViewRoomTypeModal,
      closeLoginModal,
    } = this.props;

    closeSearchModal();
    // closeSearchedModal()
    closeViewRoomTypeModal();
    closeLoginModal();
  }

  openViewRoomTypeModal = (data) => {
    // console.log(data)
    const { openViewRoomTypeModal } = this.props;

    this.setState({ roomTypeSelected: data, searchInfo: {} });
    openViewRoomTypeModal();
  };

  closeViewRoomModal = () => {
    const { closeViewRoomTypeModal } = this.props;

    closeViewRoomTypeModal();
  };

  openSearchRoomModal = (data) => {
    // const { openSearchModal } = this.props;

    this.setState({ searchInfo: data });
    // openSearchModal()
    this.props.openConfirmSearchModal();
  };

  closeSearchRoomModal = () => {
    const { closeSearchModal } = this.props;

    closeSearchModal();
  };

  openSearchedRoomModal = (data) => {
    const { openSearchedModal } = this.props;

    openSearchedModal();
  };

  closeSearchedRoomModal = () => {
    const { closeSearchedModal } = this.props;

    closeSearchedModal();
    // this.openSearchRoomModal()
  };

  search = () => {
    this.closeSearchRoomModal();
    this.openSearchedRoomModal();
    // history.push(paths.BookingPage)
  };

  offerRoom = (roomType) => {
    this.closeViewRoomModal();
    // this.props.openConfirmSearchModal()
  };

  bookRoom = (data) => {
    const { isLogined } = this.props;

    if (isLogined) {
      this.props.attachSessionCustomer(data);
    } else {
      this.props.openLoginModal();
    }
  };

  closeLoginModal = () => {
    this.props.closeLoginModal();
  };

  storeSearchData = (data) => {
    this.setState({ searchInfo: data });
    this.props.storeSearchData(data);
  };

  handleConfirm = (data) => {
    this.props.closeConfirmSearchModal();

    if (data === "search") {
      this.props.openSearchModal();
    } else {
      this.props.openBookingRequestModal();
    }
  };

  closeConfirmSearchModal = () => {
    this.props.closeConfirmSearchModal();
  };

  closeBookingRequestModal = () => {
    this.props.closeBookingRequestModal();
  };

  render() {
    const { roomTypeSelected } = this.state;
    const { isOpenViewRoomTypeModal } = this.props;

    return (
      <React.Fragment>
        <Header size="small" />
        <Grid container className="home-main">
          <Container maxWidth="xl" className="home-content">
            <SeachingAnyRooms openSearchRoomModal={(data) => this.openSearchRoomModal(data)} />
            <IntroductionSection />
            <ListAvailableRooms openViewRoomModal={(data) => this.openViewRoomTypeModal(data)} />
            <Footer />
          </Container>
        </Grid>

        <CookieConsent />

        <Stage0Container />

        <ContactFloatButton />
        <ContinueBookingButton />

        <ContactContainer />
        <HolidayWarningContainer />
        <ViewRoomTypeModal
          isOpen={isOpenViewRoomTypeModal}
          closeModal={this.closeViewRoomModal}
          roomTypeSelected={roomTypeSelected}
          offerRoom={this.props.startBookForRoomType}
        />
      </React.Fragment>
    );
  }
}

const mapState = ({ modalReducer, authReducer }) => ({
  isOpenSearchRoomModal: modalReducer.isOpenSearchRoomModal,
  isOpenViewRoomTypeModal: modalReducer.isOpenViewRoomTypeModal,
  isOpenSearchedRoomModal: modalReducer.isOpenSearchedRoomModal,
  isOpenConfirmSearchModal: modalReducer.isOpenConfirmSearchModal,
  isOpenBookingRequestModal: modalReducer.isOpenBookingRequestModal,
  isLogined: authReducer.isLogined,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getRoomTypes,
      getListPermanentFacilities,
      getListExtraFacilities,
      getListCaterings,
      openSearchModal,
      openViewRoomTypeModal,
      openSearchedModal,
      openLoginModal,
      closeSearchModal,
      closeSearchedModal,
      closeViewRoomTypeModal,
      closeLoginModal,
      storeSearchData,
      attachSessionCustomer,
      openConfirmSearchModal,
      closeConfirmSearchModal,
      openBookingRequestModal,
      closeBookingRequestModal,
      startBookForRoomType: bookingActions.startBookByRoomType,
      getHolidays,
    },
    dispatch,
  );

HomePage.propTypes = {
  getRoomTypes: PropTypes.func,
  getListPermanentFacilities: PropTypes.func,
  getListExtraFacilities: PropTypes.func,
  getListCaterings: PropTypes.func,
  storeSearchData: PropTypes.func,
  isOpenSearchRoomModal: PropTypes.bool,
  isOpenViewRoomTypeModal: PropTypes.bool,
  isOpenSearchedRoomModal: PropTypes.bool,
  openSearchModal: PropTypes.func,
  openViewRoomTypeModal: PropTypes.func,
  openSearchedModal: PropTypes.func,
  closeSearchModal: PropTypes.func,
  closeSearchedModal: PropTypes.func,
  closeViewRoomTypeModal: PropTypes.func,
  isLogined: PropTypes.bool,
  attachSessionCustomer: PropTypes.func,
  isOpenConfirmSearchModal: PropTypes.bool,
  openConfirmSearchModal: PropTypes.func,
  closeConfirmSearchModal: PropTypes.func,
  isOpenBookingRequestModal: PropTypes.bool,
  openBookingRequestModal: PropTypes.func,
  closeBookingRequestModal: PropTypes.func,
  // getConfiguration: PropTypes.func,
};

export default connect(mapState, mapDispatch)(withRouter(HomePage));
