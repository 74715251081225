import { Container, Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import RoomComponent from "./Room";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    justifyContent: "center",
    padding: "0 64px",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  title: {
    padding: "25px 0",
    textTransform: "uppercase",
  },
  showMoreGrid: {
    padding: "20px",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
}));

const ListAvailableRoomComponent = (props) => {
  const { openViewRoomModal, isLoadingRoomTypes, t } = props;

  const classes = useStyles();

  const listRoomTypes =
    props.listRoomTypes && typeof props.listRoomTypes !== "string" ? props.listRoomTypes : [];

  return (
    <Container xs={12} className={classes.gridContainer} maxWidth="xl">
      <Typography
        component="h1"
        variant="h5"
        color="inherit"
        align="center"
        noWrap
        className={classes.title}>
        {t("14")}
      </Typography>
      {isLoadingRoomTypes ? (
        <Grid container className={classes.justifyContentCenter}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid spacing={8} container className={classes.textAlignCenter}>
            {(listRoomTypes || []).map((item, index) => {
              return (
                <Grid item key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
                  <RoomComponent
                    roomInfo={item}
                    openViewRoomModal={() => openViewRoomModal(item)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Container>
  );
};

const mapState = ({ publicReducer }) => ({
  listRoomTypes: publicReducer.listRoomTypes,
  isLoadingRoomTypes: publicReducer.isLoadingRoomTypes,
});

ListAvailableRoomComponent.propTypes = {
  listRoomTypes: PropTypes.array,
  isLoadingRoomTypes: PropTypes.bool,
  openViewRoomModal: PropTypes.func,
};

ListAvailableRoomComponent.defaultProps = {
  listRoomTypes: [],
  isLoadingRoomTypes: false,
};

export default connect(mapState, null)(withTranslation("common")(ListAvailableRoomComponent));
