import * as actionTypes from "../actionTypes/public.actionTypes";

export function getRoomTypes() {
  return { type: actionTypes.GET_LIST_ROOM_TYPES };
}

export function getRoomTypesSuccess(data) {
  return { type: actionTypes.GET_LIST_ROOM_TYPES_SUCCESS, data };
}

export function getRoomTypesFail() {
  return { type: actionTypes.GET_LIST_ROOM_TYPES_FAIL };
}

export function getConfiguration() {
  return { type: actionTypes.GET_CONFIGURATION };
}

export function getConfigurationSuccess(data) {
  return { type: actionTypes.GET_CONFIGURATION_SUCCESS, data };
}

export function getConfigurationFail() {
  return { type: actionTypes.GET_CONFIGURATION_FAIL };
}

export function searchRoom(data) {
  return { type: actionTypes.SEARCH_ROOM, data };
}

export function searchRoomSuccess(data) {
  return { type: actionTypes.SEARCH_ROOM_SUCCESS, data };
}

export function searchRoomFail() {
  return { type: actionTypes.SEARCH_ROOM_FAIL };
}

export function getListPermanentFacilities(data) {
  return { type: actionTypes.GET_LIST_PERMANENT_FACILITIES, data };
}

export function getListPermanentFacilitiesSuccess(data) {
  return { type: actionTypes.GET_LIST_PERMANENT_FACILITIES_SUCCESS, data };
}

export function getListPermanentFacilitiesFail() {
  return { type: actionTypes.GET_LIST_PERMANENT_FACILITIES_FAIL };
}

export function getListExtraFacilities(data) {
  return { type: actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES, data };
}

export function getListExtraFacilitiesSuccess(data) {
  return { type: actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_SUCCESS, data };
}

export function getListExtraFacilitiesFail() {
  return { type: actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES_FAIL };
}

export function getListCaterings(data) {
  return { type: actionTypes.GET_LIST_CATERINGS, data };
}

export function getListCateringsSuccess(data) {
  return { type: actionTypes.GET_LIST_CATERINGS_SUCCESS, data };
}

export function getListCateringsFail() {
  return { type: actionTypes.GET_LIST_CATERINGS_FAIL };
}

export function searchAvailableExtraFacilities(data) {
  return { type: actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES, data };
}

export function searchAvailableExtraFacilitiesSuccess(data) {
  return { type: actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_SUCCESS, data };
}

export function searchAvailableExtraFacilitiesFail(data) {
  return { type: actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES_FAIL, data };
}

export function addExtraFacility(data) {
  return { type: actionTypes.ADD_EXTRA_FACILITY, data };
}

export function addExtraFacilitySuccess(data) {
  return { type: actionTypes.ADD_EXTRA_FACILITY_SUCCESS, data };
}

export function addExtraFacilityFail(data) {
  return { type: actionTypes.ADD_EXTRA_FACILITY_FAIL, data };
}

export function storeSearchData(data) {
  return { type: actionTypes.STORE_SEARCH_DATA, data };
}

export function getBookingSessions(data) {
  return { type: actionTypes.GET_BOOKING_SESSIONS, data };
}

export function getBookingSessionsSuccess(data) {
  return { type: actionTypes.GET_BOOKING_SESSIONS_SUCCESS, data };
}

export function getBookingSessionsFail(data) {
  return { type: actionTypes.GET_BOOKING_SESSIONS_FAIL, data };
}

export function updateBookingSession(data) {
  return { type: actionTypes.UPDATE_BOOKING_SESSION, data };
}

export function updateBookingSessionSuccess(data) {
  return { type: actionTypes.UPDATE_BOOKING_SESSION_SUCCESS, data };
}

export function updateBookingSessionFail(data) {
  return { type: actionTypes.UPDATE_BOOKING_SESSION_FAIL, data };
}

export function bookRoom(data) {
  return { type: actionTypes.BOOKING_ROOM, data };
}

export function getBookings(data) {
  return { type: actionTypes.GET_BOOKING, data };
}

export function getBookingsSuccess(data) {
  return { type: actionTypes.GET_BOOKING_SUCCESS, data };
}

export function getBookingsFail(data) {
  return { type: actionTypes.GET_BOOKING_FAIL, data };
}

export function removeBookingSession(data) {
  return { type: actionTypes.REMOVE_BOOKING_SESSION, data };
}

export function removeExtraFacility(data) {
  return { type: actionTypes.REMOVE_EXTRA_FACILITY, data };
}

export function removeExtraFacilitySuccess(data) {
  return { type: actionTypes.REMOVE_EXTRA_FACILITY_SUCCESS, data };
}

export function removeExtraFacilityFail(data) {
  return { type: actionTypes.REMOVE_EXTRA_FACILITY_FAIL, data };
}

export function searchCaterings(data) {
  return { type: actionTypes.SEARCH_AVAILABLE_CATERINGS, data };
}

export function searchCateringsSuccess(data) {
  return { type: actionTypes.SEARCH_AVAILABLE_CATERINGS_SUCCESS, data };
}

export function searchCateringsFail(data) {
  return { type: actionTypes.SEARCH_AVAILABLE_CATERINGS_FAIL, data };
}

export function addSessionCatering(data) {
  return { type: actionTypes.ADD_SESSION_CATERING, data };
}

export function addSessionCateringSuccess(data) {
  return { type: actionTypes.ADD_SESSION_CATERING_SUCCESS, data };
}

export function addSessionCateringFail(data) {
  return { type: actionTypes.ADD_SESSION_CATERING_FAIL, data };
}

export function removeSessionItems(data) {
  return { type: actionTypes.REMOVE_SESSION_ITEMS, data };
}

export function removeSessionItemsSuccess(data) {
  return { type: actionTypes.REMOVE_SESSION_ITEMS_SUCCESS, data };
}

export function removeSessionItemsFail(data) {
  return { type: actionTypes.REMOVE_SESSION_ITEMS_FAIL, data };
}

export function attachSessionCustomer(data) {
  return { type: actionTypes.ATTACH_SESSION_CUSTOMER, data };
}

export function attachSessionCustomerSuccess(data) {
  return { type: actionTypes.ATTACH_SESSION_CUSTOMER_SUCCESS, data };
}

export function attachSessionCustomerFail(data) {
  return { type: actionTypes.ATTACH_SESSION_CUSTOMER_FAIL, data };
}

export function printInvoice(data) {
  return { type: actionTypes.PRINT_INVOICE, data };
}

export function printInvoiceSuccess(data) {
  return { type: actionTypes.PRINT_INVOICE_SUCCESS, data };
}

export function printInvoiceFail(data) {
  return { type: actionTypes.PRINT_INVOICE_FAIL, data };
}

export function updateBookingState(data) {
  return { type: actionTypes.UPDATE_BOOKING_STATE, data };
}

export function updateBookingStateSuccess(data) {
  return { type: actionTypes.UPDATE_BOOKING_STATE_SUCCESS, data };
}

export function updateBookingStateFail(data) {
  return { type: actionTypes.UPDATE_BOOKING_STATE_FAIL, data };
}

export function linkSessionPayment(data) {
  return { type: actionTypes.LINK_SESSION_PAYMENT, data };
}

export function linkSessionPaymentSuccess(data) {
  return { type: actionTypes.LINK_SESSION_PAYMENT_SUCCESS, data };
}

export function linkSessionPaymentFail(data) {
  return { type: actionTypes.LINK_SESSION_PAYMENT_FAIL, data };
}

export function confirmBooking(data) {
  return { type: actionTypes.CONFIRM_BOOKING, data };
}

export function confirmBookingSuccess(data) {
  return { type: actionTypes.CONFIRM_BOOKING_SUCCESS, data };
}

export function confirmBookingFail(data) {
  return { type: actionTypes.CONFIRM_BOOKING_FAIL, data };
}

export function destroyBookingSession(data) {
  return { type: actionTypes.DESTROY_BOOKING_SESSION, data };
}

export function destroyBookingSessionSuccess(data) {
  return { type: actionTypes.DESTROY_BOOKING_SESSION_SUCCESS, data };
}

export function destroyBookingSessionFail(data) {
  return { type: actionTypes.DESTROY_BOOKING_SESSION_FAIL, data };
}

export function sendContactRequest(data) {
  return { type: actionTypes.SEND_CONTACT_REQUEST, data };
}

export function sendContactRequestSuccess(data) {
  return { type: actionTypes.SEND_CONTACT_REQUEST_SUCCESS, data };
}

export function sendContactRequestFail(data) {
  return { type: actionTypes.SEND_CONTACT_REQUEST_FAIL, data };
}

export function retrieveExtraFacilityItemPricings(data) {
  return { type: actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS, data };
}

export function retrieveExtraFacilityItemPricingsSuccess(data) {
  return { type: actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_SUCCESS, data };
}

export function retrieveExtraFacilityItemPricingsFail(data) {
  return { type: actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS_FAIL, data };
}

export function retrieveCateringItemPricings(data) {
  return { type: actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS, data };
}

export function retrieveCateringItemPricingsSuccess(data) {
  return { type: actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_SUCCESS, data };
}

export function retrieveCateringItemPricingsFail(data) {
  return { type: actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS_FAIL, data };
}

export function changeLanguage(data) {
  return { type: actionTypes.CHANGE_LANGUAGE, data };
}

export function changeLanguageComplete(data) {
  return { type: actionTypes.CHANGE_LANGUAGE_SUCCESS, data };
}

export function getHolidays() {
  return { type: actionTypes.GET_LIST_HOLIDAY };
}

export function getHolidaysSuccess(data) {
  return { type: actionTypes.GET_LIST_HOLIDAY_SUCCESS, data };
}

export function getHolidaysFail() {
  return { type: actionTypes.GET_LIST_HOLIDAY_FAIL };
}
