import * as C from "../actionTypes/booking";

export const myBooking = {
  loadInitialData: () => ({ type: C.MY_BOOKING_LOAD_INITIAL_DATA }),
  changeActivedView: (payload) => ({ type: C.MY_BOOKING_CHANGE_ACTIVATED_VIEW, payload }),
  changeCurrentPage: (payload) => ({ type: C.MY_BOOKING_CHANGE_CURRENT_PAGE, payload }),

  retrieveBookingDetail: (payload) => ({ type: C.MY_BOOKING_RETRIEVE_BOOKING_DETAIL, payload }),
  cancelBooking: () => ({ type: C.MY_BOOKING_CANCEL_BOOKING }),
};
