import { message } from "antd";
import { isAfter } from "date-fns";
import { get } from "lodash";
import "moment/locale/en-ca";
import "moment/locale/zh-cn";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import * as modalActions from "../actions/modal";
import * as actions from "../actions/public";
import * as actionTypes from "../actionTypes/public.actionTypes";

import i18n from "../config/i18next";
import { history } from "../config/store";
import * as paths from "../constants/paths.constants";

import { LOCAL_STORAGE_KEY } from "../helpers/constant";

import * as homeSelector from "../selectors/home";
import * as storage from "../services/storage";

function* getListRoomTypes({ api }) {
  const roomTypes = yield select(homeSelector.getListRoomTypes);
  if (roomTypes && roomTypes.length) {
    yield put(actions.getRoomTypesSuccess(roomTypes));
    return;
  }

  try {
    const response = yield call(api.public.getRoomTypes);
    yield put(actions.getRoomTypesSuccess(response));
  } catch (e) {
    yield put(actions.getRoomTypesFail());
  }
}

function* getConfiguration({ api }) {
  try {
    // yield delay(2000)
    const response = yield call(api.public.getConfiguration);
    yield put(actions.getConfigurationSuccess(response));
  } catch (e) {
    yield put(actions.getConfigurationFail());
  }
}

function* searchRoom({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.public.searchAvailableRoom, data);
    if (response && response.items.length > 0) {
      yield put(modalActions.closeSearchModal());
      yield put(modalActions.openSearchedModal());
      yield put(actions.getBookingSessionsSuccess(response));
      // yield put(actions.getBookingSessions())
    } else {
      message.error(
        "We haven't found the available room for you. Please try again with another option. ",
      );
      yield put(actions.searchRoomFail());
    }
  } catch (e) {
    message.error(
      "We haven't found the available room for you. Please try again with another option. ",
    );
    yield put(actions.searchRoomFail());
  }
}

function* getListPermanentFacilities({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.public.getListPermanentFacilities, data);
    yield put(actions.getListPermanentFacilitiesSuccess(response));
  } catch (e) {
    yield put(actions.getListPermanentFacilitiesFail());
  }
}

function* getListExtraFacilities({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.public.getListExtraFacilityCategories, data);
    yield put(actions.getListExtraFacilitiesSuccess(response));
  } catch (e) {
    yield put(actions.getListExtraFacilitiesFail());
  }
}

function* getListCaterings({ api }, { data }) {
  try {
    // yield delay(2000)
    const response = yield call(api.public.getListCaterings, data);
    yield put(actions.getListCateringsSuccess(response));
  } catch (e) {
    yield put(actions.getListCateringsFail());
  }
}

const getBookingSessionId = ({ publicReducer }) => (publicReducer.bookingSession || {}).id;

function* searchExtraPrice({ api }, { data }) {
  try {
    const response = yield call(api.public.searchExtraFacilities, data);
    if (response) {
      yield put(
        actions.searchAvailableExtraFacilitiesSuccess({
          id: data.category,
          listAvailable: response,
        }),
      );
      // yield put(actions.getBookingSessionsSuccess())
    } else {
      yield put(actions.searchAvailableExtraFacilitiesFail());
    }
  } catch (e) {
    yield put(actions.searchAvailableExtraFacilitiesFail());
  }
}

function* addExtraFacility({ api }, { data }) {
  try {
    // yield delay(2000)
    yield call(api.public.addExtraFacilities, data);
    yield put(actions.getBookingSessions());
    yield put(actions.addExtraFacilitySuccess());
    yield put(actions.retrieveExtraFacilityItemPricingsSuccess([]));
  } catch (e) {
    yield put(actions.addExtraFacilityFail());
  }
}

function* bookingRoom({ api }, { data }) {
  try {
    // yield delay(2000)
    let listBookingSessions = yield call(api.public.getBookingSessions);
    // console.log(listBookingSessions)
    if (listBookingSessions.length > 0) {
      yield put(actions.getBookingSessionsSuccess(listBookingSessions[0]));
      yield call(api.public.updateBookingSession, { ...data, id: listBookingSessions[0].id });
      listBookingSessions = yield call(api.public.getBookingSessions);
      yield put(actions.getBookingSessionsSuccess(listBookingSessions[0]));
      yield put(modalActions.closeSearchedModal());
      history.push(paths.BookingPage);
    } else {
      yield put(actions.updateBookingSessionFail());
    }
  } catch (e) {
    yield put(actions.updateBookingSessionFail());
  }
}

function* updateBookingSession({ api }, { data }) {
  try {
    const authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization);
    console.log(authorization);
    if (!(authorization || {}).access) {
      console.log("tesst");
      yield put(modalActions.openLoginModal());
    } else {
      const bookingSessionId = yield select(getBookingSessionId);
      const response = yield call(api.public.updateBookingSession, {
        ...data,
        id: bookingSessionId,
      });
      yield put(actions.updateBookingSessionSuccess(response));
    }
  } catch (e) {
    yield put(actions.updateBookingSessionFail({ id: data.category_id }));
  }
}

function* getBookings({ api }, { data }) {
  try {
    const response = yield call(api.public.getBookings, data);
    yield put(actions.getBookingsSuccess(response));
  } catch (e) {
    console.log(e);
    yield put(actions.getBookingsFail());
  }
}

function* getBookingSessions({ api }) {
  try {
    const bookings = yield call(api.public.getBookingSession);
    if (bookings) {
      if (bookings.expired_at) {
        const isExpired = isAfter(new Date(), new Date(bookings.expired_at));
        if (!isExpired) {
          yield put(actions.getBookingSessionsSuccess(bookings));
        } else {
          localStorage.removeItem("booking");
        }
      } else {
        localStorage.removeItem("booking");
      }
    }
  } catch (e) {
    yield put(actions.getBookingSessionsFail());
  }
}

function* removeSessionItems({ api }, { data }) {
  try {
    yield call(api.public.removeSessionItems, {
      ids: data,
    });
    yield put(actions.getBookingSessions());
    yield put(actions.removeSessionItemsFail());
  } catch (e) {
    yield put(actions.removeSessionItemsSuccess());
  }
}

function* searchAvailableCaterings({ api }, { data }) {
  try {
    // const bookingSessionId = yield select(getBookingSessionId)
    const response = yield call(api.public.searchAvailableCaterings, data);
    if (response) {
      yield put(actions.searchCateringsSuccess());
      // yield put(actions.getBookingSessionsSuccess(response))
    } else {
      yield put(actions.searchCateringsFail());
    }
  } catch (e) {
    console.log(e);
    yield put(actions.searchCateringsFail());
  }
}

function* addSessionCatering({ api }, { data }) {
  try {
    // const bookingSessionId = yield select(getBookingSessionId)
    yield call(api.public.addCatering, data);
    yield put(actions.getBookingSessions());
    yield put(actions.addSessionCateringSuccess());
    yield put(actions.retrieveCateringItemPricingsSuccess([]));
  } catch (e) {
    console.log(e);
    yield put(actions.addSessionCateringFail());
  }
}

const getCustomerId = ({ authReducer }) => (authReducer.userProfile || {}).id;

function* attachSessionCustomer({ api }, { data }) {
  try {
    const customer_id = yield select(getCustomerId);
    yield call(api.public.attachSessionCustomer, { customer_id });
    yield call(api.public.updateBookingState, { state: 2 });
    const bookingSession = yield call(api.public.getBookingSession, { state: 2 });
    yield put(actions.getBookingSessionsSuccess(bookingSession));
    yield put(actions.attachSessionCustomerSuccess());
    yield put(modalActions.closeSearchedModal());
    history.push(paths.BookingPage);
  } catch (e) {
    console.log(e);
    yield put(actions.attachSessionCustomerFail());
  }
}

function* printInvoice({ api }, { data }) {
  try {
    const session = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.session);
    yield call(api.public.printInvoice, { session });
    // yield put(actions.getBookingSessions())
    yield put(actions.printInvoiceSuccess());
  } catch (e) {
    console.log(e);
    yield put(actions.printInvoiceFail());
  }
}

function* updateBookingState({ api }, { data }) {
  try {
    const authorization = storage.getLocalStorageItem(LOCAL_STORAGE_KEY.authorization);
    if (!(authorization || {}).access) {
      console.log("tesst");
      yield put(modalActions.openLoginModal());
      yield put(actions.updateBookingStateFail());
    } else {
      yield call(api.public.updateBookingState, data);
      yield put(actions.getBookingSessions());
      yield put(actions.updateBookingStateSuccess());
    }
  } catch (e) {
    console.log(e);
    yield put(actions.updateBookingStateFail());
  }
}

function* linkSessionPayment({ api }, { data }) {
  try {
    yield call(api.public.linkSessionPayment, data);
    yield call(api.public.confirmBooking, data);
    yield call(api.public.updateBookingState, { state: 5 });
    yield put(actions.getBookingSessions());
    yield put(actions.confirmBookingSuccess());
  } catch (e) {
    yield put(actions.confirmBookingFail());
  }
}

function* destroyBookingSession({ api }, { data }) {
  try {
    yield call(api.public.destroyBookingSession, data);
    yield put(actions.getBookingSessions());
    yield put(actions.destroyBookingSessionSuccess());
    history.push(paths.MyBookingPage);
  } catch (e) {
    yield put(actions.destroyBookingSessionFail());
  }
}

function* removeBookingSession({ api }, { data }) {
  try {
    yield call(api.public.destroyBookingSession, data);
    yield put(actions.getBookingSessions());
    yield put(actions.destroyBookingSessionSuccess());
    yield put(modalActions.closeSearchedModal());
    history.push(paths.HomePage);
  } catch (e) {
    yield put(actions.destroyBookingSessionFail());
  }
}

function* sendContactRequest({ api }, { data }) {
  try {
    yield call(api.public.sendContactRequest, data);
    message.success("Request Successfully");
    yield put(modalActions.closeBookingRequestModal());
    yield put(actions.sendContactRequestSuccess());
  } catch (e) {
    yield put(actions.sendContactRequestFail());
  }
}

function* retrieveExtraFacilityItemPricings({ api }, { data }) {
  try {
    const response = yield call(api.public.retrieveItemPricings, data);
    yield put(actions.retrieveExtraFacilityItemPricingsSuccess(get(response, "results")));
  } catch (e) {
    console.log(e);
    yield put(actions.retrieveExtraFacilityItemPricingsFail());
  }
}

function* retrieveCateringItemPricings({ api }, { data }) {
  try {
    const response = yield call(api.public.retrieveItemPricings, data);
    yield put(actions.retrieveCateringItemPricingsSuccess(get(response, "results")));
  } catch (e) {
    console.log(e);
    yield put(actions.retrieveCateringItemPricingsFail());
  }
}

function* changeLanguage({ api }, { data }) {
  try {
    yield i18n.changeLanguage(data);
    localStorage.setItem("language", data);
    // moment.locale(locale === 'zh' ? 'zh-cn' : 'en-ca')
    yield put(actions.changeLanguageComplete(data));
  } catch (e) {
    const currentLocale = yield select((state) => get(state, "public.locale.lang", "en"));
    yield put(actions.changeLanguageComplete(currentLocale));
  }
}

function* getListHolidays({ api }) {
  const listHoliday = yield select(homeSelector.getListHoliday);

  if (listHoliday && listHoliday.length) {
    yield put(actions.getHolidaysSuccess(listHoliday));
    return;
  }

  try {
    const response = yield call(api.public.getListHolidays);
    yield put(actions.getHolidaysSuccess(response));
  } catch (e) {
    yield put(actions.getHolidaysFail());
  }
}

function publicSaga(deps) {
  return function* saga() {
    yield all([
      takeLatest(actionTypes.GET_LIST_ROOM_TYPES, getListRoomTypes, deps),
      takeLatest(actionTypes.GET_CONFIGURATION, getConfiguration, deps),
      takeLatest(actionTypes.SEARCH_ROOM, searchRoom, deps),
      takeLatest(actionTypes.GET_LIST_PERMANENT_FACILITIES, getListPermanentFacilities, deps),
      takeLatest(actionTypes.GET_LIST_EXTRA_FACILITIES_CATEGORIES, getListExtraFacilities, deps),
      takeLatest(actionTypes.GET_LIST_CATERINGS, getListCaterings, deps),
      takeLatest(actionTypes.SEARCH_AVAILABLE_EXTRA_FACILITIES, searchExtraPrice, deps),
      takeLatest(actionTypes.ADD_EXTRA_FACILITY, addExtraFacility, deps),
      takeLatest(actionTypes.BOOKING_ROOM, bookingRoom, deps),
      takeLatest(actionTypes.GET_BOOKING, getBookings, deps),
      takeLatest(actionTypes.GET_BOOKING_SESSIONS, getBookingSessions, deps),
      takeLatest(actionTypes.REMOVE_SESSION_ITEMS, removeSessionItems, deps),
      takeLatest(actionTypes.SEARCH_AVAILABLE_CATERINGS, searchAvailableCaterings, deps),
      takeLatest(actionTypes.ADD_SESSION_CATERING, addSessionCatering, deps),
      takeLatest(actionTypes.ATTACH_SESSION_CUSTOMER, attachSessionCustomer, deps),
      takeLatest(actionTypes.PRINT_INVOICE, printInvoice, deps),
      takeLatest(actionTypes.UPDATE_BOOKING_STATE, updateBookingState, deps),
      takeLatest(actionTypes.LINK_SESSION_PAYMENT, linkSessionPayment, deps),
      takeLatest(actionTypes.DESTROY_BOOKING_SESSION, destroyBookingSession, deps),
      takeLatest(actionTypes.REMOVE_BOOKING_SESSION, removeBookingSession, deps),
      takeLatest(actionTypes.SEND_CONTACT_REQUEST, sendContactRequest, deps),

      takeLatest(
        actionTypes.RETRIEVE_EXTRA_FACILITY_ITEM_PRICINGS,
        retrieveExtraFacilityItemPricings,
        deps,
      ),
      takeLatest(actionTypes.RETRIEVE_CATERING_ITEM_PRICINGS, retrieveCateringItemPricings, deps),
      takeLatest(actionTypes.CHANGE_LANGUAGE, changeLanguage, deps),
      takeLatest(actionTypes.GET_LIST_HOLIDAY, getListHolidays, deps),
    ]);
  };
}

export default publicSaga;
