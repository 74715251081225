import React, { Component } from "react";
import Cookies from "js-cookie";

import CookieConsent from "../../components/Home/CookieConsent";

class CookieConsentContainer extends Component {
  state = {
    visible: !Cookies.get("cookie_consent.required"),
    statistics: false,
    comfort: false,
  };

  render() {
    return (
      <CookieConsent
        {...this.state}
        onStatisticsChange={(value) => this.setState({ statistics: value })}
        onComfortChange={(value) => this.setState({ comfort: value })}
        onSelectAll={() => this.setState({ statistics: true, comfort: true })}
        onConfirm={() => {
          Cookies.set("cookie_consent.required", true);
          Cookies.set("cookie_consent.statistics", this.state.statistics);
          Cookies.set("cookie_consent.comfort", this.state.comfort);

          this.setState({ visible: !Cookies.get("cookie_consent.required") });
        }}
      />
    );
  }
}

export default CookieConsentContainer;
