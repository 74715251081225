export const LOCAL_STORAGE_KEY = {
  authorization: "AUTHORIZATION",
  session: "Session",
};

export const CURRENCY_MAPPING = {
  EUR: "€",
  USD: "$",
};

export const PUBLIC_HOLIDAYS = [
  // 2020
  {
    date: "2020/01/01",
    name: "Neujahr",
  },
  {
    date: "2020/01/06",
    name: "Heilige Drei Könige",
  },
  {
    date: "2020/04/10",
    name: "Karfreitag",
  },
  {
    date: "2020/04/13",
    name: "Ostermontag",
  },
  {
    date: "2020/05/01",
    name: "Tag der Arbeit",
  },
  {
    date: "2020/05/21",
    name: "Christi Himmelfahrt",
  },
  {
    date: "2020/06/01",
    name: "Pfingstmontag",
  },
  {
    date: "2020/06/11",
    name: "Fronleichnam",
  },
  {
    date: "2020/10/03",
    name: "Tag der Deutschen Einheit",
  },
  {
    date: "2020/11/01",
    name: "Allerheiligen",
  },
  {
    date: "2020/12/25",
    name: "1. Weihnachtsfeiertag",
  },
  {
    date: "2020/12/26",
    name: "2. Weihnachtsfeiertag",
  },
  // 2021
  {
    date: "2021/01/01",
    name: "Neujahr",
  },
  {
    date: "2021/01/06",
    name: "Heilige Drei Könige",
  },
  {
    date: "2021/04/02",
    name: "Karfreitag",
  },
  {
    date: "2021/04/05",
    name: "Ostermontag",
  },
  {
    date: "2021/05/01",
    name: "Tag der Arbeit",
  },
  {
    date: "2021/05/13",
    name: "Christi Himmelfahrt",
  },
  {
    date: "2021/05/24",
    name: "Pfingstmontag",
  },
  {
    date: "2021/06/03",
    name: "Fronleichnam",
  },
  {
    date: "2021/10/03",
    name: "Tag der Deutschen Einheit",
  },
  {
    date: "2021/11/01",
    name: "Allerheiligen",
  },
  {
    date: "2021/12/25",
    name: "1. Weihnachtsfeiertag",
  },
  {
    date: "2021/12/26",
    name: "2. Weihnachtsfeiertag",
  },
  // 2022
  {
    date: "2022/01/01",
    name: "Neujahr",
  },
  {
    date: "2022/01/06",
    name: "Heilige Drei Könige",
  },
  {
    date: "2022/04/15",
    name: "Karfreitag",
  },
  {
    date: "2022/04/18",
    name: "Ostermontag",
  },
  {
    date: "2022/05/01",
    name: "Tag der Arbeit",
  },
  {
    date: "2022/05/26",
    name: "Christi Himmelfahrt",
  },
  {
    date: "2022/06/06",
    name: "Pfingstmontag",
  },
  {
    date: "2022/06/16",
    name: "Fronleichnam",
  },
  {
    date: "2022/10/03",
    name: "Tag der Deutschen Einheit",
  },
  {
    date: "2022/11/01",
    name: "Allerheiligen",
  },
  {
    date: "2022/12/25",
    name: "1. Weihnachtsfeiertag",
  },
  {
    date: "2022/12/26",
    name: "2. Weihnachtsfeiertag",
  },
  // 2023
  {
    date: "2023/01/01",
    name: "Neujahr",
  },
  {
    date: "2023/01/06",
    name: "Heilige Drei Könige",
  },
  {
    date: "2023/04/07",
    name: "Karfreitag",
  },
  {
    date: "2023/04/10",
    name: "Ostermontag",
  },
  {
    date: "2023/05/01",
    name: "Tag der Arbeit",
  },
  {
    date: "2023/05/18",
    name: "Christi Himmelfahrt",
  },
  {
    date: "2023/05/29",
    name: "Pfingstmontag",
  },
  {
    date: "2023/06/08",
    name: "Fronleichnam",
  },
  {
    date: "2023/10/03",
    name: "Tag der Deutschen Einheit",
  },
  {
    date: "2023/11/01",
    name: "Allerheiligen",
  },
  {
    date: "2023/12/25",
    name: "1. Weihnachtsfeiertag",
  },
  {
    date: "2023/12/26",
    name: "2. Weihnachtsfeiertag",
  },
  // 2024
  {
    date: "2024/01/01",
    name: "Neujahr",
  },
  {
    date: "2024/01/06",
    name: "Heilige Drei Könige",
  },
  {
    date: "2024/03/29",
    name: "Karfreitag",
  },
  {
    date: "2024/04/01",
    name: "Ostermontag",
  },
  {
    date: "2024/05/01",
    name: "Tag der Arbeit",
  },
  {
    date: "2024/05/09",
    name: "Christi Himmelfahrt",
  },
  {
    date: "2024/05/20",
    name: "Pfingstmontag",
  },
  {
    date: "2024/05/30",
    name: "Fronleichnam",
  },
  {
    date: "2024/10/03",
    name: "Tag der Deutschen Einheit",
  },
  {
    date: "2024/11/01",
    name: "Allerheiligen",
  },
  {
    date: "2024/12/25",
    name: "1. Weihnachtsfeiertag",
  },
  {
    date: "2024/12/26",
    name: "2. Weihnachtsfeiertag",
  },
];
