import { createSelector } from "reselect";

const getReducer = ({ bookingSession }) => bookingSession.stage3;

const getSessionData = ({ bookingSession: { sessionData } }) => {
  return sessionData;
};

export const getPaymentMethod = createSelector(
  [getSessionData],
  ({ payment_method }) => payment_method,
);

export const isNextSubmitting = createSelector(
  [getReducer],
  ({ isNextSubmitting }) => isNextSubmitting,
);

export const isBackSubmitting = createSelector(
  [getReducer],
  ({ isBackSubmitting }) => isBackSubmitting,
);

export const isCancelSubmitting = createSelector(
  [getReducer],
  ({ isCancelSubmitting }) => isCancelSubmitting,
);
