const C = "BOOKING";

const MY_BOOKING = `${C}.MY_BOOKING`;
export const MY_BOOKING_LOAD_INITIAL_DATA = `${MY_BOOKING}.LOAD_INITIAL_DATA`;
export const MY_BOOKING_CHANGE_ACTIVATED_VIEW = `${MY_BOOKING}.ACTIVE_UPCOMIMG_VIEW`;
export const MY_BOOKING_LIST_BOOKING = `${MY_BOOKING}.LIST_BOOKING`;
export const MY_BOOKING_LIST_BOOKING_COMPLETED = `${MY_BOOKING}.LIST_BOOKING_COMPLETED`;
export const MY_BOOKING_LIST_BOOKING_FAILED = `${MY_BOOKING}.LIST_BOOKING_FAILED`;
export const MY_BOOKING_CHANGE_CURRENT_PAGE = `${MY_BOOKING}.CHANGE_CURRENT_PAGE`;

export const MY_BOOKING_RETRIEVE_BOOKING_DETAIL = `${MY_BOOKING}.RETRIEVE_BOOKING_DETAIL`;
export const MY_BOOKING_RETRIEVE_BOOKING_DETAIL_COMPLETED = `${MY_BOOKING}.RETRIEVE_BOOKING_DETAIL_COMPLETED`;
export const MY_BOOKING_RETRIEVE_BOOKING_DETAIL_FAILED = `${MY_BOOKING}.RETRIEVE_BOOKING_DETAIL_FAILED`;

export const MY_BOOKING_LOADING_INVOICE_HTML = `${MY_BOOKING}.LOADING_INVOICE_HTML`;
export const MY_BOOKING_LOADING_INVOICE_HTML_COMPLETED = `${MY_BOOKING}.LOADING_INVOICE_HTML_COMPLETED`;
export const MY_BOOKING_LOADING_INVOICE_HTML_FAILED = `${MY_BOOKING}.LOADING_INVOICE_HTML_FAILED`;

export const MY_BOOKING_CANCEL_BOOKING = `${MY_BOOKING}.CANCEL_BOOKING`;
export const MY_BOOKING_CANCEL_BOOKING_COMPLETED = `${MY_BOOKING}.CANCEL_BOOKING_COMPLETED`;
export const MY_BOOKING_CANCEL_BOOKING_FAILED = `${MY_BOOKING}.CANCEL_BOOKING_FAILED`;
