import * as _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import { ITEM_TYPE, PAYMENT_METHOD } from "../../constants/bookingSession";

import { formatMoney } from "../../helpers/bookings";

const formatDate = (value) => moment(value, "YYYY-MM-DD").format("DD.MM.YYYY");

const getReducer = ({ bookingSession }) => bookingSession.stage4;

const getSessionData = ({ bookingSession: { sessionData } }) => {
  return sessionData;
};

export const getItems = createSelector([getSessionData], ({ items }) => {
  let results = [];

  const roomItems = _.filter(items, (it) => it.item_type === ITEM_TYPE.ROOM);
  _.forEach(roomItems, (roomItem) => {
    const item = {
      ..._.pick(roomItem, ["date", "price", "quantity", "discount_amount"]),
      name: _.get(roomItem, "item.description", "Room").split("\n")[0],
      date: formatDate(_.get(roomItem, "date")),
    };
    results.push(item);
  });

  const exFacilityItems = _.filter(items, (it) => it.item_type === ITEM_TYPE.EX_FACILITY);
  const exFacilityGroups = _.groupBy(exFacilityItems, (it) => _.get(it, "item.category.id"));
  _.forEach(exFacilityGroups, (gItems) => {
    const gDates = _.groupBy(gItems, (it) => _.get(it, "date"));
    _.forIn(gDates, (listItem, date) => {
      const firstItem = _.first(listItem);
      const name = _.get(firstItem, "item.category.name");
      const item = {
        ..._.pick(firstItem, ["price", "discount_amount"]),
        name,
        date: formatDate(date),
        quantity: listItem.length,
      };
      results.push(item);
    });
  });

  const cateringItems = _.filter(items, (it) => it.item_type === ITEM_TYPE.CATERING);
  _.forEach(cateringItems, (it) => {
    const item = {
      ..._.pick(it, ["price", "discount_amount", "quantity"]),
      name: _.get(it, "item.name"),
      date: formatDate(_.get(it, "date")),
    };
    results.push(item);
  });

  results = _.map(results, (item) => ({
    ...item,
    total: item.quantity * item.price * (1 - item.discount_amount / 100),
  }));

  return results;
});

export const getItemsBeautify = createSelector([getItems], (items) => {
  return _.map(items, (item) => ({
    ..._.pick(item, ["name", "date", "quantity"]),
    price: formatMoney(_.get(item, "price")),
    discount: `${_.get(item, "discount_amount")}%`,
    total: formatMoney(_.get(item, "total")),
  }));
});

export const getTotal = createSelector([getSessionData], ({ total }) => total);

export const getTotalBeautify = createSelector([getTotal], (total) => formatMoney(total));

export const getTax = createSelector([getSessionData], ({ tax }) => tax);

export const getTaxBeautify = createSelector([getTax], (tax) => formatMoney(tax));

export const getTaxRate = createSelector([getSessionData], ({ tax_rate }) => tax_rate);

export const getTaxRateBeautify = createSelector([getTaxRate], (rate) => `${rate * 100}%`);

export const getGrandTotal = createSelector([getSessionData], ({ grand_total }) => grand_total);

export const getGrandTotalBeautify = createSelector([getGrandTotal], (total) => formatMoney(total));

export const getPaymentMethod = createSelector([getSessionData], ({ payment_method }) => {
  return payment_method === PAYMENT_METHOD.STRIPE ? "CREDIT CARD" : "INVOICE";
});

export const getAdditionalRequirement = createSelector(
  [getSessionData],
  ({ additional_requirement }) => additional_requirement,
);

export const isNextSubmitting = createSelector(
  [getReducer],
  ({ isNextSubmitting }) => isNextSubmitting,
);

export const isBackSubmitting = createSelector(
  [getReducer],
  ({ isBackSubmitting }) => isBackSubmitting,
);

export const isCancelSubmitting = createSelector(
  [getReducer],
  ({ isCancelSubmitting }) => isCancelSubmitting,
);
