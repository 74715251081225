import Container from "@material-ui/core/Container";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import ContactContainer from "../../containers/Booking/Contact";

import * as selector from "../../selectors/bookingSession";

import ContactFloatButton from "../Booking/ContactFloatButton";
import Header from "../Header";
import Stage1 from "./Stage1";
import Stage2 from "./Stage2";
import Stage3 from "./Stage3";
import Stage4 from "./Stage4";
import Stage5 from "./Stage5";

const Booking = (props) => {
  const { t, stage } = props;

  const getView = () => {
    switch (stage) {
      case 1:
        return <Stage1 />;
      case 2:
        return <Stage2 />;
      case 3:
        return <Stage3 />;
      case 4:
        return <Stage4 />;
      case 5:
        return <Stage5 />;
      default:
        return <Stage5 />;
    }
  };

  return (
    <div>
      <Header size="small" />
      <Container>
        <Stepper
          activeStep={stage - 1}
          alternativeLabel
          style={{ backgroundColor: "whitesmoke", borderTop: "8px darkred solid" }}>
          <Step>
            <StepLabel>{t("Configuration")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("Summary")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("Payment")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("Confirmation")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("Completed")}</StepLabel>
          </Step>
        </Stepper>

        {getView()}
      </Container>
      <ContactFloatButton />
      <ContactContainer />
    </div>
  );
};

const mapState = (state) => ({
  stage: selector.getStage(state),
});

const mapDispatch = (dispatch) => bindActionCreators({}, dispatch);

Booking.propTypes = {
  searchedRoom: PropTypes.object,
  bookingSession: PropTypes.object,
  history: PropTypes.object,
};

export default connect(mapState, mapDispatch)(withRouter(withTranslation("booking")(Booking)));
