import * as _ from "lodash";
import { all, call, put, select, takeLatest, takeLeading } from "redux-saga/effects";

import * as C from "../actionTypes/booking";

import { history } from "../config/store";

import * as detailSelector from "../selectors/booking/bookingDetail";
import * as selector from "../selectors/booking/myBooking";
import { getLocale } from "../selectors/home";

const URL = process.env.REACT_APP_BASE_URL;

function* loadData({ api }) {
  yield put({ type: C.MY_BOOKING_LIST_BOOKING });

  let listApi = api.bookingApi.listMyBookings;
  const payload = yield select(selector.getRequestPayload);
  try {
    const response = yield call(listApi, payload);
    yield put({ type: C.MY_BOOKING_LIST_BOOKING_COMPLETED, payload: response });
  } catch (e) {
    yield put({ type: C.MY_BOOKING_LIST_BOOKING_FAILED, payload: e });
    console.error(e);
  }
}

function* retrieveBookingDetail({ api }, { payload }) {
  try {
    const response = yield call(api.bookingApi.getBookingDetail, payload);
    yield put({ type: C.MY_BOOKING_RETRIEVE_BOOKING_DETAIL_COMPLETED, payload: response });
  } catch (e) {
    yield put({ type: C.MY_BOOKING_RETRIEVE_BOOKING_DETAIL_FAILED, payload: e });
    console.error(e);
  }

  try {
    const locale = yield select(getLocale);

    yield put({ type: C.MY_BOOKING_LOADING_INVOICE_HTML });
    const response = yield call(api.bookingApi.getBookingToken);
    const token = _.get(response, "token", "");
    const pdfUrl = `${URL}/booking/download_invoice_pdf?booking_id=${payload}&token=${token}&lang=${locale}`;
    const html = yield call(api.bookingSessionApi.downloadBookingInvoiceHTML, {
      booking_id: payload,
      token,
      lang: locale,
    });
    yield put({ type: C.MY_BOOKING_LOADING_INVOICE_HTML_COMPLETED, payload: { html, pdfUrl } });
  } catch (e) {
    yield put({ type: C.MY_BOOKING_LOADING_INVOICE_HTML_FAILED, payload: e });
    console.error(e);
  }
}

function* cancelBooking({ api }) {
  const data = yield select(detailSelector.generateCancelBookingPayload);
  const locale = yield select(getLocale);

  try {
    const response = yield call(api.bookingApi.cancelBooking, data, locale);
    yield put({ type: C.MY_BOOKING_CANCEL_BOOKING_COMPLETED, payload: response });
    history.goBack();
  } catch (e) {
    yield put({ type: C.MY_BOOKING_CANCEL_BOOKING_FAILED, payload: e });
    console.error(e);
  }
}

function publicSaga(deps) {
  return function* saga() {
    yield all([
      takeLeading(C.MY_BOOKING_LOAD_INITIAL_DATA, loadData, deps),
      takeLatest(C.MY_BOOKING_CHANGE_CURRENT_PAGE, loadData, deps),
      takeLatest(C.MY_BOOKING_CHANGE_ACTIVATED_VIEW, loadData, deps),

      takeLatest(C.MY_BOOKING_RETRIEVE_BOOKING_DETAIL, retrieveBookingDetail, deps),
      takeLeading(C.MY_BOOKING_CANCEL_BOOKING, cancelBooking, deps),
      takeLeading(C.MY_BOOKING_CANCEL_BOOKING_COMPLETED, loadData, deps),
    ]);
  };
}

export default publicSaga;
