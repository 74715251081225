import { Grid, TextField, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { requestResetPassword } from "../../../actions/auth";
import Image from "../../../assets/images/ecos_Stockholm_1.jpg";

import { history } from "../../../config/store";
import { LogoImage as Logo } from "../../../constants/componentHelpers";
import * as paths from "../../../constants/paths.constants";

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      marginTop: "50px",
      marginBottom: "30px",
      color: theme.colorAccent,
      width: "60%",
      cursor: "pointer",
    },
    checkIcon: {
      "& svg": {
        width: "50px",
        height: "50px",
        color: theme.colorAccent,
      },
    },
    background: {
      "& img": {
        height: "100%",
        width: "100%",
      },
    },
    formRoot: {
      textAlign: "center",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 300,
      },
    },
    formField: {
      "& .MuiInputLabel-root": {
        textAlign: "left",
      },
      "& .MuiInputBase-root": {
        // backgroundColor: 'white',
        borderRadius: "15px",
        marginTop: "0",
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    textWelcome: {
      padding: "20px 0",
      color: theme.colorGray,
    },
    verifySuccessText: {
      padding: "0px 0 10px",
      color: theme.colorGray,
    },
    title: {
      color: theme.colorAccent,
      marginBottom: "20px",
    },
    titleSubText: {
      color: theme.colorGray,
      padding: "0 20%",
    },
    buttonGroup: {
      justifyContent: "center",
      "& button": {
        width: 300,
        // padding: '10px 0',
        margin: "25px",
      },
    },
    forgotPassword: {
      cursor: "pointer",
    },
    registerContent: {
      marginTop: "30px",
    },
    registerLink: {
      cursor: "pointer",
    },
  };
});

const ForgotPassword = (props) => {
  const {
    t,
    isRequestingResetPassword,
    requestResetPassword,
    form: { validateFields, getFieldDecorator },
  } = props;
  const classes = useStyles();
  // const [email, setEmail] =>
  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateFields((errors, values) => {
      if (!errors) {
        requestResetPassword(values);
      }
    });
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item md={4} sm={4} xs={4}>
        <Typography variant="h2" align="center" className={classes.aa}>
          <img
            src={Logo}
            className={classes.logo}
            onClick={() => history.push(paths.HomePage)}
          />
        </Typography>
        <Typography variant="h5" align="center" className={classes.title}>
          {t("ForgotPassword")}
        </Typography>
        <Typography
          variant="body2"
          align="center"
          className={classes.titleSubText}
        >
          {t("PleaseEnterYourRegisteredEmailBelowToReceiveTheResetPassword")}
        </Typography>
        <Form
          className={classes.formRoot}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Grid container>
            <Grid item xs={12}>
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    { required: true, message: t("PleaseInputYourEmail") },
                    {
                      type: "email",
                      message: t("PleaseInputCorrectYourEmail"),
                    },
                  ],
                })(
                  <TextField
                    // error
                    className={classes.formField}
                    // id="outlined-error"
                    id="standard-basic"
                    label="Email"
                  />
                )}
              </Form.Item>
            </Grid>
            <Grid item xs={12}>
              <Toolbar className={classes.buttonGroup}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isRequestingResetPassword}
                >
                  {t("SendRequest")}
                </Button>
              </Toolbar>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.forgotPassword}>
                <Link to={paths.LoginPage}>{t("BackToLOGIN")}</Link>
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </Grid>
      <Grid item md={8} sm={8} xs={8} className={classes.background}>
        <img src={Image} />
      </Grid>
    </Grid>
  );
};

const mapState = ({ authReducer }) => ({
  isRequestingResetPassword: authReducer.isRequestingResetPassword,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      requestResetPassword,
    },
    dispatch
  );

ForgotPassword.propTypes = {
  verifyAccount: PropTypes.func,
  t: PropTypes.func,
  isVerified: PropTypes.bool,
};

export default Form.create({
  name: "requestResetPassword",
})(withTranslation("common")(connect(mapState, mapDispatch)(ForgotPassword)));
