import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/bookingSession";

import HolidayBookingWarning from "../../components/Modal/HolidayBookingWarning";

import * as selector from "../../selectors/bookingSession/stage0";

const HolidayWarningContainer = (props) => {
  return <HolidayBookingWarning {...props} />;
};

const mapState = (state) => ({
  isOpen: selector.isShowHolidayWarning(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      closeModal: actions.hideHolidayWarning,
      onContact: actions.showContactForm,
    },
    dispatch,
  );

export default connect(mapState, mapDispatch)(HolidayWarningContainer);
