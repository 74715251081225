import { Container, Grid, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { bindActionCreators } from "redux";

import { myBooking as actions } from "../../actions/booking";

import MyBookingComponent from "../../components/MyBooking";

import * as selector from "../../selectors/booking/myBooking";

import Header from "../Header";
import BookingDetail from "./BookingDetail";

const useStyles = makeStyles((theme) => ({
  toolbarSecondary: {
    justifyContent: "space-between",
    backgroundColor: "#840810",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  formContent: {
    justifyContent: "center",
  },
  formRoot: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
      "& .MuiInputBase-input": {
        padding: "10.5px 14px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ddd !important",
        color: "#ddd !important",
      },
    },
  },
  formField: {
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      borderRadius: "15px",
    },
    "& .MuiInputLabel-formControl": {
      top: "-5px",
    },
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  pageTitle: {
    color: "white",
    padding: "15px 0",
  },
  searchTitle: {
    margin: "auto 0",
  },
  searchContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchField: {},
  searchButton: {
    margin: "8px",
  },
  tabsContent: {
    margin: "20px 0",
    boxShadow: "unset",
    "& .MuiTabs-flexContainer": {
      backgroundColor: "white",
      borderBottom: "3px solid #f44336",
    },
    "& .MuiButtonBase-root": {
      // backgroundColor: theme.colorAccent,
      color: theme.colorAccent,
    },
    "& .Mui-selected": {
      backgroundColor: theme.colorAccent,
      color: "white",
    },
  },
}));

const View = (props) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { t } = props;

  return (
    <div>
      <Header size="small" />
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        <Grid container className={classes.formContent}>
          <Grid item xs={12}>
            <Container>
              <Link to={`${match.path}`}>
                <Typography variant="h4" className={classes.pageTitle}>
                  {t("MY BOOKING")}
                </Typography>
              </Link>
            </Container>
          </Grid>
        </Grid>
      </Toolbar>

      <Switch>
        <Route path={`${match.path}/:bookingId`}>
          <BookingDetail />
        </Route>

        <Route path={`${match.path}/`}>
          <MyBookingComponent {...props} />
        </Route>
      </Switch>
    </div>
  );
};

class MyBooking extends Component {
  componentDidMount() {
    this.props.loadInitialData();
  }

  render() {
    // let match = useRouteMatch();

    return <View {...this.props} />;
  }
}

const mapState = (state) => ({
  activatedView: selector.getActivatedView(state),
  pagination: selector.getPagination(state),
  isLoading: selector.isFetchingBookings(state),
  items: selector.getItems(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      loadInitialData: actions.loadInitialData,
      onChangeActivatedView: actions.changeActivedView,
      onChangePage: actions.changeCurrentPage,
    },
    dispatch,
  );

MyBooking.propTypes = {
  getBookings: PropTypes.func,
};

export default connect(mapState, mapDispatch)(withTranslation("booking")(MyBooking));
