import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { Modal } from "antd";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const HolidayWarningModal = (props) => {
  const { t, isOpen, closeModal, onContact } = props;

  return (
    <Modal
      title={t("Booking on holiday or weekend warning")}
      visible={isOpen}
      onCancel={closeModal}
      footer={[
        <Button variant="outlined" onClick={closeModal}>
          {t("Cancel")}
        </Button>,

        <Button variant="contained" onClick={onContact} color="primary">
          {t("Contact")}
        </Button>,
      ]}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography variant="body1">
            {parse(t("Please contact our staff directly for booking on weekend or holiday"))}
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withTranslation("common")(HolidayWarningModal);
