import { Container, Grid, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Input, Radio, Select } from "antd";
import { get } from "lodash";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getProfile, updateProfile } from "../../actions/auth";

import { getListAllCountries } from "../../helpers/helpers";

import ChangePassword from "./ChangePassword";

const useStyles = makeStyles((theme) => ({
  toolbarSecondary: {
    justifyContent: "space-between",
    backgroundColor: "#840810",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  formContent: {
    justifyContent: "center",
    marginBottom: "20px",
  },
  formRoot: {
    "& .ant-form-item-control-wrapper": {
      width: "90%",
    },
  },
  formField: {
    borderRadius: "15px",
    margin: "8px 0",
    minWidth: "100px",
    width: "90%",
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      borderRadius: "15px",
      marginRight: "20px",
    },
    "& .MuiSelect-root": {
      backgroundColor: "white",
      borderRadius: "15px",
    },
    "& .MuiInputBase-input": {
      padding: "10px",
    },
    "& .MuiInputLabel-formControl": {
      top: "-7px",
    },
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  pageTitle: {
    color: "white",
    padding: "15px 0",
  },
  searchTitle: {
    margin: "auto 0",
  },
  searchContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchField: {},
  searchButton: {
    margin: "8px",
  },
  subTitle: {
    borderBottom: "1px solid",
    margin: "20px 0",
  },
  container: {
    maxWidth: "75%",
  },
  buttonGroup: {
    textAlign: "center",
    marginBottom: "50px",
  },
  confirmChangeButton: {
    marginRight: "30px",
  },
}));

const Profile = (props) => {
  const classes = useStyles();

  const {
    t,
    form: { getFieldDecorator, getFieldValue, validateFields },
    updateProfile,
    userProfile,
    getProfile,
  } = props;

  useEffect(() => {
    if (!userProfile || !userProfile.id) {
      getProfile();
    }
  }, [userProfile]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await validateFields((errors, values) => {
      if (!errors) {
        console.log(values);
        updateProfile({
          ...values,
        });
      }
    });
  };

  const listCountries = getListAllCountries("en");

  const onFilter = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  return (
    <div>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        <Grid container className={""}>
          <Grid item xs={12}>
            <Container className={classes.container}>
              <Typography variant="h4" className={classes.pageTitle}>
                {t("108")}
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Toolbar>
      <Toolbar component="nav" variant="dense" className={classes.facilitiesContent}>
        <Grid container className={classes.justifyContentCenter}>
          <Container className={classes.container}>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" className={classes.subTitle}>
                {t("109")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Form className={classes.formRoot} onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <Form.Item label={t("FirstName")}>
                      {getFieldDecorator("first_name", {
                        initialValue: get(userProfile, "first_name"),
                        rules: [
                          {
                            required: true,
                            message: t("PleaseInputYourFirstName"),
                          },
                        ],
                      })(<Input placeholder={t("FirstName*")} />)}
                    </Form.Item>
                    <Form.Item label={t("LastName")}>
                      {getFieldDecorator("last_name", {
                        initialValue: get(userProfile, "last_name"),
                        rules: [
                          {
                            required: true,
                            message: t("PleaseInputYourLastName"),
                          },
                        ],
                      })(<Input placeholder={t("LastName*")} />)}
                    </Form.Item>
                    <Form.Item label="Email">
                      {getFieldDecorator("email", {
                        initialValue: get(userProfile, "email"),
                        rules: [
                          {
                            required: true,
                            message: t("PleaseInputYourEmail"),
                          },
                          {
                            type: "email",
                            message: t("PleaseInputCorrectYourEmail"),
                          },
                        ],
                      })(<Input placeholder="Email *" />)}
                    </Form.Item>
                    <Form.Item label={t("AccountType")} className={classes.alignItemsCenter}>
                      {getFieldDecorator("is_belong_to_company", {
                        initialValue: get(userProfile, "is_belong_to_company"),
                      })(
                        <Radio.Group>
                          <Radio value={false}>{t("Private")}</Radio>
                          <Radio value={true}>{t("Company")}</Radio>
                        </Radio.Group>,
                      )}
                    </Form.Item>
                    <Form.Item label={t("CompanyName")}>
                      {getFieldDecorator("company", {
                        initialValue: get(userProfile, "company"),
                        rules: [
                          {
                            required: getFieldValue("is_belong_to_company") === "company",
                            message: "Please input your company!",
                          },
                        ],
                      })(
                        <Input
                          placeholder={`${t("CompanyName")}${
                            getFieldValue("is_belong_to_company") === "company"
                              ? " *"
                              : "(" + t("optional") + ")"
                          }`}
                        />,
                      )}
                    </Form.Item>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Form.Item label={t("Country")}>
                      {getFieldDecorator("country", {
                        initialValue: get(userProfile, "country"),
                        rules: [
                          {
                            required: true,
                            message: t("PleaseInputYourCountry"),
                          },
                        ],
                      })(
                        <Select
                          placeholder={t("Country*")}
                          showSearch
                          optionFilterProp="children"
                          // disabled={true}
                          filterOption={onFilter}>
                          {listCountries.map((item, index) => {
                            return (
                              <Select.Option key={index} value={item.value}>
                                {item.label}
                              </Select.Option>
                            );
                          })}
                        </Select>,
                      )}
                    </Form.Item>
                    <Form.Item label={t("City")}>
                      {getFieldDecorator("city", {
                        initialValue: get(userProfile, "city"),
                        rules: [{ required: true, message: t("PleaseInputYourCity") }],
                      })(<Input placeholder={t("City*")} />)}
                    </Form.Item>
                    <Form.Item label={t("PostCode")}>
                      {getFieldDecorator("post_code", {
                        initialValue: get(userProfile, "post_code"),
                        rules: [
                          {
                            required: true,
                            message: t("PleaseInputYourPostCode"),
                          },
                        ],
                      })(<Input placeholder={t("PostCode*")} />)}
                    </Form.Item>

                    <Form.Item label={t("Street")}>
                      {getFieldDecorator("street", {
                        initialValue: get(userProfile, "street"),
                        rules: [
                          {
                            required: true,
                            message: t("PleaseInputYourStreet"),
                          },
                        ],
                      })(<Input placeholder={t("Street*")} />)}
                    </Form.Item>

                    <Form.Item label={t("HouseNr")}>
                      {getFieldDecorator("house_nbr", {
                        initialValue: get(userProfile, "house_nbr"),
                        rules: [
                          {
                            required: true,
                            message: t("PleaseInputYourHouseNr"),
                          },
                        ],
                      })(<Input placeholder={t("HouseNr*")} />)}
                    </Form.Item>

                    <Form.Item label={t("Phone")}>
                      {getFieldDecorator("phone", {
                        initialValue: get(userProfile, "phone"),
                      })(<Input placeholder={t("Phone(optional)")} />)}
                    </Form.Item>

                    <Form.Item label={t("ContactPerson")}>
                      {getFieldDecorator("contact_person", {
                        initialValue: get(userProfile, "contact_person"),
                      })(<Input placeholder={t("ContactPerson(optional)")} />)}
                    </Form.Item>
                    <Form.Item label={t("Addition")}>
                      {getFieldDecorator("addition", {
                        initialValue: get(userProfile, "addition"),
                      })(<Input placeholder={t("Addition(optional)")} />)}
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12} className={classes.buttonGroup}>
                    {/* <MaterialButton className={classes.cancelButton}>
                      Cancel
                    </MaterialButton> */}
                    <Button
                      type="primary"
                      variant="contained"
                      className={classes.confirmChangeButton}
                      htmlType="submit">
                      {t("110")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <ChangePassword />
          </Container>
        </Grid>
      </Toolbar>
    </div>
  );
};

const mapState = ({ authReducer }) => ({
  isUpdatingProfile: authReducer.isUpdatingProfile,
  userProfile: authReducer.userProfile,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      updateProfile,
      getProfile,
    },
    dispatch,
  );

export default Form.create({
  name: "updateProfile",
})(connect(mapState, mapDispatch)(withTranslation("common")(Profile)));
